import { useTranslation } from "react-i18next";
export default function CommonPageSizeOptions() {
  const { t } = useTranslation();
  return [
    { value: 5, label: t("paginationOptions.option5") },
    { value: 10, label: t("paginationOptions.option10") },
    { value: 15, label: t("paginationOptions.option15") },
    { value: 20, label: t("paginationOptions.option20") },
  ];
}
