import { UserOutlined } from "@ant-design/icons";
import { Avatar, Dropdown, MenuProps, Space } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../../../../constants/router.constants";
import { UserModelInf } from "../../../../../../../models/user";
import { CommonUtils } from "../../../../../../../utils/common";
import LocalUtils from "../../../../../../../utils/local.utils";
import "./index.scss";
import ModalChangePasscode from "./menu/modal-changePasscode";

function UserMenu({ userData }: { userData: UserModelInf | undefined }) {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: t("userMenu.account"),
      danger: true,
      disabled: true,
    },
    {
      key: "2",
      label: (
        <Link to={ROUTE_PATHS.MyAccount}>{t("menuDashboard.myAccount")}</Link>
      ),
      icon: <i className="menu-icon icon-profile" />,
    },
    {
      key: "6",
      label: t("userMenu.profile"),
    },
    {
      key: "7",
      label: t("userMenu.settings"),
      icon: <i className="menu-icon icon-setting-1" />,
    },
    {
      key: "8",
      label: t("userMenu.changePasscode"),
      icon: <i className="menu-icon icon-key" />,
      onClick: showModal,
    },
    {
      key: "9",
      label: t("userMenu.lockAccount"),
      icon: <i className="menu-icon icon-lock-1" />,
    },
    {
      key: "10",
      label: t("userMenu.logout"),
      icon: <i className="menu-icon icon-logout" />,
      onClick: handleLogout,
    },
  ];

  function handleLogout() {
    LocalUtils.clear();
    window.location.href = ROUTE_PATHS.SignIn;
  }


  return (
    <>
      <Space direction="vertical">
        <Space wrap>
          <Dropdown
            overlayClassName="menu-user"
            menu={{ items }}
            trigger={["click"]}>
            <Avatar
              size={40}
              src={CommonUtils.createFileUrl(userData?.personalImage)}
              icon={<UserOutlined />}
            />
          </Dropdown>
        </Space>
      </Space>
      <ModalChangePasscode
        visible={isModalOpen}
        onClose={handleCancel}
        user={userData}
      />
    </>
  );
}

export default UserMenu;
