import { Col, Divider, Form, Input, InputNumber, Row } from "antd";
import { useTranslation } from "react-i18next";

function Base() {
  const { t } = useTranslation();

  return (
    <>
      <Divider
        className="h5-semiBold"
        orientation="left"
        orientationMargin="0">
        {t("sysConfig.label.baseConfig")}
      </Divider>
      <Row gutter={1}>
        <Col
          lg={8}
          sm={24}
          xs={24}>
          <Form.Item
            label={t("sysConfig.basic.name")}
            name="name"
            rules={[{ required: true }]}>
            <Input className="input-base" />
          </Form.Item>
          <Form.Item
            label={t("sysConfig.basic.otp_expire_time")}
            name="aotpExpiry">
            <InputNumber className="input-base" />
          </Form.Item>
        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}>
          <Form.Item
            label={t("sysConfig.basic.change_passcode_notify_before_x_days")}
            name="aChangePasscodeAfterXDaysNotify">
            <InputNumber className="input-base" />
          </Form.Item>
          <Form.Item
            label={t("sysConfig.basic.change_passcode_after_x_days")}
            name="aChangePasscodeAfterXDays">
            <InputNumber className="input-base" />
          </Form.Item>

        </Col>
        <Col
          lg={8}
          sm={24}
          xs={24}>
          <Form.Item
            label={t("sysConfig.basic.inactivity_logout_minutes_idle_logout")}
            name="inactivityLogout">
            <InputNumber className="input-base" />
          </Form.Item>
          <Form.Item
            label={t("sysConfig.basic.access_token_life_time_minutes")}
            name="accessTokenLifeTime">
            <InputNumber className="input-base" />
          </Form.Item>
        </Col>
      </Row>
    </>
  );
}

export default Base;
