import { Alert, Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function TrafficChart() {
  const [isLoading, setIsLoading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      series: [
        {
          name: "Free Cash Flow",
          data: [35, 41, 36, 26, 45, 48, 52, 53, 41, 87, 21, 11],
        },
      ],
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: true,
        strokeDashArray: 3,
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: true,
          },
        },
      },

      plotOptions: {
        bar: {
          columnWidth: "12%",
          borderRadius: 6,
        },
      },
      colors: ["#0078D7"],
      xaxis: {
        categories: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        labels: {
          style: {
            fontWeight: 400,
            fontFamily: "Poppins",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  });
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000); // Delay 2 seconds
  }, []);
  return (
    <>
      {isLoading && (
        <div className="loading">
          <Spin tip="Loading...">
            <Alert description="Loading Chart..." type="info" />
          </Spin>
        </div>
      )}
      {!isLoading && (
        <ReactApexChart
          options={chartData.options}
          series={chartData.options.series}
          type="bar"
          height={350}
        />
      )}
    </>
  );
}

export default TrafficChart;
