import { Alert, Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./chart.scss";

function ProductSalesChart() {
  const [isLoading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    options: {
      series: [44, 55, 13],
      labels: ["Total Sales", "Total Orders", "Order Cancel"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          customScale: 0.8,
          donut: {
            size: "80%",
            borderRadius: 6,
          },
        },
      },
      colors: ["#FFD66B", "#5B93FF", "#FF8F6B"],
      chart: {
        toolbar: {
          show: false,
        },
        width: 60,
      },
      legend: {
        position: "bottom" as "bottom",
      },
    },
  });
  useEffect(() => {
    // Simulating data fetching delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Delay 2s (simulating async data fetching)
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Spin tip="Loading...">
            <Alert description="Loading Chart..." type="info" />
          </Spin>
        </div>
      )}
      {!isLoading && (
        <div className="chart-bottom-content">
          <ReactApexChart
            options={chartData.options}
            series={chartData.options.series}
            type="donut"
          />
        </div>
      )}
    </>
  );
}
export default ProductSalesChart;
