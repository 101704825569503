import { InfoCircleOutlined } from "@ant-design/icons";
import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { AuthApi } from "../../../../../../../../apis/auth.api";
import { PASSWORD_REGEX } from "../../../../../../../../constants/app-constants";
import { ChangePasscodeModel } from "../../../../../../../../models/sign-in.model";
import { UserModelInf } from "../../../../../../../../models/user";
import GuidePasscode from "../../../../../../../common/guide-passcode/guide-passcode";
import "../../../../../../../common/modal-mfa/index.scss";
import showNotification from "../../../../../../../common/notification/index";
import "./modal-changePasscode.scss";

export interface ModalChangePasscodeProps {
  visible: boolean;
  onClose: () => void;
  user: UserModelInf | undefined;
}

export function ModalChangePasscode({
  visible,
  onClose,
  user,
}: ModalChangePasscodeProps) {
  const { t } = useTranslation();
  const [changePasscodeForm] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const handleOk = () => {
    changePasscodeForm.submit();
  };

  const handleCancel = () => {
    changePasscodeForm.resetFields();
    onClose();
  };

  async function handleChangePasscode(values: any) {
    const model: ChangePasscodeModel = {
      oldPasscode: values.oldPasscode,
      passcode: values.passcode,
      passcodeConfirm: values.confirm,
    };
    setLoading(true);
    try {
      let data = await AuthApi.changingLoggedUserPasscode(model);
      if (data) {
        changePasscodeForm.resetFields();
        const message = "Updated new password successfully.";
        showNotification("success", message);
        onClose();
      }
    } catch (Error) {
    } finally {
      setLoading(false);
    }
  }

  function PasscodeForm() {
    return (
      <>
        <Form.Item
          name="oldPasscode"
          label={t("user.oldPasscode")}
          hasFeedback
          tooltip={{
            title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
            placement: "rightBottom", color: "#D9D9D9",
            overlayClassName: "guide-tooltip"
          }}  
          rules={[{ required: true, pattern: PASSWORD_REGEX,
            message: "The password required or format incorrect." }]}
        >
          <Input.Password className="input-base" placeholder={t("user.oldPasscode")} />
        </Form.Item>
        <Form.Item
          name="passcode"
          label={t("user.newPasscode")}
          hasFeedback
          tooltip={{
            title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
            placement: "rightBottom", color: "#D9D9D9",
            overlayClassName: "guide-tooltip"
          }}  
          rules={[{ required: true, pattern: PASSWORD_REGEX,
            message: "The password required or format incorrect." }]}
        >
          <Input.Password className="input-base" placeholder={t("user.newPasscode")} />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={t("user.rePasswordPlahoder")}
          dependencies={["password"]}
          hasFeedback
          tooltip={{
            title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
            placement: "rightBottom", color: "#D9D9D9",
            overlayClassName: "guide-tooltip"
          }}  
          rules={[
            {
              required: true,
              message: "Please confirm your password",
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("passcode") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error("The new password that you entered do not match.")
                );
              },
            }),
          ]}
        >
          <Input.Password className="input-base" placeholder={t("user.rePasswordPlahoder")} />
        </Form.Item>
      </>
    );
  }

  return (
    <>
      <Modal
        className="modal-confirm"
        open={visible}
        title="Change Passcode"
        onCancel={handleCancel}
        okButtonProps={{ loading: isLoading }}
        onOk={handleOk}
      >
        <Form
          className="modal-container"
          layout="vertical"
          labelWrap
          form={changePasscodeForm}
          onFinish={handleChangePasscode}
        >
          <PasscodeForm />
        </Form>
      </Modal>
    </>
  );
}

export default ModalChangePasscode;
