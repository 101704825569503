export const ROUTE_PATHS = {
  Home: '/',
  Alerts: '/alerts',
  GeneralSetting: '/general-setting',
  UserRoles: '/user-roles',
  UserRolesCreate: '/user-roles/create',
  UserRolesEdit: '/user-roles/edit/:id',
  Users: '/users',
  UserCreate: '/user/create',
  UserEdit: '/user/edit/:id',
  Audits: '/audits',
  Reports: '/reports',
  SystemConfig: '/system-config',
  UISetting: '/system-config/ui-setting',
  HostConfig: '/host-config',
  HostConfigCreate: '/host-config/create',
  HostConfigEdit: '/host-config/edit/:id',
  HostKeys: '/host-key',
  HostKeysCreate: '/host-key/create',
  HostKeysEdit: '/host-key/:id',
  HSMKeys: '/hsm-keys',
  EncryptedKeys: '/ecrypted-keys',
  EncryptedKeysEdit: '/ecrypted-keys/:id',
  EncryptedKeysCreate: '/ecrypted-keys/create',
  HSMKeysEdit: '/hsm-keys/edit/:id',
  HSMKeysCreate: '/hsm-keys/create',
  KeyTemplates: '/key-templates',
  KeyTemplatesCreate: '/key-templates/create',
  KeyTemplatesEdit: '/key-templates/edit/:id',
  TerminalKeyCreate: '/key-templates/create',
  SignIn: '/sign-in',
  MyAccount: '/my-account',
  ChangePasscode: '/change-passcode',
  SessionTimedOut: '/session-timed-out',
  Terminals: '/terminals',
  TerminalCreate: '/terminal/create',
  TerminalEdit: '/terminal/edit/:id',
  ClientNotFound: `/404`,
  NotFound: '*',
};
