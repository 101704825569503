import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Space,
  Spin,
  Table,
  Tooltip,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { ColumnsType } from "antd/es/table";
import { TableRowSelection } from "antd/es/table/interface";
import { Key, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { KeysApi } from "../../../../../apis/keys.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import { KeysEnums } from "../../../../../models/key-enums";
import {
  KeyTemplateModelAPI,
  TerminalKeyModel,
} from "../../../../../models/keys";
import { CommonUtils } from "../../../../../utils/common";
import BreadcrumbComponent from "../../../../common/breadcrumb";
import SwitchComponent from "../../../../common/form-component/switch-form-item/switch-component";
import showConfirmModal from "../../../../common/modal-confirm/index";
import showNotification from "../../../../common/notification";
import PaginationComponent from "../../../../common/pagination/component/pagination";
import "../index.scss";
import AddTerminalKeyForm from "./terminal-keys/add-terminal-key";

function AddKeyTemplatesForm() {
  const { id } = useParams();
  const { t } = useTranslation();
  const [tableIsLoading, setTableIsLoading] = useState(true);
  const [isKTLoading, setKTIsLoading] = useState(true);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [terminalKeyListModels, setTerminalKeyListModels] = useState<
    TerminalKeyModel[]
  >([]);
  const navigate = useNavigate();
  const [selectedRow, setSelectedRow] = useState<Key[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [keyTemplateForm] = Form.useForm();
  const breadcrumbItems = [
    "Update Key Templates",
    "Key Management",
    "Key Templates",
  ];
  const [isModalOpen, setIsModalOpen] = useState(false);
  const checkUrl = useLocation();

  useEffect(() => {
    if (id != null) {
      fetchTerminalList(id);
    }
  }, []);

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data } = await KeysApi.getKeyTemplatesDetail(id);
          if (data != null) setKTIsLoading(false);
          keyTemplateForm.setFieldsValue({
            name: data.keyTemplatesModel.name,
            isolationMode: data.keyTemplatesModel.isolationMode,
            remark: data.keyTemplatesModel.remark,
          });
          setSelectedRow(data.terminalKeySelected);
        } catch (err) {}
      };
      fetchData();
    } else {
      setKTIsLoading(false);
      setTableIsLoading(false);
    }
  }, []);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const onSubmit = () => {
    keyTemplateForm.submit();
  };

  async function fetchTerminalList(
    keyTemplateId: string,
    pageSizeData?: number,
    pageNumberData?: number,
    searchString?: string
  ) {
    pageSizeData = pageSizeData || pageSize;
    pageNumberData = pageNumberData || pageNumber;
    searchString = searchString || "";
    keyTemplateId = keyTemplateId || "";
    setTableIsLoading(true);
    try {
      var { data } = await KeysApi.getAllTerminalKeysFilter(
        pageNumberData,
        pageSizeData,
        searchString,
        keyTemplateId
      );
      const modifiedData = data.records.map((item: TerminalKeyModel) => ({
        ...item,
        key: item.id, // Rename 'id' to 'key'
      }));
      setTerminalKeyListModels(modifiedData);
      setTotalRecords(data.totalRecords);
    } catch (error) {
    } finally {
      setTableIsLoading(false);
    }
  }

  function handleSetSelectTerminalKey(selectedList: Key[]): TerminalKeyModel[] {
    const updatedTerminalKeyListModels = terminalKeyListModels.map(
      (terminal) => {
        if (selectedList.includes(terminal.key ?? DefaultGUID)) {
          return { ...terminal, isSelected: true };
        } else {
          return terminal;
        }
      }
    );
    return updatedTerminalKeyListModels;
  }

  function handleDeleteTerminalKey(key: string) {
    const handleConfirm = async () => {
      try {
        const item = terminalKeyListModels.find((k) => k.key === key);
        if (item !== undefined) {
          if (item.id === DefaultGUID) {
            setTerminalKeyListModels((preList) =>
              preList.filter((_) => _.key !== item.key)
            );
            showNotification("success", "Deleted terminal key successfully.");
          } else {
            let { data: deleteResponse } = await KeysApi.deleteTerminalKey(key);
            if (deleteResponse) {
              showNotification("success", "Deleted terminal key successfully.");
              if (id != null) {
                fetchTerminalList(id);
              }
            }
          }
        } else {
          showNotification("error", "Terminal Key not found");
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this user now`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  }

  async function handleCreateKeyTemplate(values: any) {
    setLoadingBtn(true);
    try {
      const req: KeyTemplateModelAPI = {
        id: id ?? DefaultGUID,
        name: values.name,
        isolationMode: values.isolationMode,
        remark: values.remark,
        terminalKeyModels: handleSetSelectTerminalKey(selectedRow),
      };
      const result =
        checkUrl.pathname === ROUTE_PATHS.KeyTemplatesCreate
          ? await KeysApi.createKeyTemplate(req)
          : await KeysApi.updateKeyTemplate(req);

      if (result) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.KeyTemplates);
        }, 1000);
      }
      showNotification("success", "Updated Key Template successfully.");
      setLoadingBtn(false);
    } catch (err) {}
  }

  const columns: ColumnsType<TerminalKeyModel> = [
    {
      title: t("key.col_title.terminal_slot"),
      key: "terminalSlot",
      render: (text: string, record: any) => (
        <>
          <Col>{record.terminalSlot}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.key_mode"),
      key: "keyMode",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyMode,
            KeysEnums.KeyModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.key_name"),
      key: "KeyName",
      render: (text: string, record: any) => (
        <>
          <Col>{record.keyName}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.kcv"),
      key: "kcv",
      render: (text: string, record: any) => (
        <Col>
          {" "}
          {CommonUtils.getDisplayNameForKeyEnums(
            record.encryptionMode,
            KeysEnums.EncryptionModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.keyAlgorithm"),
      key: "keyAlgorithm",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyAlgorithm,
            KeysEnums.KeyAlgorithmEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.remark"),
      key: "remark",
      render: (text: string, record: any) => <Col>{record.remark}</Col>,
    },
    {
      key: "6",
      title: "Action",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={<i className="icon-delete" />}
              onClick={() => handleDeleteTerminalKey(record.key!.toString())}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const rowSelection: TableRowSelection<TerminalKeyModel> = {
    selectedRowKeys: selectedRow,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedRow(selectedRowKeys);
      console.log(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };

  return (
    <>
      <Layout className="content-page">
        <BreadcrumbComponent items={breadcrumbItems} />
        <div className="ctn-user-index">
          <Spin
            tip="Loading"
            spinning={isKTLoading}>
            <Form
              layout="vertical"
              onFinish={handleCreateKeyTemplate}
              form={keyTemplateForm}>
              <Row gutter={16}>
                <Col
                  sm={18}
                  xs={14}
                  lg={12}>
                  <div className="head-form">
                    <div className="h4-semiBold">{t("key.key_template.title")}</div>
                    <div className="body-regular ">{t("key.key_template.title")}</div>
                  </div>
                </Col>
                <Col
                  sm={6}
                  xs={10}
                  lg={12}>
                  <Form.Item>
                    <div className="btn-grp">
                      <Link to={ROUTE_PATHS.KeyTemplates}>
                        <Button className="btn-back btn-base btn-secondary-base">
                          {t("sysConfig.button.back")}
                        </Button>
                      </Link>
                      <Button
                        className="btn-sumbit btn-base btn-main-base"
                        loading={loadingBtn}
                        onClick={onSubmit}>
                        {t("sysConfig.button.save")}
                      </Button>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              <Divider />
              <Row gutter={24}>
                <Col
                  lg={12}
                  sm={24}>
                  <Form.Item
                    name="name"
                    label={t("key.col_title.name")}
                    hasFeedback
                    rules={[{ required: true, message: "Name is required"}]}>
                    <Input
                      className="input-base"
                      placeholder={t("key.col_title.key_template_name")}
                    />
                  </Form.Item>
                  <SwitchComponent
                    name="isolationMode"
                    label={t("key.col_title.isolate_mode")}
                    valuePropName="checked"
                  />
                </Col>
                <Col
                  lg={12}
                  sm={24}>
                  <Form.Item
                    name="remark"
                    label={t("key.col_title.remark")}>
                    <TextArea
                      style={{ height: 110 }}
                      placeholder=""
                    />
                  </Form.Item>
                </Col>
                <div className="table-cmp">
                  <Col span={24}>
                    <Form.Item
                      name="terminalKeys"
                      label={t("key.title.key_template")}>
                      <Row>
                        <Col span={24}>
                          <Table
                            dataSource={terminalKeyListModels}
                            columns={columns}
                            loading={tableIsLoading}
                            rowSelection={rowSelection}
                            pagination={{
                              pageSize: pageSize,
                              current: pageNumber,
                              style: { display: "none" },
                            }}
                          />
                        </Col>
                        <PaginationComponent
                          totalRecords={totalRecords}
                          pageSize={pageSize}
                          setPageSize={setPageSize}
                          pageNumber={pageNumber}
                          setPageNumber={setPageNumber}
                          handleGetFilter={() => fetchTerminalList(id ?? "")}
                        />
                      </Row>
                    </Form.Item>
                    <Button
                      className="icon-key btn-modal btn-add-terminal"
                      htmlType="button"
                      onClick={() => handleOpenModal()}>
                      {t("key.button.btn_add_key_terminal")}
                    </Button>
                  </Col>
                </div>
              </Row>
            </Form>
          </Spin>
        </div>
        <AddTerminalKeyForm
          visible={isModalOpen}
          onClose={handleCloseModal}
          setTerminalKey={setTerminalKeyListModels}
        />
      </Layout>
    </>
  );
}
export default AddKeyTemplatesForm;
