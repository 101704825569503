import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../../../apis/user.api";
import {
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { MFAModel, UserModelInf } from "../../../../models/user";
import SwitchComponent from "../../../common/form-component/switch-form-item/switch-component";
import InsertPasscodeForm from "../../../common/modal-mfa/modal-input-passcode";
import showNotification from "../../../common/notification/index";

interface FAConfiguratrionProps {
  userInfo: UserModelInf | undefined;
}

function FAConfiguratrion(props: FAConfiguratrionProps) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [loginConfigurationForm] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const { data } = await UserApi.getMFAById(props.userInfo?.id ?? "");
      loginConfigurationForm.setFieldsValue({
        isCellCodeRequired: data.isCellCodeRequired,
        isEmailCodeRequired: data.isEmailCodeRequired,
        isDualOTP: data.isDualOTP,
      });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onVisibleSubmitModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async function handleSubmitConfigurations(values: any) {
    setLoadingBtn(true);
    try {
      const model: MFAModel = {
        id: props.userInfo?.id ?? DefaultGUID,
        isCellCodeRequired: values.isCellCodeRequired,
        isEmailCodeRequired: values.isEmailCodeRequired,
        isDualOTP: values.isDualOTP,
        passcode: values.passcode,
      };
      var { data } = await UserApi.updateMFAById(model);
      if (data) {
        showNotification("success", t("user.notiUpdateLoginConfigSucces"));
        onVisibleSubmitModal();
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  const checkFAConfiguration: SwitchChangeEventHandler = (checked, event) => {
    const { id } = event.currentTarget;
    console.log(id);
    loginConfigurationForm.setFieldsValue({
      isCellCodeRequired: false,
      isEmailCodeRequired: false,
      isDualOTP: false,
    });

    loginConfigurationForm.setFieldsValue({ [id]: checked });
  };

  function TitleLoginConfiguration() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.login_config_set")}</div>
              <div className="body-regular ">{t("title.login_config_set")}</div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onVisibleSubmitModal}
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <TitleLoginConfiguration />
      <Form
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        form={loginConfigurationForm}
        onFinish={handleSubmitConfigurations}
        className="form-submit-information"
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} lg={8}>
            <SwitchComponent
              label={t("sysConfig.appPermissions.isCellCodeRequired")}
              name="isCellCodeRequired"
              valuePropName="checked"
              onChange={checkFAConfiguration}
            />
          </Col>
          <Col sm={24} xs={24} lg={8}>
            <SwitchComponent
              label={t("sysConfig.appPermissions.isEmailCodeRequired")}
              name="isEmailCodeRequired"
              valuePropName="checked"
              onChange={checkFAConfiguration}
            />
          </Col>
          <Col sm={24} xs={24} lg={8}>
            <SwitchComponent
              label={t("sysConfig.appPermissions.isDualOTP")}
              name="isDualOTP"
              valuePropName="checked"
              onChange={checkFAConfiguration}
            />
          </Col>
        </Row>
        <Form.Item
          name="passcode"
          label={t("verify.ModalInsertPasscord")}
          hidden={true}
        >
          <Input.Password type="text" />
        </Form.Item>
      </Form>
      <InsertPasscodeForm
        visible={isModalOpen}
        onClose={onVisibleSubmitModal}
        formSubmit={loginConfigurationForm}
        isLoading={loadingBtn}
      />
    </Spin>
  );
}

export default FAConfiguratrion;
