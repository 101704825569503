import GeneralSettingTab from "./general-setting";
function UISetting() {
  function ConfigVerticalTab() {
    return (
      <GeneralSettingTab />
    );
  }
  return <ConfigVerticalTab />;
}
export default UISetting;
