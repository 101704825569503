import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Modal,
  Select,
  SelectProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from "uuid";
import { KeysApi } from "../../../../../../apis/keys.api";
import { DefaultGUID } from "../../../../../../constants/app-constants";
import {
  EncryptionModeEnums,
  KeyAlgorithmEnums,
  KeyDisplayName,
  KeyModeEnums,
} from "../../../../../../models/key-enums";
import {
  EncrytedKeyModelDisplay,
  TerminalKeyModel,
} from "../../../../../../models/keys";
import showNotification from "../../../../../common/notification/index";
import "../../index.scss";

interface AddTerminalKeyFormProps {
  visible: boolean;
  onClose: () => void;
  setTerminalKey: Dispatch<SetStateAction<TerminalKeyModel[]>>;
}

function AddTerminalKeyForm({
  visible,
  onClose,
  setTerminalKey,
}: AddTerminalKeyFormProps) {
  const [modalForm] = Form.useForm<FormInstance>();
  const [encryptedNameList, setEncryptedNameList] = useState<
    EncrytedKeyModelDisplay[]
  >([]);
  const [isLoading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchHSMKeyName = async () => {
      const encryptedKeys = await KeysApi.getAllEncryptedKeys();
      setEncryptedNameList(encryptedKeys.data);
    };
    fetchHSMKeyName();
  }, []);

  const generateGuidId = (): string => {
    return uuidv4();
  };

  async function handleCreateTerminalKey(values: any) {
    setLoading(true);
    try {
      const guidId = generateGuidId();
      const req: TerminalKeyModel = {
        id: DefaultGUID,
        terminalSlot: values.terminalSlot,
        keyMode: values.keyMode ?? 0,
        encryptedKeyId: values.encryptedKeyId ?? null,
        name: values.name,
        encryptionMode: values.encryptMode ?? 0,
        keyAlgorithm: values.keyAlgorithm ?? 0,
        remark: values.remark ?? null,
        isSelected: false,
        isNew: true,
        key: guidId,
      };

      setTerminalKey((prevState) => [...prevState, req]);
      setLoading(false);
      modalForm.resetFields();
      onClose();
      showNotification("success", "Create Terminal Key successfully.");
    } catch (err) {}
  }
  function onSubmit() {
    modalForm.submit();
  }

  function ModalDetail() {
    const optionsKeyMode: SelectProps["options"] = [
      {
        label: KeyDisplayName.KeyModeDisplayName[0],
        value: KeyModeEnums.None,
      },
      {
        label: KeyDisplayName.KeyModeDisplayName[1],
        value: KeyModeEnums.DUKPTbyHSMKey,
      },
      {
        label: KeyDisplayName.KeyModeDisplayName[2],
        value: KeyModeEnums.NoneUnique,
      },
      {
        label: KeyDisplayName.KeyModeDisplayName[4],
        value: KeyModeEnums.KEK,
      },
    ];

    const optionsEncryptionMode: SelectProps["options"] = [
      {
        label: KeyDisplayName.EncryptionModeDisplayName[0],
        value: EncryptionModeEnums.None,
      },
      {
        label: KeyDisplayName.EncryptionModeDisplayName[1],
        value: EncryptionModeEnums.ECB,
      },
      {
        label: KeyDisplayName.EncryptionModeDisplayName[2],
        value: EncryptionModeEnums.CBC,
      },
      {
        label: KeyDisplayName.EncryptionModeDisplayName[4],
        value: EncryptionModeEnums.TR31,
      },
    ];

    const optionsKeyAlgorithm: SelectProps["options"] = [
      {
        label: KeyDisplayName.KeyAlgorithmDisplayName[0],
        value: KeyAlgorithmEnums.None,
      },
      {
        label: KeyDisplayName.KeyAlgorithmDisplayName[1],
        value: KeyAlgorithmEnums.DES,
      },
      {
        label: KeyDisplayName.KeyAlgorithmDisplayName[2],
        value: KeyAlgorithmEnums.TDES,
      },
    ];

    return (
      <>
        <Form.Item
          name="name"
          label={t("key.col_title.name")}
          hasFeedback
          rules={[{ required: true, message: "Key Name is required" }]}>
          <Input
            className="input-base"
            placeholder={t("key.col_title.name")}
          />
        </Form.Item>
        <Form.Item
          name="terminalSlot"
          label={t("key.col_title.terminal_slot")}
          hasFeedback
          rules={[{ required: true, message: "Terminal Slot is required" }]}>
          <InputNumber
            className="input-base input-terminalslot"
            defaultValue={0}
          />
        </Form.Item>
        <Form.Item
          name="keyMode"
          label={t("key.col_title.key_mode")}>
          <Select
            className="select-base"
            placeholder={t("key.col_title.key_mode")}
            options={optionsKeyMode}
          />
        </Form.Item>
        <Form.Item
          name="encryptedKeyId"
          hidden></Form.Item>
        <Form.Item
          name="encryptedKey"
          label={t("key.col_title.ecryptedKey")}
          rules={[{ required: true, message: "Encrypted Key is required" }]}>
          <Select
            className="select-base"
            placeholder={t("key.col_title.ecryptedKey")}
            onSelect={(value, options) => {
              modalForm.setFieldValue("encryptedKeyId", value);
            }}
            options={encryptedNameList.map((key) => ({
              label: key.name,
              value: key.id,
            }))}
          />
        </Form.Item>
        <Form.Item
          name="encryptMode"
          label={t("key.col_title.encryption_mode")}>
          <Select
            className="select-base"
            placeholder="Encryption Mode"
            options={optionsEncryptionMode}
          />
        </Form.Item>
        <Form.Item
          name="keyAlgorithm"
          label={t("key.col_title.keyAlgorithm")}>
          <Select
            className="select-base"
            placeholder="Key Algorithm"
            options={optionsKeyAlgorithm}
          />
        </Form.Item>
        <Form.Item
          name="remark"
          label={t("key.col_title.remark")}>
          <TextArea
            style={{ height: 110 }}
            placeholder=""
          />
        </Form.Item>
      </>
    );
  }

  return (
    <Modal
      open={visible}
      className="terminal-modal"
      onCancel={onClose}
      okButtonProps={{ loading: isLoading }}
      onOk={onSubmit}
      title={t("key.button.btn_add_key_terminal")}>
      <Form
        className="modal-container"
        layout="vertical"
        onFinish={handleCreateTerminalKey}
        labelWrap
        form={modalForm}>
        <ModalDetail />
      </Form>
    </Modal>
  );
}

export default AddTerminalKeyForm;
