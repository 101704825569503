import "./index.scss";

import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../../assets/images/PAX_Logo.svg";
import { SUPPORT_EMAIL } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";

function NotFoundPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function backToHome() {
    navigate(ROUTE_PATHS.Home);
  }

  return (
    <div className="not-found-page">
      <div className="not-found-page__header">
        <Link to={ROUTE_PATHS.Home}>
          <img src={logo} className="" alt="" />
        </Link>
      </div>

      <div className="not-found-page__body">
        <h1 dangerouslySetInnerHTML={{ __html: t("404.title") }}></h1>
        <p>{t("404.description")}</p>
        <Button
          icon={<i className="icon-home" />}
          type="primary"
          onClick={backToHome}
        >
          {t("404.backToHome")}
        </Button>
        <a href={`mailto:${SUPPORT_EMAIL}`} className="text-success">
          {t("404.contactSupport")}
        </a>
      </div>

      <div className="not-found-page__foolter">
        <small>{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default NotFoundPage;
