import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  ResponseType,
} from "axios";
import qs from "qs";
import { getRecoil, setRecoil } from "recoil-nexus";
import showNotification from "../components/common/notification";
import {
  LOCAL_COOKIE_KEY,
  LOCAL_STORAGE_KEY,
} from "../constants/app-constants";
import { RefreshTokenModel } from "../models/sign-in.model";
import { authState } from "../states/auth";
import i18n from "../utils/i18n";
import LocalUtils from "../utils/local.utils";
import { AuthApi } from "./auth.api";

const toggleLoading = (value: boolean) => {};

let IS_REFRESHING_TOKEN = false;
let APPLICATION_ID = "d03769d0-bef3-4250-9990-db1e6cedc52b";

const getHeaders = (contentType: string) => {
  let headers: any = {
    "Content-Type": contentType,
    AppId: APPLICATION_ID,
  };

  if (LocalUtils.getCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN)) {
    headers = {
      ...headers,
      Authorization: `Bearer ${LocalUtils.getCookie(
        LOCAL_COOKIE_KEY.ACCESS_TOKEN
      )}`,
    };
  }

  return headers;
};

const axiosInstance = (
  contentType: string = "application/json",
  responseType: ResponseType = "json",
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): AxiosInstance => {
  if (isShowLoading) toggleLoading(true);

  const instance = axios.create({
    responseType: responseType,
  });

  instance.interceptors.request.use(async (config: any) => {
    if (allowAnonymous) {
      config.headers["AppId"] = APPLICATION_ID;
      return config;
    }

    //can check ingore in here
    await checkRefreshTokenFinished(config);

    let idToken = LocalUtils.getCookie(LOCAL_COOKIE_KEY.ID_TOKEN);
    const refreshToken = LocalUtils.get(LOCAL_STORAGE_KEY.REFRESH_TOKEN);

    if (!idToken) {
      if (refreshToken && !IS_REFRESHING_TOKEN) {
        try {
          IS_REFRESHING_TOKEN = true;
          const refreshTokenRefresh: RefreshTokenModel = {
            refreshToken: refreshToken,
          };
          const { data } = await AuthApi.refreshToken(refreshTokenRefresh);
          const isRememberMe =
            LocalUtils.get(LOCAL_STORAGE_KEY.IS_REMEMBER_ME) == "true";
          LocalUtils.setAuthenticatedData(data, isRememberMe);
        } catch (error) {
          console.error(error);
          LocalUtils.remove(LOCAL_STORAGE_KEY.REFRESH_TOKEN);
        }

        IS_REFRESHING_TOKEN = false;
      }
    }

    config.headers = getHeaders(contentType);
    return config;
  });

  instance.interceptors.response.use(
    (response) => {
      if (isShowLoading) toggleLoading(false);

      return response;
    },
    (error) => {
      if (isShowLoading) toggleLoading(false);

      if (error.response.status === 401) {
        handleUnAuthorize();
      } else {
        const data = error.response.data;
        if (isShowErrorMessage) {
          let message = error.response.status === 403 ? i18n.t("common.forbiddenError") : i18n.t("common.serverError");

          if (data && data.message) {
            message = data.message;
          } else if (typeof data == "string" && data !== "") {
            message = data;
          }

          showNotification("error", message);
        }
      }

      return Promise.reject(error);
    }
  );

  return instance;
};

export const getAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false,
  arrayFormat?: "repeat" | "comma"
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: arrayFormat || "comma" });
    },
  });
};

export const getFileAsync = (
  url: string,
  params?: { [key: string]: any },
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "blob",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).get(url, {
    params: params,
    paramsSerializer: function (params) {
      return qs.stringify(params, { arrayFormat: "repeat" });
    },
  });
};

export const postAsync = (
  url: string,
  json?: object,
  isShowLoading = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).post(url, json);
};

export const putAsync = (
  url: string,
  json?: object,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, json);
};

export const deleteAsync = (
  url: string,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "application/json",
    "json",
    isShowLoading,
    isShowErrorMessage,
    (allowAnonymous = false)
  ).delete(url);
};

export const postFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false,
  config?: AxiosRequestConfig
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).post(url, parseFormdata(json), config);
};

export const putFormDataAsync = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, parseFormdata(json));
};

export const postFormDataMultipleFile = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, json);
};

export const putFormDataMultipleFile = (
  url: string,
  json?: any,
  isShowLoading: boolean = true,
  isShowErrorMessage = true,
  allowAnonymous = false
): Promise<AxiosResponse> => {
  return axiosInstance(
    "multipart/form-data",
    "json",
    isShowLoading,
    isShowErrorMessage,
    allowAnonymous
  ).put(url, json);
};

export const downloadAsync = (
  url: string,
  params?: object
): Promise<AxiosResponse> => {
  return axiosInstance("application/json", "blob", true).get(url, { params });
};

const parseFormdata = (model: any) => {
  const formdata = new FormData();
  Object.keys(model || {}).forEach((p) => {
    if (model[p]) {
      if (Array.isArray(model[p])) {
        (model[p] as Array<any>).forEach((q) => {
          formdata.append(p + "[]", q);
        });
      } else {
        formdata.append(p, model[p]);
      }
    }
  });

  return formdata;
};

function handleUnAuthorize() {
  const auth = getRecoil(authState);
  setRecoil(authState, { ...auth, isLogined: false });
  LocalUtils.clear();
}

function checkRefreshTokenFinished(config: any): Promise<boolean> {
  return new Promise((resolve) => {
    const timer = setInterval(() => {
      if (!IS_REFRESHING_TOKEN) {
        clearInterval(timer);
        resolve(true);
      }
    }, 100);
  });
}
