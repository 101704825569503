export enum FormAuth {
  Login = "Login",
  EmailVerification = "EmailVerification",
  PhoneVerification = "PhoneVerification",
  DualOTP = "DualOTP",
}

export enum MFAType {
  None = "none",
  EmailCode = "emailCode",
  PhoneCode = "phoneCode",
  DualOTP = "dualOTP",
}

export enum OTPType {
  None = "none",

  LoginMail = "LoginMail",
  LoginPhone = "LoginPhone",

  MFAMail = "MFAMail",
  MFAPhone = "MFAPhone",
  MFADualOTP = "MFADualOTP",

  ForgotPassUsingMail = "ForgotPassUsingMail",
  ForgotPassUsingPhone = "ForgotPassUsingPhone",
  ForgotPassUsingDualOTP = "ForgotPassUsingDualOTP",

  UserVerifyingMail = "UserVerifyingMail",
  UserVerifyingPhone = "UserVerifyingPhone",
  UserVerifyingMailAndPhone = "UserVerifyingMailAndPhone",
}

export enum ChangePasswordType {
  FisrtTimeLogin = 1,
  PasswordExpired = 2,
  PasswordWarning = 3
}