export enum KeyModeEnums {
  None = 0,
  DUKPTbyHSMKey = 1,
  NoneUnique = 2,
  KEK = 4,
}

export enum EncryptionModeEnums {
  None = 0,
  ECB = 1,
  CBC = 2,
  TR31 = 4,
}

export enum KeyAlgorithmEnums {
  None = 0,
  DES = 1,
  TDES = 2,
}

export enum KeysEnums {
  KeyModeEnums,
  EncryptionModeEnums,
  KeyAlgorithmEnums,
}

export const KeyDisplayName = {
  KeyModeDisplayName: {
    [KeyModeEnums.None]: "None",
    [KeyModeEnums.DUKPTbyHSMKey]: "DUKPT-BKT-Ecrypted by HSM Key",
    [KeyModeEnums.NoneUnique]: "None Unique TMK Per Device",
    [KeyModeEnums.KEK]: "KEK",
  },
  KeyAlgorithmDisplayName: {
    [KeyAlgorithmEnums.None]: "None",
    [KeyAlgorithmEnums.DES]: "2DES",
    [KeyAlgorithmEnums.TDES]: "TDES",
  },
  EncryptionModeDisplayName: {
    [EncryptionModeEnums.None]: "None",
    [EncryptionModeEnums.ECB]: "ECB",
    [EncryptionModeEnums.CBC]: "CBC",
    [EncryptionModeEnums.TR31]: "TR31",
  },
};
