import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import HostKeysManagementForm from "@/components/views/key-management/host-keys";

function KeysHostManagementPage() {
  const breadcrumbItems = ["Keys Host", "Key Management", "Keys Host"];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <HostKeysManagementForm />
    </Layout>
  );
}
export default KeysHostManagementPage;
