import { HostConfigApi } from "@/apis/host-config.api";
import { HostConfigDisplay } from "@/models/host-config";
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  SelectProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { KeysApi } from "../../../../../apis/keys.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import {
  EncryptionModeEnums,
  KeyAlgorithmEnums,
  KeyDisplayName,
  KeyModeEnums,
} from "../../../../../models/key-enums";
import { HostKey } from "../../../../../models/keys";
import BreadcrumbComponent from "../../../../common/breadcrumb";
import showNotification from "../../../../common/notification";
import "../index.scss";

function EditHostKeyForm() {
  const breadcrumbItems = ["Update Host Keys", "Key Management", "Host Keys"];
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [hostForm] = Form.useForm();
  const navigate = useNavigate();
  const [hostConfig, setHostConfig] = useState<HostConfigDisplay[]>([]);

  useEffect(() => {
    getHostConfigSelection();
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data } = await KeysApi.getHostKeyById(id);
          hostForm.setFieldsValue({
          name: data.name,
          keyMode: data.keyMode,
            ecryptValue: data.encryptedKeyValue,
            ecryptMode: data.encryptionMode,
            initialDID: data.initialDID,
            hostConfig: data.hostConfigEntityId,
            keyAlgorithm: data.keyAlgorithm,
            kcv: data.kcv,
            ksi: data.ksi,
            remark: data.remark
          });
        } catch (err) {}
      };
      fetchData();
    }
  }, []);

  const onSubmit = () => {
    hostForm.submit();
  };

  async function getHostConfigSelection() {
    const {data} = await HostConfigApi.getAllHostConfigs();
    setHostConfig(data);
  }

  const optionsKeyMode: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyModeDisplayName[0],
      value: KeyModeEnums.None,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[1],
      value: KeyModeEnums.DUKPTbyHSMKey,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[2],
      value: KeyModeEnums.NoneUnique,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[4],
      value: KeyModeEnums.KEK,
    },
  ];

  const optionsEncryptionMode: SelectProps["options"] = [
    {
      label: KeyDisplayName.EncryptionModeDisplayName[0],
      value: EncryptionModeEnums.None,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[1],
      value: EncryptionModeEnums.ECB,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[2],
      value: EncryptionModeEnums.CBC,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[4],
      value: EncryptionModeEnums.TR31,
    },
  ];

  const optionsKeyAlgorithm: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[0],
      value: KeyAlgorithmEnums.None,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[1],
      value: KeyAlgorithmEnums.DES,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[2],
      value: KeyAlgorithmEnums.TDES,
    },
  ];

  async function handleUpdateHSMKey(values: any) {
    setLoadingBtn(true);
    try {
      const req: HostKey = {
        id: id ?? DefaultGUID,
        name: values.name,
        hostConfigEntityId: values.hostConfig,
        encryptedKeyValue: values.ecryptValue,
        encryptionMode: values.ecryptMode,
        ksi: values.ksi,
        keyMode: values.keyMode,
        kcv: values.kcv,
        initialDID: values.initialDID,
        keyAlgorithm: values.keyAlgorithm,
        remark: values.remark,
      };
      
      var result = await KeysApi.updateHostKey(req);
      showNotification("success", "Updated Host Key successfully.");
      setLoadingBtn(false);
      if (result) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.HostKeys);
        }, 1000);
      }
    } catch (err) {}
  }

  const options: SelectProps["options"] = hostConfig.map((config) => ({
    label: config.name,
    value: config.id,
  }));


  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Content className="ctn-user-index">
        <Form
          className="edit-container"
          layout="vertical"
          labelWrap
          form={hostForm}
          onFinish={handleUpdateHSMKey}>
          <Row gutter={16}>
            <Col
              sm={18}
              xs={14}
              lg={12}>
              <div className="head-form">
                <div className="h4-semiBold">{t('key.host_key.title')}</div>
                <div className="body-regular ">{t('key.host_key.title')}</div>
              </div>
            </Col>
            <Col
              sm={6}
              xs={10}
              lg={12}>
              <Form.Item>
                <div className="btn-grp">
                  <Link to={ROUTE_PATHS.HostKeys}>
                    <Button className="btn-back btn-base btn-secondary-base">
                      {t("sysConfig.button.back")}
                    </Button>
                  </Link>
                  <Button
                    className="btn-sumbit btn-base btn-main-base"
                    loading={loadingBtn}
                    onClick={onSubmit}>
                    {t("sysConfig.button.save")}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="name"
                label={t("key.col_title.name")}
                hasFeedback
                rules={[{ required: true, message: "Key Name is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.name")}
                />
              </Form.Item>
              <Form.Item
                name="keyMode"
                label={t("key.col_title.key_mode")}
                rules={[{ required: true, message: "Key Mode is required" }]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.key_mode")}
                  options={optionsKeyMode}
                />
              </Form.Item>
              <Form.Item
                name="ecryptMode"
                label={t("key.col_title.encryption_mode")}
                rules={[
                  { required: true, message: "Encryption Key is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.encryption_mode")}
                  options={optionsEncryptionMode}
                />
              </Form.Item>
              <Form.Item
                name="ecryptValue"
                label={t("key.col_title.ecryptValue")}
                rules={[
                  { required: true, message: "Encryption Value is required" },
                ]}>
                <Input.Password className="input-base" />
              </Form.Item>
              <Form.Item
                name="initialDID"
                label={t("key.col_title.initialDID")}
                hasFeedback
                rules={[
                  { required: true, message: "Initial DID is required" },
                ]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.initialDID")}
                />
              </Form.Item>
            </Col>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="hostConfig"
                label={t("key.col_title.host_config_name")}
                hasFeedback
                rules={[{ required: true, message: "Host Config is required" }]}>
                <Select
                  className="select-base"
                  options={options}
                />
              </Form.Item>
              <Form.Item
                name="kcv"
                label={t("key.col_title.kcv")}
                hasFeedback
                rules={[{ required: true, message: "KCV is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.kcv")}
                />
              </Form.Item>
              <Form.Item
                name="keyAlgorithm"
                label={t("key.col_title.keyAlgorithm")}
                rules={[
                  { required: true, message: "Key Algorithm is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder="Key Mode"
                  options={optionsKeyAlgorithm}
                />
              </Form.Item>
              <Form.Item
                name="ksi"
                label={t("IKSN")}
                hasFeedback
                rules={[{ required: true, message: "IKSN is required" }]}>
                <Input.Password className="input-base" />
              </Form.Item>
              <Form.Item
                name="remark"
                label={t("key.col_title.remark")}>
                <TextArea placeholder={t("key.col_title.remark")}  autoSize={{ minRows: 2, maxRows: 3 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
}

export default EditHostKeyForm;
