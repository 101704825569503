import { UserOutlined } from "@ant-design/icons";
import { Avatar, Col, Modal, Row, Tag } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { LogType } from "../../../../../constants/app.enums";
import { AuditLog } from "../../../../../models/audit-log";
import DateUtils from "../../../../../utils/date.utils";
import "./log-component.scss";

interface LogProps {
  log: AuditLog;
  type: LogType;
}

function LogComponent(props: LogProps) {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const iconLog =
    props.type === LogType.OperationLog ? (
      <UserOutlined />
    ) : (
      <i className="icon-send" />
    );
  const colorItem =
    props.log.responseStatusCode !== 200 ? "rgb(217 73 84)" : "rgb(73 125 199)";

  const handleTitleClick = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const getColorByHttpMethod = (httpMethod: number): string => {
    switch (httpMethod) {
      case 200:
        return "green";
      case 500:
        return "red";
      default:
        return "red";
    }
  };

  const getLogStatusTitle = (httpMethod: number): string => {
    switch (httpMethod) {
      case 200:
        return "Success";
      case 500:
        return "Failed";
      default:
        return "Failed";
    }
  };

  return (
    <>
      <Link to="#" onClick={handleTitleClick}>
        <Row className="log-component-container">
          <Col span={1}>
            <Avatar
              className="log-icon"
              size={40}
              style={{ backgroundColor: colorItem, marginRight: "7px" }}
              icon={iconLog}
            />
          </Col>
          <Col span={18}>
            <Row className="display-container">
              <Col
                span={24}
                className="action-title "
                style={{ color: colorItem }}
              >
                {props.log.title}
              </Col>
              <Col span={24} className="details-log">
                {props.log.userEmail != null && (
                  <span>
                    {t("log.userName")}: {props.log.userEmail}
                  </span>
                )}

                <span>
                  {t("log.ipRequest")}: {props.log.ipAddress}
                </span>
                <span>
                  {t("log.resourcesUrl")}: {props.log.requestUrl}
                </span>
              </Col>
            </Row>
          </Col>
          <Col span={4}>
            <div className="time-request">
              {DateUtils.convertUTCToLocal(props.log.requestTime)}
            </div>
          </Col>
        </Row>
      </Link>
      <Modal
        title="View Detail"
        open={openModal}
        closable={false}
        footer={
          <button onClick={handleCloseModal}> {t("userMenu.close")}</button>
        }
        className="modal-log"
        width={700}
      >
        <div className="modal-body">
          <div className="modal-prop-title">
            <Avatar
              className="log-icon"
              size={40}
              src=""
              style={{ backgroundColor: colorItem, marginRight: "7px" }}
              icon={iconLog}
            />
            <span className="title-prop" style={{ color: colorItem }}>
              {props.log.title}
            </span>
          </div>
          <div className="modal-content">
            {props.type !== LogType.TransactionLog && (
              <>
                {props.log.userName != null && props.log.userName !== "" && (
                  <Row className="modal-prop">
                    <Col span={6} className="name-prop">
                      {t("log.userName")}
                    </Col>
                    <Col span={18} className="des-prop">
                      {props.log.userName}
                    </Col>
                  </Row>
                )}

                {props.log.userEmail != null && (
                  <Row className="modal-prop">
                    <Col span={6} className="name-prop">
                      {t("log.loginName")}
                    </Col>
                    <Col span={18} className="des-prop">
                      {props.log.userEmail}
                    </Col>
                  </Row>
                )}
              </>
            )}

            <Row className="modal-prop">
              <Col span={6} className="name-prop">
                {t("log.ipRequest")}
              </Col>
              <Col span={18} className="des-prop">
                {props.log.ipAddress}
              </Col>
            </Row>
            <Row className="modal-prop">
              <Col span={6} className="name-prop">
                {t("log.resourcesUrl")}
              </Col>
              <Col span={18} className="des-prop">
                {props.log.requestUrl}
              </Col>
            </Row>

            <Row className="modal-prop">
              <Col span={6} className="name-prop">
                {t("log.requestTime")}
              </Col>
              <Col span={18} className="des-prop">
                {DateUtils.convertUTCToLocal(props.log.requestTime)}
              </Col>
            </Row>

            {(props.log.requestBody !== null && props.log.requestBody?.value !=="") && (
              <>
                <Row className="modal-prop">
                  <Col span={6} className="name-prop">
                    {t("log.requestType")}
                  </Col>
                  <Col span={18} className="des-prop">
                    {props.log.requestBody?.type}
                  </Col>
                </Row>

                <Row className="modal-prop">
                  <Col span={6} className="name-prop">
                    {t("log.requestBody")}
                  </Col>
                  <Col span={18} className="des-prop">
                    {props.log.requestBody?.value}
                  </Col>
                </Row>
              </>
            )}

            <Row className="modal-prop">
              <Col span={6} className="name-prop">
                {t("log.responseTime")}
              </Col>
              <Col span={18} className="des-prop">
                {DateUtils.convertUTCToLocal(props.log.responseTime)}
              </Col>
            </Row>
            {props.log.responseBody !== null && (
              <>
                <Row className="modal-prop">
                  <Col span={6} className="name-prop">
                    {t("log.responseType")}
                  </Col>
                  <Col span={18} className="des-prop">
                    {props.log.responseBody?.type}
                  </Col>
                </Row>
                <Row className="modal-prop">
                  <Col span={6} className="name-prop">
                    {t("log.responseBody")}
                  </Col>
                  <Col span={18} className="des-prop">
                    {props.log.responseBody?.value}
                  </Col>
                </Row>
              </>
            )}

            <Row className="modal-prop">
              <Col span={6} className="name-prop">
                {t("log.responseStatus")}
              </Col>
              <Col span={18} className="des-prop">
                <Tag color={getColorByHttpMethod(props.log.responseStatusCode)}>
                  {getLogStatusTitle(props.log.responseStatusCode)}
                </Tag>
              </Col>
            </Row>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default LogComponent;
