import { Col, Pagination, Select } from "antd";

import "./pagination.scss";
import CommonPageSizeOptions from "../pagination";

interface PaginationComponentProps {
  totalRecords: number;
  handleGetFilter?: (pageSize: number, page: number) => void;
  pageSize: number;
  setPageSize: (newPageSize: number) => void;
  pageNumber: number;
  setPageNumber: (newPageNumber: number) => void;
}

const PaginationComponent = ({
  totalRecords,
  handleGetFilter,
  pageSize,
  setPageSize,
  pageNumber,
  setPageNumber,
}: PaginationComponentProps) => {
  const onTableChange = async (page: number, pageSize: number) => {
    setPageNumber(page);
    setPageSize(pageSize);
    handleGetFilter && await handleGetFilter(pageSize, page);
  };

  async function handleChangePageSize(values: number) {
    setPageSize(values);
    handleGetFilter && await handleGetFilter(values, pageNumber);
  }

  return (
    <>
      <Col
        span={8}
        className="pagination-cmp ">
        <div className="pagination-left-cmp">
          <Select
            className="select-page-size"
            defaultValue={pageSize}
            style={{ width: 110 }}
            onChange={handleChangePageSize}
            options={CommonPageSizeOptions()}
          />
          <span className="show-total">{`Total ${totalRecords} items`}</span>
        </div>
      </Col>
      <Col
        span={16}
        className="pagination-cmp ">
        <div className="pagination-right-cmp">
          <Pagination
            current={pageNumber}
            pageSize={pageSize}
            onChange={onTableChange}
            total={totalRecords}
            showQuickJumper={true}
          />
        </div>
      </Col>
    </>
  );
};

export default PaginationComponent;
