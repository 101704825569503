import { AxiosResponse } from "axios";
import {
  EncrytedKey,
  EncrytedKeyModelDisplay,
  HSMKeys,
  HSMKeysModelDisplay,
  HostKey,
  KeyTemplateDetail,
  KeyTemplateDetailModal,
  KeyTemplateModelAPI,
  KeyTemplateModelDisplay,
  TerminalKeyModel,
} from "../models/keys";
import { PaginationResponseModel } from "../models/pagination-response-model";
import {
  deleteAsync,
  getAsync,
  postAsync,
  putAsync,
  putFormDataAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Portal}/v1`;

function getAllHSMKey(): Promise<AxiosResponse<HSMKeysModelDisplay[]>> {
  const url = `${baseUrl}/key/get-all-hsm`;
  return getAsync(url);
}

function getHSMById(id: string): Promise<AxiosResponse<HSMKeysModelDisplay>> {
  const url = `${baseUrl}/key/get-hsm/${id}`;
  return getAsync(url);
}

function createHSM(model: HSMKeys): Promise<AxiosResponse<HSMKeys>> {
  const url = `${baseUrl}/key/add-hsm-key`;
  return postAsync(url, model, true, true);
}

function updateHSM(model: HSMKeys): Promise<AxiosResponse<HSMKeys>> {
  const url = `${baseUrl}/key/update-hsm/`;
  return putAsync(url, model, true, true);
}

function deleteHSM(id: string) {
  const url = `${baseUrl}/key/hsm-key/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function getAllEncryptedKeys(): Promise<
  AxiosResponse<EncrytedKeyModelDisplay[]>
> {
  const url = `${baseUrl}/key/get-all-ecrypted-keys`;
  return getAsync(url);
}

function getEncryptedKeyById(id: string): Promise<AxiosResponse<EncrytedKey>> {
  const url = `${baseUrl}/key/get-ecrypted-key/${id}`;
  return getAsync(url);
}

function updateEncryptedKey(
  model: EncrytedKey
): Promise<AxiosResponse<EncrytedKey>> {
  const url = `${baseUrl}/key/add-ecrypted-key`;
  return putFormDataAsync(url, model, true, true);
}

function createEncryptedKey(
  model: EncrytedKey
): Promise<AxiosResponse<EncrytedKey>> {
  const url = `${baseUrl}/key/add-ecrypted-key`;
  return postAsync(url, model, true, true);
}

function deleteEncryptedKey(id: string) {
  const url = `${baseUrl}/key/ecrypted-key/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function getAllKeyTemplates(): Promise<
  AxiosResponse<KeyTemplateModelDisplay[]>
> {
  const url = `${baseUrl}/key/get-all-key-templates`;
  return getAsync(url);
}

function getKeyTemplatesDetail(
  id: string
): Promise<AxiosResponse<KeyTemplateDetail>> {
  const url = `${baseUrl}/key/get-key-template/${id}`;
  return getAsync(url);
}

function getKeyTemplatesDetailModal(
  id: string
): Promise<AxiosResponse<KeyTemplateDetailModal>> {
  const url = `${baseUrl}/key/get-key-template-modal/${id}`;
  return getAsync(url);
}

function getAllTerminalKeys(): Promise<AxiosResponse<TerminalKeyModel[]>> {
  const url = `${baseUrl}/key/terminal-key-all`;
  return getAsync(url);
}

function getAllTerminalKeysFilter(
  pageIndex: number,
  pageSize: number,
  searchString: string,
  keyTemplateId: string
): Promise<AxiosResponse<PaginationResponseModel<TerminalKeyModel>>> {
  const url = `${baseUrl}/key/terminal-key-all?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${searchString}&keyTemplateId=${keyTemplateId}`;
  return getAsync(url);
}

function createTerminalKey(
  model: TerminalKeyModel
): Promise<AxiosResponse<TerminalKeyModel>> {
  const url = `${baseUrl}/key/add-terminal-key`;
  return postAsync(url, model, true, true);
}

function createKeyTemplate(
  model: KeyTemplateModelAPI
): Promise<AxiosResponse<KeyTemplateModelAPI>> {
  const url = `${baseUrl}/key/add-key-template`;
  return postAsync(url, model, true, true);
}

function updateKeyTemplate(
  model: KeyTemplateModelAPI
): Promise<AxiosResponse<KeyTemplateModelAPI>> {
  const url = `${baseUrl}/key/update-key-template`;
  return putAsync(url, model, true, true);
}

function deleteKeyTemplate(id: string) {
  const url = `${baseUrl}/key/key-template/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function deleteTerminalKey(id: string) {
  const url = `${baseUrl}/key/terminal-key/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function getAllHostKeys(): Promise<AxiosResponse<HostKey[]>> {
  const url = `${baseUrl}/key/host-keys`;
  return getAsync(url);
}

function getHostKeyById(id: string): Promise<AxiosResponse<HostKey>> {
  const url = `${baseUrl}/key/host-key/${id}`;
  return getAsync(url);
}

function updateHostKey(model: HostKey): Promise<AxiosResponse<HostKey>> {
  const url = `${baseUrl}/key/host-key/`;
  return putAsync(url, model, true, true);
}

function deleteHostKey(id: string) {
  const url = `${baseUrl}/key/host-key/${id}`;
  return deleteAsync(url, undefined, true, true);
}

export const KeysApi = {
  getAllHSMKey,
  getAllEncryptedKeys,
  getAllKeyTemplates,
  getHSMById,
  createHSM,
  updateHSM,
  deleteHSM,
  getEncryptedKeyById,
  updateEncryptedKey,
  createEncryptedKey,
  deleteEncryptedKey,
  getAllTerminalKeys,
  createTerminalKey,
  createKeyTemplate,
  getAllTerminalKeysFilter,
  getKeyTemplatesDetail,
  deleteKeyTemplate,
  updateKeyTemplate,
  getKeyTemplatesDetailModal,
  deleteTerminalKey,
  getAllHostKeys,
  getHostKeyById,
  updateHostKey,
  deleteHostKey
};
