import { Form, Switch } from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";
import "./switch-component.scss";

interface SwitchComponentProps {
  label: string;
  name: string;
  valuePropName: string;
  onChange?: SwitchChangeEventHandler;
}

function SwitchComponent(props: SwitchComponentProps) {
  return (
    <div className="row-switch-item">
      <div className="label-switch">{props.label}</div>
      <Form.Item
        name={props.name}
        valuePropName={props.valuePropName}>
        <Switch className="switch-class" onChange={props.onChange}/>
      </Form.Item>
    </div>
  );
}

export default SwitchComponent;
