import { FacebookOutlined, HomeOutlined, InstagramOutlined, TwitterOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserApi } from "../../../../../apis/user.api";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import { UserModelContact } from "../../../../../models/user";

function ContactDetails() {
  const { t } = useTranslation();
  const [contactForm] = Form.useForm();
  const { id } = useParams();
  const [user, setUser] = useState<UserModelContact | undefined>();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const responseData = await handlerGetUserById(id);
          contactForm.setFieldsValue({
            homeAddress: responseData?.homeAddress,
            workAddress: responseData?.workAddress,
            hometownAddress: responseData?.hometownAddress,
            facebookAddress: responseData?.facebookAddress,
            twitterAddress: responseData?.twitterAddress,
            instagramAddress: responseData?.instagramAddress,
            homePhone: responseData?.homePhone,
            workPhone: responseData?.workPhone,
          });
          setUser(responseData);
          setIsLoading(false);
        } catch (error) { }
      };
      fetchData();
    }
  }, [id]);

  async function handlerGetUserById(
    id: string
  ): Promise<UserModelContact | undefined> {
    const response = await UserApi.getUserContactById(id);
    return response.data;
  }

  const onSubmit = () => {
    contactForm.submit();
  };

  async function handlePostUser(values: any) {
    setLoadingBtn(true);
    try {
      const userRequest: UserModelContact = {
        id: user?.id ?? "null",
        homeAddress: values.homeAddress,
        workAddress: values.workAddress,
        hometownAddress: values.hometownAddress,
        facebookAddress: values.facebookAddress,
        twitterAddress: values.twitterAddress,
        instagramAddress: values.instagramAddress,
        homePhone: values.homePhone,
        workPhone: values.workPhone,
      };

      let data = await UserApi.putUserContact(userRequest);
      if (data) {
        navigate(ROUTE_PATHS.Users);
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleContactDetails() {
    return (
      <>
        <Row gutter={10}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userContact_desc")}</div>
              <div className="body-regular ">{t("title.userContact_desc")}</div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.Users}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onSubmit}
                  htmlType="submit"
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <TitleContactDetails />
      <Form
        name="infomationForm"
        layout="vertical"
        form={contactForm}
        onFinish={handlePostUser}
        className="form-submit-contact"
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="homePhone"
              label={t("sysConfig.userPermission.home_phone")}
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: "This field is allowed digits only.",
                },
              ]}
            >
              <Input
                className="input-base"
                placeholder={t("sysConfig.userPermission.home_phone")}
              />
            </Form.Item>
            <Form.Item
              name="homeAddress"
              label={t("sysConfig.userPermission.home_address")}
            >
              <Input
               prefix={<HomeOutlined  className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.home_address")}
              />
            </Form.Item>
            <Form.Item
              name="hometownAddress"
              label={t("sysConfig.userPermission.hometown_address")}
            >
              <Input
               prefix={<HomeOutlined className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.hometown_address")}
              />
            </Form.Item>
            <Form.Item
              name="twitterAddress"
              label={t("sysConfig.userPermission.twitter_address")}
            >
              <Input
                prefix={<TwitterOutlined className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.twitter_address")}
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="workPhone"
              label={t("sysConfig.userPermission.work_phone")}
              rules={[
                {
                  pattern: /^[0-9]+$/,
                  message: "This field is allowed digits only.",
                },
              ]}
            >
              <Input
                className="input-base"
                placeholder={t("sysConfig.userPermission.work_phone")}
              />
            </Form.Item>
            <Form.Item            
              name="workAddress"
              label={t("sysConfig.userPermission.work_address")}
            >
              <Input
               prefix={<HomeOutlined className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.work_address")}
              />
            </Form.Item>
            <Form.Item
              name="facebookAddress"
              label={t("sysConfig.userPermission.facebook_address")}
            >
              <Input
                prefix={<FacebookOutlined className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.facebook_address")}
              />
            </Form.Item>
            <Form.Item
              name="instagramAddress"
              label={t("sysConfig.userPermission.instagram_address")}
            >
              <Input
                prefix={<InstagramOutlined className="site-form-item-icon" />}
                className="input-base"
                placeholder={t("sysConfig.userPermission.instagram_address")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}
export default ContactDetails;
