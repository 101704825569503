import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Button, Col, Row, Space, Table, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserApi } from "../../../apis/user.api";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { UserModelDisplay } from "../../../models/user";
import { CommonUtils } from "../../../utils/common";
import DateUtils from "../../../utils/date.utils";
import showConfirmModal from "../../common/modal-confirm";
import showNotification from "../../common/notification";
import PaginationComponent from "../../common/pagination/component/pagination";
import "./user.scss";

function UserManagementForm() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [users, setUsers] = useState<UserModelDisplay[] | any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetAllUserFilter();
  }, []);

  const tableScroll = {
    x: "max-content",
  };

  const userColumns: ColumnsType<UserModelDisplay> = [
    {
      title: "User",
      key: "1",
      render: (text: string, record: any) => (
        <>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            src={CommonUtils.createFileUrl(record.personalImage)}
            style={{ backgroundColor: "#4E46DC", marginRight: "7px" }}
            icon={<UserOutlined />}
          />
          {record.fullName}
        </>
      ),
    },
    {
      title: "Contact",
      key: "2",
      render: (text: string, record: any) => (
        <>
          <Row> {record.email}</Row>
          <Row> {record.cellE164Number}</Row>
        </>
      ),
    },
    {
      title: "Status",
      key: "3",
      className: "col-status",
      dataIndex: "isDeactivate",
      render: (isDeactivate) => {
        return (
          <>
            {isDeactivate && (
              <Badge
                status="error"
                text="Deactivate"
              />
            )}
            {!isDeactivate && (
              <Badge
                status="success"
                text="Activate"
              />
            )}
          </>
        );
      },
    },
    {
      title: "Register time",
      className: "col-register-time",
      key: "4",
      render: (text: string, record: any) => (
        <Col>{DateUtils.convertUTCToLocal(record.createdOn)}</Col>
      ),
    },
    {
      title: "Last login time",
      className: "col-last-login-time",
      key: "5",
      render: (text: string, record: any) => (
        <Col>{DateUtils.convertUTCToLocal(record.lastActivity)}</Col>
      ),
    },
    {
      key: "6",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title={record.isDeactivate ? "Active" : "Deactive"}>
            <Button
              className={
                !record.isDeactivate
                  ? "action-grp-deactive-btn"
                  : "action-grp-active-btn"
              }
              icon={
                <i
                  className={
                    !record.isDeactivate ? "icon-ok-1" : "icon-cancel-1"
                  }
                />
              }
              onClick={() =>
                handleClickActive(record.id!.toString(), record.isDeactivate)
              }>
              {!record.isDeactivate ? (
                <span className="active">{t("sysConfig.button.active")}</span>
              ) : (
                <span className="deactive">{t("sysConfig.button.deactive")}</span>
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Link
              to={ROUTE_PATHS.UserEdit.replace(":id", record.id!.toString())}>
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={
                <i
                  className="icon-delete"
                  onClick={() => handleClickDelete(record.id!.toString())}
                />
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleGetAllUserFilter = async (
    pageSizeData?: number,
    pageNumberData?: number,
    searchString?: string
  ) => {
    pageSizeData = pageSizeData || pageSize;
    pageNumberData = pageNumberData || pageNumber;
    searchString = searchString || "";
    setIsLoading(true);
    try {
      const { data } = await UserApi.getAllUsersFilter(
        pageNumberData,
        pageSizeData,
        searchString
      );
      setUsers(data.records);
      setIsLoading(false);
      setTotalRecords(data.totalRecords);
    } catch (error) {}
  };

  const handleClickActive = (id: string, isDeactivate?: boolean): void => {
    let title = isDeactivate ? "Active" : "Deactive";

    const handleConfirm = async () => {
      try {
        let { data: activeResponse } = await UserApi.activeUser(
          id,
          isDeactivate
        );
        if (activeResponse) {
          showNotification("success", t("user.notiUpdateUserSucces"));
          await handleGetAllUserFilter();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
    };

    showConfirmModal({
      type: "warning",
      title: `${title} this user now`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await UserApi.deleteUser(id);
        if (deleteResponse) {
          showNotification("success", t("modal.noti_deleted_success"));
          await handleGetAllUserFilter();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: t("title.delete_user_title_modal"),
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const onSearch = (value: any) => {
    handleGetAllUserFilter(undefined, undefined, value);
  };

  return (
    <div className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t("menuDashboard.users")}</Row>
          <Row className="body-regular">{totalRecords} {t("user.registeredUsers")}</Row>
        </Col>
        <Col
          span={12}
          className="table-cmp">
          <Link to={ROUTE_PATHS.UserCreate}>
            <Button
              className="btn-create btn-base btn-main-base"
              icon={<UserAddOutlined />}>
              {t("user.btn_add_new_user")}
            </Button>
          </Link>
          <Search
            className="search-cmp"
            placeholder="Search"
            onSearch={onSearch}
            size="large"
            style={{ maxWidth: 400 }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Table
                rowKey={(x) => x.id}
                loading={isLoading}
                className="tb-list-user"
                columns={userColumns}
                dataSource={users}
                scroll={tableScroll}
                pagination={{
                  pageSize: pageSize,
                  current: pageNumber,
                  style: { display: "none" },
                }}
              />
            </Col>
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              handleGetFilter={handleGetAllUserFilter}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default UserManagementForm;
