import {
  Col,
  Divider,
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
} from "antd";
import { SwitchChangeEventHandler } from "antd/es/switch";
import { useTranslation } from "react-i18next";
import SwitchComponent from "../../../../common/form-component/switch-form-item/switch-component";

interface ApplicationPermissionsProps {
  formSubmit: FormInstance<any>;
}

function ApplicationPermissions(props: ApplicationPermissionsProps) {
  const { t } = useTranslation();

  const handleSwitchChange: SwitchChangeEventHandler = (checked, event) => {
    const { id } = event.currentTarget;

    props.formSubmit.setFieldsValue({
      isCellCodeRequired: false,
      isEmailCodeRequired: false,
      isDualOTP: false,
    });
    props.formSubmit.setFieldsValue({ [id]: checked });
  };

  return (
    <>
      <Divider className="h5-semiBold" orientation="left" orientationMargin="0">
        {t("sysConfig.label.applicationPermission")}
      </Divider>
      <Row>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.allow_mail_login")}
            name="aAllowMailLogin"
            valuePropName="checked"
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.allow_phone_login")}
            name="aAllowPhoneLogin"
            valuePropName="checked"
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.userManagerOTPSMSRequired")}
            name="userManagerOTPSMSRequired"
            valuePropName="checked"
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.isEmailCodeRequired")}
            name="isEmailCodeRequired"
            valuePropName="checked"
            onChange={handleSwitchChange}
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.isCellCodeRequired")}
            name="isCellCodeRequired"
            valuePropName="checked"
            onChange={handleSwitchChange}
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.isDualOTP")}
            name="isDualOTP"
            valuePropName="checked"
            onChange={handleSwitchChange}
          />
        </Col>

      </Row>
      <Divider className="h5-semiBold" orientation="left" orientationMargin="0">
        {t("Login Failure")}
      </Divider>
      <Row>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.loginFailedLockEnabled")}
            name="loginFailedLockEnabled"
            valuePropName="checked"
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.loginFailedNotifyMail")}
            name="loginFailedNotifyMail"
            valuePropName="checked"
          />
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <SwitchComponent
            label={t("sysConfig.appPermissions.loginFailedNotifyPhone")}
            name="loginFailedNotifyPhone"
            valuePropName="checked"
          />
        </Col>
      </Row>
      <Row>
        <Col lg={8} sm={24} xs={24}>
          <Form.Item
            label={t("sysConfig.appPermissions.failureRetryTimesAllowed")}
            name="failureRetryTimesAllowed"
          >
            <InputNumber className="input-base" />
          </Form.Item>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <Form.Item
            label={t("sysConfig.appPermissions.lockedWhenFailedXMinutesConfig")}
            name="lockedWhenFailedXMinutesConfig"
          >
            <InputNumber className="input-base" />
          </Form.Item>
        </Col>
        <Col lg={8} sm={24} xs={24}>
          <Form.Item
            label={t(
              "sysConfig.appPermissions.lockedWhenOutOfRetryTimesConfig"
            )}
            name="lockedWhenOutOfRetryTimesConfig"
          >
            <InputNumber className="input-base" />
          </Form.Item>
        </Col>
        <Form.Item
          name="passcode"
          label={t("verify.ModalInsertPasscord")}
          hidden={true}
        >
          <Input.Password
            className="input-base"
            placeholder={t("user.yourPasscode")}
          />
        </Form.Item>
      </Row>
    </>
  );
}

export default ApplicationPermissions;
