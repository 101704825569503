import { useTranslation } from "react-i18next";
import group from "../../../../assets/images/Group.svg";
import { Alert, Button, Form } from "antd";
import { FormAuth } from "../../../../models/enums";
import { VALIDATION_MESSAGE_CONFIG } from "../../../../constants/app-constants";
import { Link } from "react-router-dom";
import { InputOTP } from "antd-input-otp";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { signInFormProps } from "../props/props";

function StepVerificationPhone({
  form,
  signInResponse,
  messageAlert,
  loading,
  handleSubmit,
}: signInFormProps) {
  const { t } = useTranslation();

  return (
    <div className="verification">
      <div className="logo-group">
        <img
          src={group}
          alt=""
        />
      </div>

      <div className="header">
        <div className="title h2-semiBold">{t("login.titleVerification")}</div>
        <div className="title-detail body-regular">
          {t("login.titleVerificationDetail")}
        </div>
      </div>

      <div className="info-phone-email">
        <div className="h4-semiBold ">
          {signInResponse && signInResponse.userPhoneNumber}
        </div>
      </div>

      <div className="info-phone-email">
        <div className="body-semiBold ">{t("login.titleOTP")}</div>
      </div>

      <div className="sign-in-form">
        {messageAlert && (
          <Alert
            message={messageAlert}
            type="error"
            showIcon
            closable
          />
        )}

        <br />

        <Form
          name=""
          layout="vertical"
          validateMessages={VALIDATION_MESSAGE_CONFIG}
          onFinish={() => handleSubmit(FormAuth.PhoneVerification)}
          form={form}
          className="hiden-required-mark">
          <Form.Item
            name="otp"
            className="input-otp-content">
            <InputOTP
              inputClassName="input-otp body-semiBold"
              inputType="numeric"
              length={6}
            />
          </Form.Item>

          <Form.Item>
            <Button
              htmlType="submit"
              className="btn-login btn-base body-regular"
              type="primary"
              block
              loading={loading}>
              {t("login.verificationContinue")}
            </Button>
          </Form.Item>

          <div className="get-code">
            <span className="body-regular">Didn’t get the code?&nbsp;</span>
            <span>
              <Link
                tabIndex={-1}
                to={ROUTE_PATHS.Home}
                className="body-regular-underline">
                {t("login.resend")}
              </Link>
            </span>
            <span className="body-regular"> &nbsp;or&nbsp; </span>
            <span>
              <Link
                tabIndex={-1}
                to={ROUTE_PATHS.Home}
                className="body-regular-underline">
                Call Us
              </Link>
            </span>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default StepVerificationPhone;
