import { Button, Form } from "antd";
import { Link, useLocation } from "react-router-dom";
import logo from "../../../assets/images/PAX_Logo.svg";
import { SESSION_TIMEOUT_CONFIG } from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import "./index.scss";

const SessionTimedOutForm = () => {

    let location = useLocation();

    return (
        <>
          <div className="auth-layout__logo_wrapper">
            <Link to={ROUTE_PATHS.Home}>
              <img
                src={logo}
                className="auth-layout__logo"
                alt=""
              />
            </Link>
          </div>
          <div className="session-timed-out">
            <div className="header">
                <div className="title h2-semiBold">{location.state?.Title ?? SESSION_TIMEOUT_CONFIG.sessionWarningContentData.Title }</div>
            </div>
            <div className="session-timed-out-form">
                <Form layout="vertical" className="hiden-required-mark">
                    <div className="session-timed-out-content">{location.state?.Content ?? SESSION_TIMEOUT_CONFIG.sessionWarningContentData.Content}</div>
                    <Form.Item>
                        <Button
                        onClick={() => {window.location.href = ROUTE_PATHS.SignIn;}}
                        className="btn-login btn-base body-regular"
                        type="primary"
                        block>
                        Sign in
                        </Button>
                    </Form.Item>
                </Form>
            </div>
          </div>
        </>
      );
}

export default SessionTimedOutForm;