import { Breadcrumb } from "antd";
import "./index.scss";

interface BreadcrumbComponentProps {
  items: string[];
}

function BreadcrumbComponent({ items }: BreadcrumbComponentProps) {
  const firstItem = items[0];
  const breadcrumbItems = items.slice(1);

  return (
    <div className="bread-crumb">
      <span className="h2-semiBold">{firstItem}</span>
      <Breadcrumb style={{ margin: "5px 0 24px 0" }}>
        {breadcrumbItems.map((item, index) => (
          <Breadcrumb.Item
            className={
              index === breadcrumbItems.length - 1 ? "current-page" : ""
            }
            key={index}
          >
            {item}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
    </div>
  );
}

export default BreadcrumbComponent;
