import "@/assets/fontello/css/fontello.css";
import {
  LOCAL_UI_STORAGE_VALUE,
  SCREEN_WIDTH_SMALL,
  SCREEN_WIDTH_XSMALL,
} from "@/constants/app-constants";
import { ROUTE_PATHS } from "@/constants/router.constants";
import { UserModelInf } from "@/models/user";
import { collapsedState } from "@/states/sidebar";
import { uiSettingState } from "@/states/uiSetting";
import { CommonUtils } from "@/utils/common";
import { SearchOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Space } from "antd";
import Input from "antd/es/input/Input";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import backgroundImage from "../../../../../assets/images/PAX_Logo_Dashboard.svg";
import SlideRight from "./components/slideRight";
import UserMenu from "./components/userMenu/";
import "./index.scss";

const { Header: AntdHeader } = Layout;

export interface HeaderProps {
  user: UserModelInf | undefined;
  trigger: React.ReactNode;
}

function HeaderDashboard(headerProps: HeaderProps) {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [openDrawer, setOpenDrawer] = useState(false);
  const setCollapsedState = useSetRecoilState(collapsedState);
  const uiSetting = useRecoilValue(uiSettingState);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function handleOnCollapsed() {
    return setCollapsedState((prevCollapsedState) => ({
      isCollapsed: !prevCollapsedState.isCollapsed,
    }));
  }

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };


  const path = uiSetting.uiLogoImage === LOCAL_UI_STORAGE_VALUE.LOGO_GATEWAY_PATH || uiSetting.uiLogoImage === "" ? backgroundImage : CommonUtils.createFileUrl(uiSetting.uiLogoImage, process.env.REACT_APP_API_ENDPOINT_Portal);

  return (
    <>
      <AntdHeader className="dashboard-header">
        <Row className="dashboard-row">
          <Col>
            <Link to={ROUTE_PATHS.Home}>
              <img
                src={path}
                className="dashboard-logo"
                alt="Pax logo"
              />
            </Link>
          </Col>
          <Col className="menu-icon">
            <Button
              type="text"
              icon={headerProps.trigger}
              onClick={handleOnCollapsed}
            />
          </Col>
          <Col>
            {windowWidth > SCREEN_WIDTH_SMALL && (
              <Input
                size="large"
                className="dashboard-search"
                placeholder="Search"
                prefix={<SearchOutlined />}
              />
            )}
          </Col>

          <Col className="flex-end">
            {windowWidth > SCREEN_WIDTH_XSMALL && (
              <>
                <Space>
                  <i className="header-icon icon-notification"></i>
                </Space>
                <Space>
                  <i className="header-icon  icon-setting"></i>
                </Space>
                <Space>
                  <i className="header-icon icon-message"></i>
                </Space>
              </>
            )}

            <Space>
              <div className="user-icon">
                <UserMenu userData={headerProps.user} />
              </div>
            </Space>

            <Space>
              <i
                className="header-icon icon-category"
                onClick={toggleDrawer}></i>
            </Space>
          </Col>
        </Row>
      </AntdHeader>

      <SlideRight
        openDrawer={openDrawer}
        setOpenDrawer={() => setOpenDrawer(false)}
      />
    </>
  );
}

export default HeaderDashboard;