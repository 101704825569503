import { Form, Input, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UserApi } from "../../../../../apis/user.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { UserModelInf, UserRestPasscode } from "../../../../../models/user";
import "../../../../common//modal-mfa/index.scss";
import showNotification from "../../../../common/notification";

export interface ModalResetPasscodeProps {
  visible: boolean;
  onClose: () => void;
  user: UserModelInf | undefined;
}

export function ModaResetPasscode({
  visible,
  onClose,
  user,
}: ModalResetPasscodeProps) {
  const { t } = useTranslation();
  const [changePasscodeForm] = Form.useForm();
  const [isLoadingModal, setLoadingModal] = useState(false);

  const handleOk = () => {
    changePasscodeForm.submit();
  };

  const handleCancel = () => {
    changePasscodeForm.resetFields();
    onClose();
  };

  async function handleResetPasscode(values: any) {
    const model: UserRestPasscode = {
      userId: user?.id || DefaultGUID,
      adminPasscode: values.passcode,
    };
    setLoadingModal(true);

    try {
      let data = await UserApi.userResetPasscode(model);
      if (data) {
        const message = "Reset Passcode success";
        showNotification("success", message);
        onClose();
      }
    } catch (error) {
    }
    finally{
      setLoadingModal(false);
    }
  }


  return (
    <>
      <Modal
        className="modal-confirm"
        title={t("user.resetPassword")}
        open={visible}
        okText="Reset"
        okButtonProps={{ loading: isLoadingModal }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          className="modal-container"
          layout="vertical"
          labelWrap
          form={changePasscodeForm}
          onFinish={handleResetPasscode}
        >
          <Form.Item
            name="email"
            label={t("user.emailConfirmResetPasscode")}
            messageVariables={{ label: "Email" }}
            initialValue={user?.email}
          >
            <Input className="input-base" placeholder="Email" disabled={true} />
          </Form.Item>
          <Form.Item
            name="passcode"
            label={t("user.confirmResetPasscode")}
            hasFeedback
            rules={[{ required: true, message: "Admin Passcode is required" }]}
          >
            <Input.Password
              className="input-base"
              placeholder={t("user.yourPasscode")}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

export default ModaResetPasscode;
