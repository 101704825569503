import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import EncryptedKeyManagementForm from "../../components/views/key-management/encrypted-keys";

function EncryptedKeyManagementPage() {
  const breadcrumbItems = [
    "Encrypted Keys",
    "Key Management",
    "Encrypted Keys",
  ];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <EncryptedKeyManagementForm />
    </Layout>
  );
}
export default EncryptedKeyManagementPage;
