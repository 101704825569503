import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { UserApi } from '../../../apis/user.api';
import { UpdateUserPhoneRequest } from "../../../models/user";
import showNotification from '../notification/index';
import "./index.scss";
import InsertPasscodeForm from './modal-input-passcode';

export interface UpdateUserPhonePopupProps {
  visible: boolean;
  onClose: (isReload:boolean) => void;
  cellNumber: string;
  cellCountryCode: string;
  cellDialCode: string;
  userId: string;
}

export function UpdateUserPhonePopup({
  visible,
  onClose,
  cellNumber,
  cellCountryCode,
  cellDialCode,
  userId
}: UpdateUserPhonePopupProps) {
  const { t } = useTranslation();
  const [updateUserPhoneForm] = Form.useForm();
  const [isLoadingModal, setLoadingModal] = useState(false);
  const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] = useState(false);

  const handleOk = () => {
    setIsConfirmPasswordModalOpen(true)
  };

  const handleCancel = () => {
    updateUserPhoneForm.resetFields();
    setIsConfirmPasswordModalOpen(false);
    onClose(false);
  };

  const handleUpdateUserPhone = async (values: UpdateUserPhoneRequest) => {
    setLoadingModal(true);

    try {
      await UserApi.updateUserPhone(values);
      showNotification("success", "Updated user phone successfully.");
      handleCancel();
      window.location.reload();
    } catch (error:any) {
      const errorMessage = error.response?.data?.message || "An error occurred while updating the user phone number.";
      showNotification("error", errorMessage);
    } finally {
      setLoadingModal(false);
    }
  };

  const handleChangePhoneInput = (
    value: any,
    data: any,
    event: React.FocusEvent<HTMLInputElement>,
    formattedValue: any
  ) => {
    data?.countryCode &&
    updateUserPhoneForm.setFieldValue(
        "cellCountryCode",
        data.countryCode.toUpperCase()
      );
    data?.dialCode &&
    updateUserPhoneForm.setFieldValue("cellDialCode", data.dialCode);
  };

  useEffect(() => {
    updateUserPhoneForm.resetFields();
    updateUserPhoneForm.setFieldValue("cellNumber", cellNumber);
    updateUserPhoneForm.setFieldValue("cellCountryCode", cellCountryCode);
    updateUserPhoneForm.setFieldValue("cellDialCode", cellDialCode);
    updateUserPhoneForm.setFieldValue("id", userId);
  }, [cellNumber, visible]);
  
  return (
    <>
    <Modal
      className="modal-confirm"
      title="Update user phone"
      open={visible}
      okText="Next"
      okButtonProps={{ loading: isLoadingModal }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        className="modal-container"
        layout="vertical"
        labelWrap
        form={updateUserPhoneForm}
        onFinish={handleUpdateUserPhone}
      >
        <Form.Item
          label="id"
          name="id"
          hidden={true}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="cellCountryCode"
          name="cellCountryCode"
          hidden={true}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          label="cellDialCode"
          name="cellDialCode"
          hidden={true}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="cellNumber"
          className={`phone-number`}
          label="Phone"
          rules={[{ required: true, message: "Phone field is required." }]}>
          <PhoneInput
            enableSearch
            country={"us"}
            searchPlaceholder={t("user.searchNumberPlaceholder")}
            onChange={handleChangePhoneInput}
          />
        </Form.Item>
        <Form.Item
            name="passcode"
            label={t("verify.ModalInsertPasscord")}
            hidden={true}
          >
            <Input.Password
              className="input-base"
              placeholder={t("user.yourPasscode")}
            />
          </Form.Item>
      </Form>
    </Modal>
    <InsertPasscodeForm formSubmit={updateUserPhoneForm} visible={isConfirmPasswordModalOpen} onClose={handleCancel} isLoading={isLoadingModal}/>
    </>
  );
}

export default UpdateUserPhonePopup;
