import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
import "./index.scss";

function AuthLayout() {
  const { t } = useTranslation();

  return (
    <div
      className="auth-layout"
    >
      <div className="auth-layout__formContent">
        <div className="auth-layout__form">
          <Outlet />
        </div>
      </div>

      <small className="auth-layout__copyright">{t("common.copyRight")}</small>
    </div>
  );
}

export default AuthLayout;
