import { KeyOutlined } from "@ant-design/icons";
import { Row } from "antd";
import { useState } from "react";
import "./guide-passcode.scss";

function GuidePasscode() {
  const [guidelines] = useState<string[]>([
    "Password minimum length is 12",
    "Password must contain at least one lowercase",
    "Password must contain at least one uppercase",
    "Password must contain at least one number",
    "Password must contain at least one special character",
    "Password must be significantly different from your last 4 passwords",
    "Do not spell a word with a number added to the beginning and the end",
    "Do not be based on any personal information such as user id, family name, pet, birthday, etc",
    "Do not spell a word or series of words that can be found in a standard dictionary",
  ]);

  return (
    <Row className="password-hint-wrapper">
      {guidelines.map((item) => (
        <span className={`password-hint-item`}>
          <KeyOutlined /> {item}
        </span>
      ))}
    </Row>
  );
}
export default GuidePasscode;
