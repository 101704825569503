import "antd/dist/reset.css";
import React, { useEffect } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { SystemApi } from "./apis/system.api";
import "./App.css";
import AuthLayout from "./components/layouts/auth-layout";
import DashboardLayout from "./components/layouts/dashboard-layout";
import { LOCAL_STORAGE_KEY, LOCAL_UI_STORAGE_VALUE, SESSION_TIMEOUT_CONFIG } from "./constants/app-constants";
import { ROUTE_PATHS } from "./constants/router.constants";
import { UISettingModel } from "./models/system-config";
import NotFoundPage from "./pages/error/404";
import MaintenancePage from "./pages/error/maintenance";
import { anonymousRouters, authorizedRouters } from "./routing";
import { authState } from "./states/auth";
import { uiSettingState } from "./states/uiSetting";
import LocalUtils from "./utils/local.utils";

function App() {
  const auth = useRecoilValue(authState);
  const setUiSetting = useSetRecoilState(uiSettingState);

  useEffect(() => {
    fetchSystemConfigData();
  }, []);

  const fetchSystemConfigData = async () => {
    try {
      const res = await SystemApi.getUISettingConfig();
      
      const config: UISettingModel = {
        uiBrowserTitle: res.data?.uiBrowserTitle ?? LOCAL_UI_STORAGE_VALUE.BROWSER_TITLE,
        uiLogoImage: res.data?.uiLogoImage ? res.data?.uiLogoImage : LOCAL_UI_STORAGE_VALUE.LOGO_GATEWAY_PATH,
        uiWelcomeImage: res.data?.uiWelcomeImage ? res.data?.uiLogoImage : LOCAL_UI_STORAGE_VALUE.WELCOME_IMAGE_PATH,
        inactivityLogout: res.data?.inactivityLogout ? res.data?.inactivityLogout * 60000 : SESSION_TIMEOUT_CONFIG.sessionWarningTime
      };
      
      setUiSetting(config); 
      LocalUtils.set(LOCAL_STORAGE_KEY.SYSTEM_CONFIG_DATA, JSON.stringify(config));
      document.title = config.uiBrowserTitle ?? "";
      
    } catch (error) {
      console.error('Error fetching UI setting:', error);
    }
  };

  return process.env.REACT_APP_MAINTENANCE_MODE === "true" ? (
    <MaintenancePage />
  ) : (
    <RouterProvider
      router={createBrowserRouter(
        createRoutesFromElements(
          <Route>
            <Route
              element={
                auth.isLogined ? (
                  <Navigate to={ROUTE_PATHS.Home} />
                ) : (
                  <AuthLayout />
                )
              }>
              {anonymousRouters
                .filter((p) => p.component)
                .map(({ href, component: Component }) => (
                  <Route
                    key={href}
                    path={href}
                    element={
                      <React.Suspense>
                        <Component />
                      </React.Suspense>
                    }
                  />
                ))}
            </Route>
            <Route
              path={ROUTE_PATHS.Home}
              element={
                !auth.isLogined ? (
                  <Navigate to={ROUTE_PATHS.SignIn} />
                ) : (
                  <DashboardLayout />
                )
              }>
              {authorizedRouters
                .filter((p) => p.component)
                .map(({ href, component: Component }) => (
                  <Route
                    key={href}
                    path={href}
                    element={
                      <React.Suspense>
                        <Component />
                      </React.Suspense>
                    }
                  />
                ))}
            </Route>
            <Route
              path={ROUTE_PATHS.NotFound}
              element={<NotFoundPage />}></Route>
          </Route>
        )
      )}
    />
  );
}

export default App;