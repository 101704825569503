import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Layout,
  Row,
  Select,
  SelectProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { KeysApi } from "../../../../../apis/keys.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import {
  KeyAlgorithmEnums,
  KeyDisplayName,
  KeyModeEnums,
} from "../../../../../models/key-enums";
import { HSMKeys } from "../../../../../models/keys";
import BreadcrumbComponent from "../../../../common/breadcrumb";
import showNotification from "../../../../common/notification";
import "../index.scss";

function EditHsmKeyForm() {
  const breadcrumbItems = ["Update HSM Keys", "Key Management", "HSM Keys"];
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { t } = useTranslation();
  const { id } = useParams();
  const [hsmForm] = Form.useForm();
  const checkUrl = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data } = await KeysApi.getHSMById(id);
          hsmForm.setFieldsValue({
            name: data.name,
            keyMode: data.keyMode,
            keyIndex: data.keyIndex,
            kcv: data.kcv,
            keyAlgorithm: data.keyAlgorithm,
            remark: data.remark,
          });
        } catch (err) {}
      };
      fetchData();
    }
  }, []);

  const onSubmit = () => {
    hsmForm.submit();
  };

  const optionsKeyMode: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyModeDisplayName[0],
      value: KeyModeEnums.None,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[1],
      value: KeyModeEnums.DUKPTbyHSMKey,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[2],
      value: KeyModeEnums.NoneUnique,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[4],
      value: KeyModeEnums.KEK,
    },
  ];

  const optionsKeyAlgorithm: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[0],
      value: KeyAlgorithmEnums.None,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[1],
      value: KeyAlgorithmEnums.DES,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[2],
      value: KeyAlgorithmEnums.TDES,
    },
  ];

  async function handleUpdateHSMKey(values: any) {
    setLoadingBtn(true);
    try {
      const req: HSMKeys = {
        id: id ?? DefaultGUID,
        name: values.name,
        keyIndex: values.keyIndex,
        keyMode: values.keyMode,
        kcv: values.kcv,
        keyAlgorithm: values.keyAlgorithm,
        remark: values.remark,
      };
      const result =
        checkUrl.pathname === ROUTE_PATHS.HSMKeysCreate
          ? await KeysApi.createHSM(req)
          : await KeysApi.updateHSM(req);

      showNotification("success", "Updated HSM Key successfully.");
      setLoadingBtn(false);
      if (result) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.HSMKeys);
        }, 1000);
      }
    } catch (err) {}
  }

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Content className="ctn-user-index">
        <Form
          className="edit-container"
          layout="vertical"
          labelWrap
          form={hsmForm}
          onFinish={handleUpdateHSMKey}>
          <Row gutter={16}>
            <Col
              sm={18}
              xs={14}
              lg={12}>
              <div className="head-form">
                <div className="h4-semiBold">{t("key.hsm.title")}</div>
                <div className="body-regular ">{t("key.hsm.title")}</div>
              </div>
            </Col>
            <Col
              sm={6}
              xs={10}
              lg={12}>
              <Form.Item>
                <div className="btn-grp">
                  <Link to={ROUTE_PATHS.HSMKeys}>
                    <Button className="btn-back btn-base btn-secondary-base">
                      {t("sysConfig.button.back")}
                    </Button>
                  </Link>
                  <Button
                    className="btn-sumbit btn-base btn-main-base"
                    loading={loadingBtn}
                    onClick={onSubmit}>
                    {t("sysConfig.button.save")}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row gutter={24}>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="name"
                label={t("key.col_title.name")}
                hasFeedback
                rules={[{ required: true, message: "Key Name is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.name")}
                />
              </Form.Item>
              <Form.Item
                name="keyMode"
                label={t("key.col_title.key_mode")}
                rules={[{ required: true, message: "Key Mode is required" }]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.key_mode")}
                  options={optionsKeyMode}
                />
              </Form.Item>
              <Form.Item
                name="keyAlgorithm"
                label={t("key.col_title.keyAlgorithm")}
                rules={[
                  { required: true, message: "Key Algorithm is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.keyAlgorithm")}
                  options={optionsKeyAlgorithm}
                />
              </Form.Item>
            </Col>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="keyIndex"
                label={t("key.col_title.key_index")}
                hasFeedback
                rules={[
                  { required: true, message: "Key Index is required" },
                  {
                    pattern: /^[0-9]+$/,
                    message: "Please enter numbers only.",
                  },
                ]}>
                <InputNumber
                  className="input-base input-keyindex"
                  placeholder={t("key.col_title.key_index")}
                />
              </Form.Item>
              <Form.Item
                name="kcv"
                label={t("key.col_title.kcv")}
                hasFeedback
                rules={[{ required: true, message: "KCV is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.kcv")}
                />
              </Form.Item>
              <Form.Item
                name="remark"
                label={t("key.col_title.remark")}>
                <TextArea placeholder={t("key.col_title.remark")} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
}

export default EditHsmKeyForm;
