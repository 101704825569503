import { AxiosResponse } from "axios";
import { PaginationResponseModel } from "../models/pagination-response-model";
import { APIPermission, RoleModelDisplay } from "../models/role";
import { UserModelDisplay } from "../models/user";
import { deleteAsync, getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Auth}/v1`;

function getAllRoles(): Promise<AxiosResponse<RoleModelDisplay[]>> {
  const url = `${baseUrl}/role/all`;
  return getAsync(url);
}

function getAllRolesFilter(
  pageIndex: number,
  pageSize: number,
  searchString: string
): Promise<AxiosResponse<PaginationResponseModel<RoleModelDisplay>>> {
  const url = `${baseUrl}/role?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${searchString}`;
  return getAsync(url);
}

function getAPIPermissions(): Promise<AxiosResponse<APIPermission[]>> {
  const url = `${baseUrl}/role/list-apis`;
  return getAsync(url);
}

function getRoleById(id: string): Promise<AxiosResponse<RoleModelDisplay>> {
  const url = `${baseUrl}/role/${id}`;
  return getAsync(url, undefined, undefined, false);
}

function postRole(
  model: RoleModelDisplay
): Promise<AxiosResponse<RoleModelDisplay>> {
  const url = `${baseUrl}/role`;
  return postAsync(url, model);
}

function updateRole(
  model: RoleModelDisplay
): Promise<AxiosResponse<RoleModelDisplay>> {
  const url = `${baseUrl}/role`;
  return putAsync(url, model);
}

function activeRole(id: string, isDeactive?: boolean) {
  const url = `${baseUrl}/role/active/${id}/${isDeactive}`;
  return postAsync(url, undefined, true, true);
}

function deleteRole(id: string) {
  const url = `${baseUrl}/role/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function getUserByRole(id: string): Promise<AxiosResponse<UserModelDisplay[]>> {
  const url = `${baseUrl}/role/by-role/${id}`;
  return getAsync(url, undefined, true, false);
}

export const RoleApi = {
  getAllRoles,
  getRoleById,
  postRole,
  activeRole,
  deleteRole,
  getAPIPermissions,
  updateRole,
  getUserByRole,
  getAllRolesFilter,
};
