import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Spin,
  Upload,
  UploadProps,
} from "antd";

import Dragger from "antd/es/upload/Dragger";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { HostConfigApi } from "../../../../apis/host-config.api";
import {
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { HostConfigField, HostConfigRenderDisplay } from '../../../../models/host-config';
import BreadcrumbComponent from "../../../common/breadcrumb";
import showNotification from "../../../common/notification";
import "./index.scss";

function HostConfigForm() {
  const { t } = useTranslation();
  const breadcrumbItems = [
    "Host Configs",
    "Home",
    "Host Configs",
    "Host Config",
  ];
  const { id } = useParams();
  const [hostConfigForm] = Form.useForm();
  const [hostConfigFields, setHostConfigFields] = useState<
    HostConfigField[] | []
  >([]);
  const [hostConfigDisplay, setHostConfigDisplay] = useState<
    HostConfigRenderDisplay[] | []
  >([]);
  const checkUrl = useLocation();
  const [fileLists, setFileLists] = useState<any>({});
  const [isShowLoading, setShowLoading] = useState<boolean>(false);
  const [isShowLoadingUpload, setShowLoadingUpload] = useState<boolean>(false);
  const dateFormat = "YYYY-MM-DD HH:mm";
  const [uploadDLL, setUploadDLL] = useState<any>({});
  const navigate = useNavigate();

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data: responseData } = await HostConfigApi.getHostConfigById(
            id
          );
          hostConfigForm.setFieldsValue({
            nameHost: responseData?.name,
          });
          const initialFileLists = buildFileList(
            responseData.hostConfigFields || []
          );
          setFileLists(initialFileLists);
          setHostConfigFields(responseData.hostConfigFields || []);
          setHostConfigDisplay(await hostConfigRender(responseData.hostConfigFields))
        } catch (error) {}
      };
      fetchData();
    } else {
      getHostConfigFields();
    }
  }, []);

  async function getHostConfigFields() {
    setShowLoadingUpload(true);
    try {
      const { data: responseData } = await HostConfigApi.uploadDLL(uploadDLL[0]?.originFileObj);

      if (responseData) {
        const initialFileLists = buildFileList(
          responseData.hostConfigFields || []
        );
        setFileLists(initialFileLists);
        setHostConfigFields(responseData.hostConfigFields || []);
        setHostConfigDisplay(await hostConfigRender(responseData.hostConfigFields))
      }
    } catch (error) {
    } finally {
      setShowLoadingUpload(false);
    }
  }

  async function handleAPIfunction(values: any) {
    setShowLoading(true);

    try {
      let isCreate = checkUrl.pathname === ROUTE_PATHS.HostConfigCreate;

      let hostConfigFieldData = updatedFormData(values);

      var idUpdate = isCreate ? DefaultGUID : id;

      const vm = new FormData();
      vm.append("Name", values.nameHost);
      vm.append("Id", idUpdate || "");
      hostConfigFieldData.forEach((field, index) => {
        vm.append(
          `HostConfigFields[${index}].DisplayName`,
          field.displayName || ""
        );
        vm.append(
          `HostConfigFields[${index}].FieldName`,
          field.fieldName || ""
        );
        vm.append(
          `HostConfigFields[${index}].FieldValue`,
          field.fieldValue || ""
        );
        vm.append(
          `HostConfigFields[${index}].FieldType`,
          field.fieldType || ""
        );
        vm.append(
          `HostConfigFields[${index}].GroupName`,
          field.groupName || ""
        );
        vm.append(
          `HostConfigFields[${index}].SortIndex`,
          field.sortIndex?.toString() || "" 
        );

        if (field.fieldFileValue) {
          vm.append(
            `HostConfigFields[${index}].FieldFileValue`,
            field.fieldFileValue
          );
        }
      });

      let result;

      if (isCreate) {
        result = await HostConfigApi.postHostConfig(vm);
      } else {
        result = await HostConfigApi.updateHostConfig(vm);
      }

      if (result?.data) {
        const message = isCreate
          ? t("hostConfig.notiCreateHostConfig")
          : t("hostConfig.notiUpdateHostConfig");
        showNotification("success", message);
        
        if (message) {
          setTimeout(() => {
            navigate(ROUTE_PATHS.HostConfig);
          }, 1000);
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  }

  async function hostConfigRender(hostConfigFields: HostConfigField[] | undefined): Promise<HostConfigRenderDisplay[]> {
    if(hostConfigFields === undefined) return [];

    const grouped = hostConfigFields.reduce((acc, field) => {
      const groupName = field.groupName ?? "";

      if (!acc.has(groupName)) {
        acc.set(groupName, []);
      }

      acc.get(groupName)!.push(field);
      return acc;
    }, new Map<string, HostConfigField[]>());

    const models: HostConfigRenderDisplay[] = Array.from(grouped, ([groupName, children]) => ({
      groupName,
      children,
    }));

    return models;
  }
  
  const props: UploadProps = {
    name: "file",
    multiple: false,
    maxCount: 1,
    async onChange(info) {
      const { file } = info;
      hostConfigForm.setFieldsValue({
        fileName: file.name,
        updatedTime: file.lastModifiedDate,
        size: bytesToMB(file.size).toFixed(2) + "MB",
      });

      setUploadDLL(info.fileList);
    },
  };

  function bytesToMB(bytes: any): number {
    return bytes / (1024 * 1024);
  }

  const buildFileList = (hostConfigFields: HostConfigField[]) => {
    const fileListObj: { [fieldName: string]: any[] } = {};
    hostConfigFields.forEach((field) => {
      if (field.fieldType === "IFormFile") {
        if(field.fieldValue != null){
          fileListObj[field.fieldName] = [
            {
              uid: field.fieldName,
              name: field.fieldValue,
              status: "done",
            },
          ];
        }
      }
    });
    return fileListObj;
  };

  function updatedFormData(values: any): HostConfigField[] {
    const updatedFormData: HostConfigField[] = hostConfigFields.map((field) => {
      const valueFromForm = values[field.fieldName];

      if (valueFromForm !== undefined) {
        if (field.fieldType === "IFormFile") {
          return {
            ...field,
            fieldFileValue: valueFromForm[0].originFileObj,
          };
        } else {
          return {
            ...field,
            fieldValue: valueFromForm,
          };
        }
      }
      return field;
    });

    return updatedFormData;
  }

  const normFile = (e: { fileList: any }, fieldName: string) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const beforeUpload = (file: any, fieldName: string) => {
    return false;
  };

  const handleChange = (info: any, fieldName: string) => {
    setFileLists((prevFileLists: any) => ({
      ...prevFileLists,
      [fieldName]: info.fileList,
    }));
  };

  const getFieldUploadProps = (fieldName: string) => ({
    beforeUpload: (file: any) => beforeUpload(file, fieldName),
    onChange: (info: any) => handleChange(info, fieldName),
    maxCount: 1,
    multiple: false,
  });

  const onSubmit = () => {
    hostConfigForm.submit();
  };

  function HostConfig() {
    return (
      <Row className="container-items">
        <Row className="row-full">
          <Col lg={8} xs={24} sm={24}>
            <Form.Item name="nameHost" label={t("Name")}>
              <Input className="input-base" />
            </Form.Item>
          </Col>
        </Row>
        <Divider
          className="h5-semiBold"
          orientation="left"
          orientationMargin="0"
        >
          Plugin
        </Divider>
        <div className="upload-row ">
          <div className="container-title">
            <p className="title body-regular">Upload Plugin</p>
            <p className="title-description">Plugin for host</p>
          </div>
          <Row className="row-full">
            <Col lg={8} xs={24} sm={24}>
              <Dragger customRequest={() => {}} {...props} 
                listType="text">
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload. Strictly prohibited from
                </p>
              </Dragger>
            </Col>

            <Col
              lg={16}
              xs={24}
              sm={24}>
              <Row>
                <Col
                  lg={12}
                  xs={24}
                  sm={24}>
                  <Form.Item
                    name="fileName"
                    label={t('File Name')}>
                    <Input
                      disabled={true}
                      className="input-base"
                      placeholder={'File name'}
                    />
                  </Form.Item>
                </Col>

                <Col
                  lg={12}
                  xs={24}
                  sm={24}>
                  <Form.Item
                    name="updatedTime"
                    label={'Updated Time'}
                    getValueProps={(i) => ({ value: i ? dayjs(i) : null })}>
                    <DatePicker
                      disabled={true}
                      showToday={false}
                      format={dateFormat}
                      placeholder="Updated time"
                      className="datepicker-input input-base"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    name="size"
                    label={t('Size')}>
                    <Input
                      disabled={true}
                      className="input-base"
                      placeholder={'Size'}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label=" ">
                    <Button className="btn-sumbit btn-base btn-main-base load-plugin" onClick={getHostConfigFields} loading={isShowLoadingUpload}>
                      Load Plugin
                    </Button>
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>

        <Divider
          className="h5-semiBold"
          orientation="left"
          orientationMargin="0"
        >
          Configuration data
        </Divider>

        <div className="upload-row ">
          <div className="container-title">
            <p className="title body-regular">Configuration data</p>
            <p className="title-description">
              Configuration for data within the plugin
            </p>
          </div>
          <Spin
            tip="Loading"
            spinning={isShowLoadingUpload}>
            <Row className="row-full">
              {hostConfigDisplay.map((field) => (
                <>
                  <Divider orientation="left" orientationMargin="0" plain>
                    {field.groupName}
                  </Divider>
                  {field.children
                    .sort((a, b) => (a.sortIndex ?? 0) - (b.sortIndex ?? 0))
                    .map((children) => {
                      switch (children.fieldType) {
                        case 'String':
                          return (
                            <Col lg={8} xs={24} sm={24} key={children.fieldName}>
                              <Form.Item
                                label={children.displayName}
                                name={children.fieldName}
                                initialValue={children.fieldValue}
                                rules={[{ required: true }]}
                              >
                                <Input className="input-base" />
                              </Form.Item>
                            </Col>
                          );
                        case 'Password':
                          return (
                            <Col lg={8} xs={24} sm={24} key={children.fieldName}>
                              <Form.Item
                                label={children.displayName}
                                name={children.fieldName}
                                initialValue={children.fieldValue}
                                rules={[{ required: true }]}
                              >
                                <Input.Password className="input-base" />
                              </Form.Item>
                            </Col>
                          );
                        case 'IFormFile':
                          return (
                            <Col lg={8} xs={24} sm={24} key={children.fieldName}>
                              <Form.Item
                                label={children.displayName}
                                name={children.fieldName}
                                valuePropName={'fileList'}
                                getValueFromEvent={(e) => normFile(e, children.fieldName)}
                                initialValue={fileLists[children.fieldName] || []}
                                rules={[
                                  {
                                    required: true,
                                    message: `Please upload at least one file for ${children.displayName}`,
                                  },
                                ]}
                              >
                                <Upload {...getFieldUploadProps(children.fieldName)} listType="text">
                                  <Button icon={<UploadOutlined />}>Click to Upload</Button>
                                </Upload>
                              </Form.Item>
                            </Col>
                          );
                        default:
                          return null;
                      }
                    })}
                </>
              ))}
            </Row>
          </Spin>
        </div>
      </Row>
    );
  }

  function HostConfigForm() {
    return (
      <Form
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        onFinish={handleAPIfunction}
        name="hostConfigForm"
        className="container"
        layout="vertical"
        form={hostConfigForm}
      >
        <Row className="container-top-items">
          <Col span={12}>
            <div className="h4-semiBold"> Host Config</div>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.HostConfig}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  onClick={onSubmit}
                  loading={isShowLoading}
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <HostConfig />
      </Form>
    );
  }

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      {<HostConfigForm />}
    </Layout>
  );
}

export default HostConfigForm;
