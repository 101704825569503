import { atom } from "recoil";
import { LOCAL_COOKIE_KEY } from "@/constants/app-constants";
import { APP_STATE_KEYS } from "@/constants/app-state.constants";
import { UserModelInf } from "@/models/user";
import LocalUtils from "@/utils/local.utils";

interface AuthState {
  isLogined: boolean;
  user: UserModelInf | undefined;
  error?: string;
}

const authState = atom<AuthState>({
  key: APP_STATE_KEYS.AUTH_STATE,
  default: {
    isLogined: LocalUtils.getCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN) === "true",
    user: LocalUtils.getUserInfo(),
    error: "",
  },
});

export { authState };

