import { Modal } from "antd";
import "./index.scss";

type ModalConfirmType = "info" | "warning" | "delete";

interface ConfirmModalProps {
  type: ModalConfirmType;
  title: string;
  content: string;
  onOk?: () => void;
  onCancel?: () => void;
}

const showConfirmModal = ({ title, content, onOk, onCancel }: ConfirmModalProps) => {
  Modal.confirm({
    title,
    content,
    okText: 'Confirm',
    cancelText: 'Cancel',
    onOk() {
      if (onOk) {
        onOk();
      }
    },
    onCancel() {
      if (onCancel) {
        onCancel();
      }
    },
  });
};

export default showConfirmModal;
