import { AxiosResponse } from "axios";
import {
  Application,
  LoginConfigModel,
  UISettingModel,
} from "../models/system-config";
import { getAsync, putAsync, putFormDataAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Portal}/v1`;

function getApplicationConfig(): Promise<AxiosResponse<Application>> {
  const url = `${baseUrl}/system/application-config`;
  return getAsync(url);
}

function updateApplicationConfig(
  model: Application
): Promise<AxiosResponse<Application>> {
  const url = `${baseUrl}/system/application-config`;
  return putFormDataAsync(url, model, true, true);
}

function getGlobalConfig(): Promise<AxiosResponse<LoginConfigModel>> {
  const url = `${baseUrl}/system/login-config`;
  return getAsync(url);
}

function updateGlobalConfig(
  model: LoginConfigModel
): Promise<AxiosResponse<LoginConfigModel>> {
  const url = `${baseUrl}/system/login-config`;
  return putAsync(url, model, true, true);
}

function getUISettingConfig(): Promise<AxiosResponse<UISettingModel>> {
  const url = `${baseUrl}/system/ui-setting`;
  return getAsync(url);
}

function updateUISettingConfig(
  model: UISettingModel
): Promise<AxiosResponse<UISettingModel>> {
  const url = `${baseUrl}/system/ui-setting`;
  return putFormDataAsync(url, model, true, true);
}

export const SystemApi = {
  getApplicationConfig,
  updateApplicationConfig,
  getUISettingConfig,
  updateUISettingConfig,
  getGlobalConfig,
  updateGlobalConfig,
};
