import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import KeyManagementForm from "../../components/views/key-management/hsm-keys";

function KeyHSMManagementPage() {
  const breadcrumbItems = ["HSM Keys", "Key Management", "HSM Keys"];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <KeyManagementForm />
    </Layout>
  );
}
export default KeyHSMManagementPage;
