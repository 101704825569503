import { ColumnsType } from "antd/es/table/interface";
import { GroupedAPIPermission } from "../../../../../models/role";
import "./role-permission.scss";

export interface RoleSelect {
  id: React.ReactNode;
  roleName: React.ReactNode;
  rolePermission: number[];
}

export interface RolePermission {
  key: React.ReactNode;
  name: React.ReactNode;
  roleDescription: string;
  children?: RolePermission[];
}

export const columns: ColumnsType<GroupedAPIPermission> = [
  {
    title: "Permission Name",
    width: "30%",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Permission Description",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
];

export const options: RoleSelect[] = [
  {
    id: 1,
    roleName: "Manager",
    rolePermission: [1],
  },
  {
    id: 2,
    roleName: "Developer",
    rolePermission: [2],
  },
  {
    id: 3,
    roleName: "Dev 1",
    rolePermission: [3],
  },
  {
    id: 4,
    roleName: "Auditor",
    rolePermission: [4],
  },
  {
    id: 5,
    roleName: "Director",
    rolePermission: [5],
  },
];
