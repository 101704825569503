import { AxiosResponse } from "axios";
import {
  ChangePasscodeModel,
  MFARequest,
  RefreshTokenModel,
  SendOTPRequestModel,
  SignInRequestModel,
  SignInResponseModel,
} from "../models/sign-in.model";
import { getAsync, postAsync, putAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Auth}/v1`;

function signIn(
  model: SignInRequestModel
): Promise<AxiosResponse<SignInResponseModel>> {
  const url = `${baseUrl}/auth/login/1`;
  return postAsync(url, model, false, false, true);
}

function multiFactor(
  type: string,
  model: MFARequest
): Promise<AxiosResponse<SignInResponseModel>> {
  const url = `${baseUrl}/auth/multi-factor/${type}`;
  return postAsync(url, model, false, false, true);
}

function sendOTP(
  type: string,
  model: SendOTPRequestModel
): Promise<AxiosResponse<SignInResponseModel>> {
  const url = `${baseUrl}/auth/sending-otp/${type}`;
  return postAsync(url, model, false, false, true);
}

function refreshToken(request: RefreshTokenModel) {
  const url = `${baseUrl}/auth/refresh-token`;
  return postAsync(url, request, false, false, true);
}

function getClientInfo(id: number) {
  const url = `${baseUrl}/clients/${id}/info`;
  return getAsync(url, undefined, undefined, false);
}

function changingLoggedUserPasscode(request: ChangePasscodeModel, showPopupError: boolean = true) {
  const url = `${baseUrl}/auth/change-passcode`;
  return putAsync(url, request, true, showPopupError, false);
}

export const AuthApi = {
  signIn,
  multiFactor,
  sendOTP,
  refreshToken,
  getClientInfo,
  changingLoggedUserPasscode,
};
