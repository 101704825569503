import { Col, Form, FormInstance, Row, Switch } from "antd";
import { AllowedDataField } from "../../../../../models/role";
import "./dataFields.scss";

function AllowedDataFieldTab({
  data,
  dataFieldForm,
}: {
  data: AllowedDataField[];
  dataFieldForm: FormInstance;
}) {
  return (
    <Form form={dataFieldForm}>
      <Row gutter={16}>
        {data.map((field) => (
          <Col
            lg={12}
            xs={24}
            sm={24}>
            <div className="row-switch-item">
              <div className="label-switch">{field.name}</div>
              <Form.Item
                key={field.value}
                name={`field-${field.value}`}>
                <Switch
                  className="switch-class"
                  defaultChecked={field.isChecked}
                />
              </Form.Item>
            </div>
          </Col>
        ))}
      </Row>
    </Form>
  );
}

export default AllowedDataFieldTab;
