import { Divider, Layout, Row, Tabs, TabsProps } from "antd";

import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../../common/breadcrumb";
import ContactDetails from "./component/contact-details";
import TabInfomation from "./component/tab-information";
import "./index.scss";
import { useEffect, useState } from "react";
import { SCREEN_WIDTH_SMALL } from "../../../../constants/app-constants";
import { useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/router.constants";

function CreateAndEditUserForm() {
  const { t } = useTranslation();
  const breadcrumbItems = ["User", "Home", "Users", "User"];
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const [selectedMenuItem, setSelectedMenuItem] = useState<string | null>(null);
  const checkUrl = useLocation();

  const handleItemClick = (menuId: string) => {
    setSelectedMenuItem(menuId);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <Row className="h5-semiBold">{t("title.userInf_title")} </Row>
          <Row className="body-regular">{t("title.userInf_desc")} </Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <TabInfomation />,
    },
    {
      key: "2",
      label: (
        <>
          <Row className="h5-semiBold">{t("title.userContact_title")}</Row>
          <Row className="body-regular">{t("title.userContact_desc")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),

      disabled: checkUrl.pathname === ROUTE_PATHS.UserCreate,
      children: <ContactDetails />,
    },
  ];

  function ConfigFormXSmallScreen() {
    return (
      <>
        <div className="menu-small-screeen">
          <div
            className={`menu-item ${
              selectedMenuItem === "item1" ? "active" : ""
            }`}
            onClick={() => handleItemClick("item1")}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userInf_title")} </div>
              <div className="body-regular ">{t("title.userInf_desc")} </div>
            </div>
          </div>
          <div
            className={`menu-item ${
              selectedMenuItem === "item2" ? "active" : ""
            }`}
            onClick={() => handleItemClick("item2")}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userContact_title")}</div>
              <div className="body-regular ">{t("title.userContact_desc")}</div>
            </div>
          </div>
          <Divider />
          {selectedMenuItem && (
            <div className="body">
              {selectedMenuItem === "item1" && <TabInfomation />}
              {selectedMenuItem === "item2" && <ContactDetails />}
            </div>
          )}
        </div>
      </>
    );
  }

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      {windowWidth > SCREEN_WIDTH_SMALL && (
        <div className="container-user-form">
          <Tabs
            tabPosition="left"
            defaultActiveKey="1"
            items={items}
            destroyInactiveTabPane={true}
          />
        </div>
      )}
      {windowWidth <= SCREEN_WIDTH_SMALL && (
        <div className="container-user-form-1">
          {<ConfigFormXSmallScreen />}
        </div>
      )}
    </Layout>
  );
}

export default CreateAndEditUserForm;
