import { AxiosResponse } from "axios";
import { LogType } from "../constants/app.enums";
import { AuditLog } from "../models/audit-log";
import { PaginationResponseModel } from "../models/pagination-response-model";
import { CommonUtils } from "../utils/common";
import { downloadAsync, getAsync } from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Portal}/v1`;

function getAuditLogs(
  pageIndex: number,
  pageSize: number,
  type: LogType,
  filter: object
): Promise<AxiosResponse<PaginationResponseModel<AuditLog>>> {
  let url = `${baseUrl}/auditlog?pageIndex=${pageIndex}&pageSize=${pageSize}&type=${type}`;
  url = CommonUtils.buildQuery(url, filter)
  return getAsync(url);
}

function getSystemLogReport(type: LogType): Promise<AxiosResponse<Blob>> {
  const url = `${baseUrl}/auditlog/export-system-report?type=${type}`;
  return downloadAsync(url);
}

function getOperationLogReport(type: LogType): Promise<AxiosResponse<Blob>> {
  const url = `${baseUrl}/auditlog/export-operation-report?type=${type}`;
  return downloadAsync(url);
}

export const AuditLogApi = {
  getAuditLogs,
  getSystemLogReport,
  getOperationLogReport,
};
