import { Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function SessionsChart() {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      series: [
        {
          data: [0],
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
        width: "100%",
        sparkline: {
          enabled: true,
        },
      },
      colors: ["#FFFFFF73"],
      plotOptions: {
        bar: {
          columnWidth: "40%",
        },
      }
    },
  });

  useEffect(() => {
    setTimeout(() => {
      const newData = [12, 14, 26, 21, 20, 15, 21, 20, 15, 10, 12, 14]

      setChartData(prevChartData => ({
        ...prevChartData,
        options: {
          ...prevChartData.options,
          series: [{ data: newData }],
        },
      }));

      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="dashboard-card card-sessions">
          <div className="dashboard-card-content">
            <span className=" h2-semiBold">44K</span>
            <span className=" h5-regular ">(-23.6%)</span>
            <br />
            <span className=" h5-regular ">Sessions</span>
          </div>

          <div className="sessions-chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.options.series}
              type="bar"
              height={117}
            />
          </div>
        </div>
      </Spin>
    </>
  );
}
export default SessionsChart;
