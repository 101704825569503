import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { ChangePasswordType } from "../../../../models/enums";

export interface PasscodeWarningProps {
  visible: boolean;
  onClose: () => void;
  passcodeWarningDays: number,
}

export function PasscodeWarning({
  visible,
  onClose,
  passcodeWarningDays,
}: PasscodeWarningProps) {
  const navigate = useNavigate();
  
  const handleOk = () => {
    navigate(ROUTE_PATHS.ChangePasscode, {
      replace: true,
      state: {type: ChangePasswordType.PasswordWarning },
    });
  };

  const handleCancel = () => {
    onClose();
    window.location.href = ROUTE_PATHS.Home;
  };

  return (
    <>
    <Modal
      open={visible}
      closable={false}
      title="Password Expiring Soon"
      onOk={handleOk}
      okText="Update password now"
      cancelText="Update later"
      onCancel={handleCancel}
      >
        Your password will expire in about {Math.round(passcodeWarningDays)} days. Please renew your password.
    </Modal>
    </>
  );
}

export default PasscodeWarning;
