import jwtDecode from "jwt-decode";
import moment from "moment";
import { Cookies } from "react-cookie";
import { CookieSetOptions } from "universal-cookie";
import {
  LOCAL_COOKIE_KEY,
  LOCAL_STORAGE_KEY,
} from "@/constants/app-constants";
import { SignInResponseModel } from "@/models/sign-in.model";
import { UserModelInf } from "@/models/user";

export const LocalUtils = {
  get(key: string) {
    return localStorage.getItem(key);
  },

  set(key: string, value: string) {
    localStorage.setItem(key, value);
  },

  remove(key: string) {
    return localStorage.removeItem(key);
  },

  setCookie(key: string, value: string, opt: CookieSetOptions) {
    const cookies = new Cookies();
    cookies.set(key, value, opt);
  },

  getCookie(key: string) {
    const cookies = new Cookies();
    return cookies.get(key);
  },

  removeCookie(key: string, opt: CookieSetOptions | undefined) {
    const cookies = new Cookies();
    cookies.remove(key, opt);
  },

  // implement local session in here

  clear() {
    const cookies = new Cookies();
    Object.keys(cookies.getAll()).forEach((item) => {
      cookies.remove(item);
    });

    localStorage.clear();
    sessionStorage.clear();
  },

  getTokenExpire(token: string) {
    try {
      const decodedToken: any = jwtDecode(token);

      if (decodedToken && typeof decodedToken.exp !== "undefined") {
        const tokenExpire = new Date(decodedToken.exp * 1000);
        return tokenExpire;
      }

      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  },

  setAuthenticatedData(data: SignInResponseModel, isRemember: boolean = false, isLogined: string = "true") {
    const tokenExpire = moment(LocalUtils.getTokenExpire(data.token))
      .subtract(60, "seconds")
      .toDate();

    LocalUtils.setCookie(LOCAL_COOKIE_KEY.ID_TOKEN, data.userId, {
      expires: tokenExpire,
      path: "/",
    });

    LocalUtils.setCookie(LOCAL_COOKIE_KEY.ACCESS_TOKEN, data.token, {
      expires: tokenExpire,
      path: "/",
    });

    LocalUtils.set(LOCAL_STORAGE_KEY.REFRESH_TOKEN, data.refreshToken);

    const loginExpire = moment()
      .add(isRemember ? 7 : 1, "days")
      .toDate();

    LocalUtils.setCookie(LOCAL_COOKIE_KEY.IS_LOGGED_IN, isLogined, {
      expires: loginExpire,
      path: "/",
    });

    LocalUtils.set(
      LOCAL_STORAGE_KEY.IS_REMEMBER_ME,
      isRemember ? "true" : "false"
    );
  },

  setUserInfo(data: UserModelInf) {
    LocalUtils.set(LOCAL_STORAGE_KEY.USER_INFO, JSON.stringify(data));
  },

  getUserInfo(): UserModelInf {
    const data = LocalUtils.get(LOCAL_STORAGE_KEY.USER_INFO);

    return data ? JSON.parse(data) : {};
  },
};

export default LocalUtils;
