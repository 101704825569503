import { Menu, MenuProps } from "antd";
import Sider from "antd/es/layout/Sider";
import { useTranslation } from "react-i18next";
import { Link, useLocation } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";
import "../../../../../assets/fontello/css/fontello.css";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import { collapsedState } from "../../../../../states/sidebar";
import "./index.scss";

function SiderMenu() {
  const location = useLocation();
  const { t } = useTranslation();
  const isCollapsed = useRecoilValue(collapsedState);
  const setCollapsedState = useSetRecoilState(collapsedState);

  type MenuItem = Required<MenuProps>["items"][number];
  function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
    children?: MenuItem[]
  ): MenuItem {
    return { key, icon, children, label } as MenuItem;
  }

  let iconAfter = <i className="icon-after icon-arrow---right-2"></i>;
  let iconAfterParent = <i className="icon-after icon-down-open-big"></i>;

  const items: MenuItem[] = [
    getItem(
      <Link to={ROUTE_PATHS.Home}>
        {t("menuDashboard.dashboard")} {iconAfter}
      </Link>,
      ROUTE_PATHS.Home,
      <i className="menu-icon icon-home" />
    ),
    getItem(
      <Link to={ROUTE_PATHS.Alerts}>
        {t("menuDashboard.alerts")} {iconAfter}
      </Link>,
      ROUTE_PATHS.Alerts,
      <i className="menu-icon icon-notification" />
    ),
    getItem(<>{t("menuDashboard.keyManagement")} { iconAfterParent }</>, null, <i className="menu-icon icon-key" />, [
      getItem(
        <Link to={ROUTE_PATHS.HSMKeys}>
          {t("menuDashboard.keyHSMManagement")} {iconAfter}
        </Link>,
        ROUTE_PATHS.HSMKeys,
        null
      ),
      getItem(
        <Link to={ROUTE_PATHS.EncryptedKeys}>
          {t("menuDashboard.encryptedKeys")} {iconAfter}
        </Link>,
        ROUTE_PATHS.EncryptedKeys,
        null
      ),
      getItem(
        <Link to={ROUTE_PATHS.KeyTemplates}>
          {t("menuDashboard.keyTemplates")} {iconAfter}
        </Link>,
        ROUTE_PATHS.KeyTemplates,
        null
      ),
      getItem(
        <Link to={ROUTE_PATHS.HostKeys}>
          {t("menuDashboard.hostKey")} {iconAfter}
        </Link>,
        ROUTE_PATHS.HostKeys,
        null
      ),
    ]),
    getItem(
      <Link to={ROUTE_PATHS.Terminals}>
        {t("menuDashboard.terminals")} {iconAfter}
      </Link>,
      ROUTE_PATHS.Terminals,
      <i className="menu-icon icon-mobile" />
    ),
    getItem(
      <> {t("menuDashboard.users")} {iconAfterParent}</>,
      null,
      <i className="menu-icon icon-2-user" />,
      [
        getItem(
          <Link to={ROUTE_PATHS.Users}>
            {t("menuDashboard.users")} {iconAfter}
          </Link>,
          ROUTE_PATHS.Users,
          null
        ),
        getItem(
          <Link to={ROUTE_PATHS.UserRoles}>
            {t("menuDashboard.userRoles")} {iconAfter}
          </Link>,
          ROUTE_PATHS.UserRoles,
          null
        ),
      ]
    ),
    getItem(
      <Link to={ROUTE_PATHS.Audits}>
        {t("menuDashboard.audits")} {iconAfter}
      </Link>,
      ROUTE_PATHS.Audits,
      <i className="menu-icon icon-folder" />
    ),
    getItem(
      <Link to={ROUTE_PATHS.Reports}>
        {t("menuDashboard.reports")} {iconAfter}
      </Link>,
      ROUTE_PATHS.Reports,
      <i className="menu-icon icon-document" />
    ),
    getItem(
      <Link to={ROUTE_PATHS.HostConfig}>
        {t("menuDashboard.hostConfig")} {iconAfter}
      </Link>,
      ROUTE_PATHS.HostConfig,
      <i className="menu-icon icon-setting" />
    ),
    getItem(
      <Link to={ROUTE_PATHS.SystemConfig}>
        {t("menuDashboard.systemConfig")} {iconAfter}
      </Link>,
      ROUTE_PATHS.SystemConfig,
      <i className="menu-icon icon-category" />
    )
  ];

  return (
    <Sider
      className="sider-menu"
      width={296}
      breakpoint="xl"
      collapsedWidth="0"
      trigger={null}
      collapsed={isCollapsed.isCollapsed}
      onCollapse={(value) => setCollapsedState({ isCollapsed: value })}>
      <Menu
        theme={"light"}
        className="body-regular"
        defaultSelectedKeys={[location.pathname]}
        mode="inline"
        defaultOpenKeys={["Dashboard"]}
        items={items}></Menu>
    </Sider>
  );
}

export default SiderMenu;
