import { Col, Divider, Layout, Row, Tabs, TabsProps } from "antd";
import AuthHealthCheck from "./auth-health-check";
import PortalHealthCheck from "./portal-health-check";
import TransactionHealthCheck from "./transaction-health-check";
import { useTranslation } from "react-i18next";

function HealthChecks() {
  const { t } = useTranslation();
  const onChange = (key: string) => {
  };

  const items: TabsProps["items"] = [
    {
      key: "AuthService",
      label: (
        <>
          <Row className="h5-semiBold">{t("sysConfig.label.authService")}</Row>
          <Row className="body-regular">{t("sysConfig.label.authServiceDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <AuthHealthCheck />,

    },
    {
      key: "PortalService",
      label: (
        <>
          <Row className="h5-semiBold">{t("sysConfig.label.portalService")}</Row>
          <Row className="body-regular">{t("sysConfig.label.portalServiceDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <PortalHealthCheck />,
    },
    {
      key: "TransactionService",
      label: (
        <>
          <Row className="h5-semiBold">{t("sysConfig.label.transactionService")}</Row>
          <Row className="body-regular">{t("sysConfig.label.transactionServiceDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <TransactionHealthCheck />,
    },
  ];

  function TitleApplicationManagement() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">
                {t("sysConfig.label.healthCheck")}
              </div>
              <div className="body-regular ">
                {t("sysConfig.label.healthCheck")}
              </div>
            </div>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <>
      <TitleApplicationManagement />
      <div className="container-tabs">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="container"
          destroyInactiveTabPane={true}
        />
      </div>
    </>

  );
}
export default HealthChecks;