import EditHsmKeyForm from "./components/views/key-management/hsm-keys/component/add-edit-hsm";
import { ROUTE_PATHS } from "./constants/router.constants";
import AuditLogPage from "./pages/audit-log";
import ChangePasscodePage from "./pages/change-password/change-passcode";
import DashboardPage from "./pages/dashboard";
import HostManagementPage from "./pages/host-config";
import CreateAndEditHostPage from "./pages/host-config/host-config-form";
import AddEditEncryptedKeyPage from "./pages/key-management/components/ecrypted-keys-edit";
import AddKeyTemplatesPage from "./pages/key-management/components/key-template-edit";
import EncryptedKeyManagementPage from "./pages/key-management/encrypted-keys-index";
import KeyManagementPage from "./pages/key-management/hsm-keys-index";
import KeyTemplatesPage from "./pages/key-management/key-templates-index";
import SessionTimedOutPage from "./pages/session-timed-out";
import SignInPage from "./pages/sign-in";
import MyAccountPage from "./pages/my-account";
import SystemConfigPage from "./pages/system-config";
import UserManagementPage from "./pages/user";
import UserRolesPage from "./pages/user-role";
import CreateAndEditRolePage from "./pages/user-role/role-form";
import CreateAndEditUserPage from "./pages/user/user-form";
import KeysHostManagementPage from "./pages/key-management/host-keys-index";
import EditHostKeyPage from "./pages/key-management/components/host-keys-edit";
import CreateAndEditTerminalPage from "./pages/terminal/terminal-form";
import TerminalManagementPage from "./pages/terminal";

interface RouterModel {
  href: string;
  component: () => JSX.Element;
  permissions?: string[];
}

export const anonymousRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.SignIn,
    component: SignInPage,
  },
  {
    href: ROUTE_PATHS.SessionTimedOut,
    component: SessionTimedOutPage,
  },
  {
    href: ROUTE_PATHS.ChangePasscode,
    component: ChangePasscodePage,
  },
];

export const authorizedRouters: RouterModel[] = [
  {
    href: ROUTE_PATHS.Home,
    component: DashboardPage,
  },
  {
    href: ROUTE_PATHS.MyAccount,
    component: MyAccountPage,
  },
  {
    href: ROUTE_PATHS.Alerts,
    component: DashboardPage,
  },
  {
    href: ROUTE_PATHS.Users,
    component: UserManagementPage,
  },
  {
    href: ROUTE_PATHS.UserCreate,
    component: CreateAndEditUserPage,
  },
  {
    href: ROUTE_PATHS.UserEdit,
    component: CreateAndEditUserPage,
  },
  {
    href: ROUTE_PATHS.Reports,
    component: DashboardPage,
  },
  {
    href: ROUTE_PATHS.SystemConfig,
    component: SystemConfigPage,
  },
  {
    href: ROUTE_PATHS.HSMKeys,
    component: KeyManagementPage,
  },

  {
    href: ROUTE_PATHS.HostKeys,
    component: KeysHostManagementPage,
  },
  {
    href: ROUTE_PATHS.HostKeysCreate,
    component: EditHostKeyPage,
  },
  {
    href: ROUTE_PATHS.HostKeysEdit,
    component: EditHostKeyPage,
  },
  {
    href: ROUTE_PATHS.KeyTemplates,
    component: KeyTemplatesPage,
  },
  {
    href: ROUTE_PATHS.KeyTemplatesCreate,
    component: AddKeyTemplatesPage,
  },
  {
    href: ROUTE_PATHS.KeyTemplatesEdit,
    component: AddKeyTemplatesPage,
  },
  {
    href: ROUTE_PATHS.HSMKeysEdit,
    component: EditHsmKeyForm,
  },
  {
    href: ROUTE_PATHS.HSMKeysCreate,
    component: EditHsmKeyForm,
  },
  {
    href: ROUTE_PATHS.EncryptedKeys,
    component: EncryptedKeyManagementPage,
  },
  {
    href: ROUTE_PATHS.EncryptedKeysEdit,
    component: AddEditEncryptedKeyPage,
  },
  {
    href: ROUTE_PATHS.EncryptedKeysCreate,
    component: AddEditEncryptedKeyPage,
  },
  {
    href: ROUTE_PATHS.UserRoles,
    component: UserRolesPage,
  },
  {
    href: ROUTE_PATHS.UserRolesCreate,
    component: CreateAndEditRolePage,
  },
  {
    href: ROUTE_PATHS.UserRolesEdit,
    component: CreateAndEditRolePage,
  },
  {
    href: ROUTE_PATHS.Audits,
    component: AuditLogPage,
  },
  {
    href: ROUTE_PATHS.HostConfig,
    component: HostManagementPage,
  },
  {
    href: ROUTE_PATHS.HostConfigCreate,
    component: CreateAndEditHostPage,
  },
  {
    href: ROUTE_PATHS.HostConfigEdit,
    component: CreateAndEditHostPage,
  },
  {
    href: ROUTE_PATHS.Terminals,
    component: TerminalManagementPage,
  },
  {
    href: ROUTE_PATHS.TerminalCreate,
    component: CreateAndEditTerminalPage,
  },
  {
    href: ROUTE_PATHS.TerminalEdit,
    component: CreateAndEditTerminalPage,
  }
];

export const appRoutes = [...anonymousRouters, ...authorizedRouters];
