import { KeyDisplayName, KeysEnums } from "../models/key-enums";
function commonFunction() {}

function createFileUrl(
  imageRelativePath: string | null | undefined,
  endPoint?: string
) {
  if (imageRelativePath === null || imageRelativePath === undefined) {
    return "";
  }

  if (process.env.REACT_APP_STORAGE_TYPE == "AzureBlob")
    return imageRelativePath; // TODO: UPDATE THIS WITH ENVIRONMENT.

  var baseUrl = process.env.REACT_APP_API_ENDPOINT_Auth;
  if (endPoint) baseUrl = endPoint;

  return `${baseUrl}/${imageRelativePath}`;
}

function convertArraytoString(array: any[]) {
  return array.filter((item) => typeof item === "string").join(", ");
}

function convertStringToArray(str: string): string[] {
  return str.split(",").map((item) => item.trim());
}

function buildQuery (url: string, queryObject: object) {
  let separatedBy = "?"
  if (url.indexOf("?") !== -1) separatedBy = "&"
  for (const [key, value] of Object.entries(queryObject)) {
    if (value !== undefined && value !== null) {
      url += separatedBy + key + "="  + value.toString();
      separatedBy = "&"
    }
  }
  return url
}

function getDisplayNameForKeyEnums(number: number, enums: KeysEnums): string {
  if (enums === KeysEnums.KeyModeEnums) {
    const keyModeValue = number as KeysEnums.KeyModeEnums;
    return KeyDisplayName.KeyModeDisplayName[keyModeValue] || "Unknown";
  } else if (enums === KeysEnums.KeyAlgorithmEnums) {
    const keyAlgorithmValue = number as KeysEnums.KeyAlgorithmEnums;
    return (
      KeyDisplayName.KeyAlgorithmDisplayName[keyAlgorithmValue] || "Unknown"
    );
  } else {
    const encryptionModeValue = number as KeysEnums.EncryptionModeEnums;
    return (
      KeyDisplayName.EncryptionModeDisplayName[encryptionModeValue] || "Unknown"
    );
  }
}

export const CommonUtils = {
  commonFunction,
  createFileUrl,
  convertArraytoString,
  convertStringToArray,
  buildQuery,
  getDisplayNameForKeyEnums
};
