import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import HostConfigManagement from "../../components/views/host-config";

function HostManagementPage() {
  const breadcrumbItems = ["Host Config", "Home", "Host Config"];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <HostConfigManagement />
    </Layout>
  );
}
export default HostManagementPage;
