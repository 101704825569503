import { Table } from "antd";
import { Key, TableRowSelection } from "antd/es/table/interface";
import React, { useEffect, useImperativeHandle, useState } from "react";
import {
  APIPermission,
  GroupedAPIPermission,
} from "../../../../../models/role";
import { columns } from "../component/role-permission-data";

interface RolePermissionProps {
  data: APIPermission[];
  selectRolePermissions: Key[];
}

export interface RolePermissionRef {
  getSelectRolePermissions: () => Key[];
  state: Key[];
}

const RolePermissionTab = React.forwardRef<
  RolePermissionRef,
  RolePermissionProps
>((props, ref) => {
  const { selectRolePermissions: initialRolePermissions, data: selectedData } =
    props;
  const [selectRolePermissions, setSelectRolePermissions] = useState<Key[]>(
    initialRolePermissions || []
  );

  useEffect(() => {
    if (
      initialRolePermissions &&
      initialRolePermissions !== selectRolePermissions
    ) {
      setSelectRolePermissions(initialRolePermissions);
    }
  }, [initialRolePermissions]);

  const getSelectRolePermissions = () => {
    return selectRolePermissions;
  };

  useImperativeHandle(ref, () => ({
    getSelectRolePermissions,
    state: selectRolePermissions,
  }));

  const rowSelection: TableRowSelection<GroupedAPIPermission> = {
    defaultSelectedRowKeys: initialRolePermissions,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectRolePermissions(selectedRowKeys);
    },
    onSelect: (record, selected, selectedRows) => {},
    onSelectAll: (selected, selectedRows, changeRows) => {},
  };

  const groupedData: GroupedAPIPermission[] = selectedData.reduce<
    GroupedAPIPermission[]
  >((grpPermission, item) => {
    const existingGroup = grpPermission.find(
      (group) => group.name === item.controllerName
    );

    if (existingGroup) {
      item.key = item.id;
      existingGroup.children.push(item);
    } else {
      item.key = item.id;
      grpPermission.push({
        key: grpPermission.length + 1,
        name: item.controllerName,
        children: [item],
      });
    }

    return grpPermission;
  }, []);

  return (
    <Table
      rowKey={x => x.key}
      columns={columns}
      rowSelection={{ ...rowSelection, checkStrictly: false }}
      dataSource={groupedData}
    />
  );
});

export default RolePermissionTab;
