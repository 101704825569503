import "./index.scss";

import { Button } from "antd";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../../../constants/router.constants";

function MaintenancePage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  function backToHome() {
    navigate(ROUTE_PATHS.Home);
  }

  return (
    <div className="not-found-page">
      <div className="not-found-page__header">
        <Link to={ROUTE_PATHS.Home}>
          {/* <img src={logo} /> */}
        </Link>
      </div>

      <div className="not-found-page__body">
        <h1 dangerouslySetInnerHTML={{ __html: t("maintenance.title") }}></h1>
        <p
          dangerouslySetInnerHTML={{ __html: t("maintenance.description") }}
        ></p>
        <a href="https://rocketlevel.com/">
          <Button
            // icon={<SVGIcons.WordIcon />}
            type="primary"
            onClick={backToHome}
          >
            {t("maintenance.backToHome")}
          </Button>
        </a>

        <a
          // href={`mailto:${SUPPORT_EMAIL}`}
          className="text-success">
          {t("maintenance.contactSupport")}
        </a>
      </div>

      <div className="not-found-page__foolter">
        <small>{t("common.copyRight")}</small>
      </div>
    </div>
  );
}

export default MaintenancePage;
