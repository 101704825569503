import { AxiosResponse } from "axios";
import { HealthCheck } from "../models/health-check";
import { getAsync } from "./http-client";

const adminPortalUrl = process.env.REACT_APP_API_ENDPOINT_Portal;
const authUrl = process.env.REACT_APP_API_ENDPOINT_Auth;
const transactionUrl = process.env.REACT_APP_API_ENDPOINT_Transaction;

function getHealthyCheckAuth(): Promise<AxiosResponse<HealthCheck>> {
  const url = `${authUrl}/dependents-health`;
  return getAsync(url, undefined, false, false);
}

function getHealthyCheckAdminPortal(): Promise<AxiosResponse<HealthCheck>> {
  const url = `${adminPortalUrl}/dependents-health`;
  return getAsync(url, undefined, false, false);
}

function getHealthyCheckTransaction(): Promise<AxiosResponse<HealthCheck>> {
  const url = `${transactionUrl}/dependents-health`;
  return getAsync(url, undefined, false, false);
}

export const HealthCheckApi = {
  getHealthyCheckAuth,
  getHealthyCheckAdminPortal,
  getHealthyCheckTransaction
}