import moment, { Moment } from "moment";
import { DATE_TIME_FORMAT } from "../constants/app-constants";

const addUTCCharater = (value: string) => {
  if (value && value.includes("Z")) {
    return value;
  }
  return value + "Z";
};

const convertServerToDateViewFormat = (
  value: string | undefined | null
): string => {
  if (!value) {
    return "";
  }
  return moment(addUTCCharater(value)).format(DATE_TIME_FORMAT.dateView);
};

const convertServerToTimeViewFormat = (
  value: string | undefined | null
): string => {
  if (!value) {
    return "";
  }
  return moment(addUTCCharater(value)).format(DATE_TIME_FORMAT.timeView);
};

function dateView(value: Moment | string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.dateView);
}

function dateAndMonthView(value: string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.dateMonthView);
}

function monthView(value: Moment | string): string {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.monthView);
}

const timeView = (value: string): string => {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.timeView);
};

const isoDate = (value: string): string => {
  if (!value) {
    return "";
  }
  return moment(value).format(DATE_TIME_FORMAT.isoDate);
};

function getMonths(startDate: string, endDate: string) {
  var start = startDate.split("-");
  var end = endDate.split("-");
  var startYear = parseInt(start[0]);
  var endYear = parseInt(end[0]);
  var dates = [];

  for (var i = startYear; i <= endYear; i++) {
    var endMonth = i != endYear ? 11 : parseInt(end[1]) - 1;
    var startMon = i === startYear ? parseInt(start[1]) - 1 : 0;
    for (var j = startMon; j <= endMonth; j = j > 12 ? j % 12 || 11 : j + 1) {
      var month = j + 1;
      var displayMonth = month < 10 ? "0" + month : month;
      dates.push([i, displayMonth, "01"].join("-"));
    }
  }
  return dates;
}

// 2023-09-07T00:00:00Z => 2023-09-06T17:00:00Z
function convertLocalUTCToUTC(value: string) {
  const dateLocalStr = value.slice(0, -1); // remove Z
  return moment(dateLocalStr).utc();
}

const convertServerToDateTimeViewFormat = (
  value: string | undefined | null
): string => {
  if (!value) {
    return "";
  }
  return moment(addUTCCharater(value)).format(
    DATE_TIME_FORMAT.dateTimeViewFormat
  );
};

// function to convert UTC time to local time display as moment
function convertUTCToLocal(utcDate: string, formatDate: string = "") {
  var utcDateTime = moment.utc(utcDate);

  var timezoneOffset = moment().utcOffset();

  var localDateTime = utcDateTime
    .utcOffset(timezoneOffset)
    .format( formatDate === "" ? DATE_TIME_FORMAT.dateTimeViewFormat : formatDate);

  return localDateTime;
}

// Function to convert UTC time to local time display as moment
function convertUTCToLocalAsMoment(utcDate: string): moment.Moment {
  var utcDateTime = moment.utc(utcDate);
  var localDateTime = utcDateTime.utcOffset(moment().utcOffset());
  return localDateTime;
}

// Function to convert local time to UTC time return string
function convertLocalToUTCTime(localDatetimeString: string) {
  var localDatetime = moment(
    localDatetimeString,
    DATE_TIME_FORMAT.dateTimeViewFormat
  );

  var utcDatetime = localDatetime.utc();

  var utcDatetimeString = utcDatetime.format(
    DATE_TIME_FORMAT.dateTimeViewFormatUTC
  );

  return utcDatetimeString;
}

// Function to convert time in DatePicker to UTC time return string
//"Mon, 29 Apr 2024 08:55:07 GMT" => "2024-03-13T12:00:00Z"
function convertDatePickerTimeToUTCTime(datetime: string | undefined) {
  try {
    if (datetime === null || datetime === undefined) {
      return undefined;
    }
    const parsedDate = new Date(datetime);

    // Check for invalid date
    if (isNaN(parsedDate.getTime())) {
      throw new Error(`Invalid date format provided: "${datetime}"`);
    }

    // Return converted time string
    return parsedDate.toISOString().slice(0, -1) + 'Z';
  } catch (error) {
      // Handle other potential errors during conversion (optional)
      console.error('Error converting date time:', error);
      throw error; // Re-throw the error for further handling
  }
}

const DateUtils = {
  dateView,
  timeView,
  getMonths,
  monthView,
  isoDate,
  dateAndMonthView,
  convertServerToDateViewFormat,
  convertServerToTimeViewFormat,
  convertLocalUTCToUTC,
  convertServerToDateTimeViewFormat,
  convertLocalToUTCTime,
  convertUTCToLocal,
  convertDatePickerTimeToUTCTime,
  convertUTCToLocalAsMoment
};

export default DateUtils;
