import { LoadingOutlined, UserOutlined } from "@ant-design/icons";

import {
  Avatar,
  Button,
  Col,
  DatePicker,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RoleApi } from "../../../../../apis/role.api";
import { UserApi } from "../../../../../apis/user.api";
import {
  DefaultDateTime,
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import { UserModelInf } from "../../../../../models/user";
import { CommonUtils } from "../../../../../utils/common";
import DateUtils from "../../../../../utils/date.utils";
import UpdateUserEmailPopup from "../../../../common/modal-mfa/modal-email";
import UpdateUserPhonePopup from "../../../../common/modal-mfa/modal-phone";
import showNotification from "../../../../common/notification/index";
import ModaResetPasscode from "./reset-passcode";

const { RangePicker } = DatePicker;

function TabInfomation() {
  const { t } = useTranslation();
  const [infomationForm] = Form.useForm();
  const { id } = useParams();
  const isEdit = id != null;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpenUpdateUserEmailPopup, setIsOpenUpdateUserEmailPopup] =
    useState(false);
  const [isOpenUpdateUserPhonePopup, setIsOpenUpdateUserPhonePopup] =
    useState(false);
  const [user, setUser] = useState<UserModelInf>();
  const [roles, setRoles] = useState<any[] | []>([]);
  const navigate = useNavigate();
  const checkUrl = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const responseData = await handlerGetUserById(id ?? "");
      const fromDate = responseData?.accessLimitDateFrom
        ? DateUtils.isoDate(responseData.accessLimitDateFrom)
        : null;
      const toDate = responseData?.accessLimitDateTo
        ? DateUtils.isoDate(responseData.accessLimitDateTo)
        : null;
      const datePickerValues = [];
      if (fromDate) {
        datePickerValues.push(fromDate);
      }
      if (toDate) {
        datePickerValues.push(toDate);
      }

      infomationForm.setFieldsValue({
        firstName: responseData?.firstName,
        surname: responseData?.surname,
        middleName: responseData?.middleName,
        perferredName: responseData?.perferredName,
        personalIdNumber: responseData?.personalIdNumber,
        email: responseData?.email,
        birthday: DateUtils.isoDate(
          responseData?.birthdayString ?? DefaultDateTime
        ),
        cellNumber: responseData?.cellNumber,
        cellCountryCode: responseData?.cellCountryCode,
        cellDialCode: responseData?.cellDialCode,
        roleId: responseData?.roleId,
        Picker: datePickerValues.length > 0 ? datePickerValues : undefined,
      });

      setUser(responseData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchRole = async function getRoles() {
      const { data } = await RoleApi.getAllRoles();

      const roleData: any = data.map((role) => ({
        label: role.roleName,
        value: role.id,
      }));

      setRoles(roleData);
    };
    fetchRole();
  }, []);

  const dateFormat = "YYYY-MM-DD";

  async function handlerGetUserById(
    id: string
  ): Promise<UserModelInf | undefined> {
    const response = await UserApi.getUserInfById(id);
    return response.data;
  }

  const uploadButton = (
    <span className="upload-btn">
      {loadingBtn ? <LoadingOutlined /> : <i className="icon-plus" />}
    </span>
  );

  const onSubmit = () => {
    infomationForm.submit();
  };

  const handleChangePhoneInput = (
    value: any,
    data: any,
    event: React.FocusEvent<HTMLInputElement>,
    formattedValue: any
  ) => {
    data?.countryCode &&
      infomationForm.setFieldValue(
        "cellCountryCode",
        data.countryCode.toUpperCase()
      );
    data?.dialCode &&
      infomationForm.setFieldValue("cellDialCode", data.dialCode);
  };

  async function handlePostUser(values: any) {
    setLoadingBtn(true);
    try {
      var datePicker: string[] = values.Picker ?? [];
      const userRequest: UserModelInf = {
        id: id ?? DefaultGUID,
        isDeactivate: values.isDeactivate,
        personalIdNumber: values.personalIdNumber,

        firstName: values.firstName,
        perferredName: values.perferredName,
        middleName: values.middleName,
        surname: values.surname,
        email: values.email,

        avatarUpload: values.profilePicture,

        birthdayString: DateUtils.convertDatePickerTimeToUTCTime(
          values.birthday
        ),
        cellNumber: values.cellNumber,
        roleId: values.roleId,
        cellCountryCode: values.cellCountryCode,
        cellDialCode: values.cellDialCode,

        accessLimitDateFrom: DateUtils.convertDatePickerTimeToUTCTime(
          datePicker[0]
        ),
        accessLimitDateTo: DateUtils.convertDatePickerTimeToUTCTime(
          datePicker[1]
        ),
      };

      const data =
        checkUrl.pathname === ROUTE_PATHS.UserCreate
          ? await UserApi.createUserInf(userRequest)
          : await UserApi.putUserInf(userRequest);

      const message =
        checkUrl.pathname === ROUTE_PATHS.UserCreate
          ? t("user.notiCreateUserSuccess")
          : t("user.notiUpdateUserSucces");

      showNotification("success", message);

      if (data) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.Users);
        }, 1000);
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleTabInformation() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userInf_set")}</div>
              <div className="body-regular ">{t("title.userInf_set")}</div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.Users}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onSubmit}
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string }
  ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onCloseUpdateUserEmailPopup = (isReload: boolean) => {
    setIsOpenUpdateUserEmailPopup(false);
    isReload && fetchData();
  };

  const onCloseUpdateUserPhonePopup = (isReload: boolean) => {
    setIsOpenUpdateUserPhonePopup(false);
    isReload && fetchData();
  };

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <TitleTabInformation />
      <Form
        name="infomationForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        form={infomationForm}
        onFinish={handlePostUser}
        className="form-submit-information"
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} lg={4}>
            <div className="label-avatar">
              <div>{t("title.user_profile")}</div>
              <div className="format-picture">
                {t("title.profile_format_title")}
              </div>
              <div className="format-picture">
                {t("title.size_formate_title")}
              </div>
            </div>
          </Col>
          <Col sm={24} xs={24} lg={8}>
            <div className="avatar">
              {isLoading ? (
                <LoadingOutlined />
              ) : user?.personalImage ? (
                <Avatar
                  src={CommonUtils.createFileUrl(user?.personalImage)}
                  shape="square"
                  alt="avatar"
                  size={100}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    marginRight: "7px",
                  }}
                  icon={<UserOutlined />}
                />
              ) : (
                <Avatar
                  shape="square"
                  alt="avatar"
                  size={100}
                  style={{
                    backgroundColor: "rgba(0, 0, 0, 0.25)",
                    marginRight: "7px",
                  }}
                  icon={<UserOutlined />}
                />
              )}

              <Form.Item>
                <Upload
                  fileList={[]}
                  customRequest={() => {}}
                  accept=".jpg, .jpeg, .png, .svg"
                ></Upload>
              </Form.Item>
            </div>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="firstName"
              label={t("user.firstName")}
              messageVariables={{ label: "First Name" }}
              rules={[{ required: true }]}
            >
              <Input className="input-base" placeholder={t("user.firstName")} />
            </Form.Item>

            <Form.Item
              name="middleName"
              label={t("user.middleName")}
              messageVariables={{ label: "Middle Name" }}
            >
              <Input
                className="input-base"
                placeholder={t("user.middleName")}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label={t("user.email")}
              messageVariables={{ label: "Email" }}
              rules={[{ required: true, type: "email" }]}
            >
              <Input
                className={isEdit ? "input-with-addon" : "input-base"}
                addonAfter={
                  isEdit ? (
                    <Button
                      type="primary"
                      className="addon-btn"
                      onClick={() => setIsOpenUpdateUserEmailPopup(true)}
                    >
                      Change
                    </Button>
                  ) : null
                }
                placeholder="Email"
                disabled={isEdit}
              />
            </Form.Item>

            <Form.Item
              label={t("user.user_role")}
              name="roleId"
              className="select-role"
              rules={[{ required: true }]}
            >
              <Select
                className="select-base"
                showSearch={true}
                placeholder={t("rolePermision.template")}
                filterOption={filterOption}
                options={roles}
              />
            </Form.Item>
            {isEdit && (
              <div className="passcode-item">
                <div className="passcode-label">{t("user.password")}</div>
                <div className="passcode-button">
                  <Button
                    className="icon-key btn-modal reset-passcode"
                    onClick={showModal}
                  >
                    {t("user.resetPassword")}
                  </Button>
                </div>
              </div>
            )}
          </Col>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              label={t("user.lastName")}
              name="surname"
              messageVariables={{ label: "Last Name" }}
              rules={[{ required: true }]}
            >
              <Input className="input-base" placeholder={t("user.lastName")} />
            </Form.Item>

            <Form.Item
              label={t("user.birthday")}
              name="birthday"
              messageVariables={{ label: "Birthday" }}
              getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
              rules={[{ required: true }]}
            >
              <DatePicker
                showToday={false}
                format={dateFormat}
                className="datepicker-input input-base"
              />
            </Form.Item>

            <Form.Item
              label="cellCountryCode"
              name="cellCountryCode"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item label="cellDialCode" name="cellDialCode" hidden={true}>
              <Input type="text" />
            </Form.Item>

            <div className={`${isEdit ? "cur-addon-btn" : ""}`}>
              <Form.Item
                name="cellNumber"
                className="phone-number"
                label="Phone"
                rules={[
                  { required: true, message: "Phone field is required." },
                ]}
              >
                <PhoneInput
                  disabled={isEdit}
                  enableSearch
                  country={"us"}
                  searchPlaceholder={t("user.searchNumberPlaceholder")}
                  onChange={handleChangePhoneInput}
                />
              </Form.Item>
              {isEdit && (
                <Button
                  type="primary"
                  onClick={() => setIsOpenUpdateUserPhonePopup(true)}
                >
                  Change
                </Button>
              )}
            </div>

            <Form.Item
              label={t("user.activation_time")}
              name="Picker"
              getValueProps={(i) => ({
                value: i
                  ? (i as [string, string]).map((dateString) =>
                      dayjs(dateString)
                    )
                  : null,
              })}
            >
              <RangePicker
                className="datepicker-input input-base"
                format={dateFormat}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ModaResetPasscode
        visible={isModalOpen}
        onClose={handleCancel}
        user={user}
      />
      <UpdateUserEmailPopup
        visible={isOpenUpdateUserEmailPopup}
        onClose={onCloseUpdateUserEmailPopup}
        email={user?.email ?? ""}
        userId={id ?? ""}
      />
      <UpdateUserPhonePopup
        visible={isOpenUpdateUserPhonePopup}
        onClose={onCloseUpdateUserPhonePopup}
        cellNumber={user?.cellNumber ?? ""}
        cellCountryCode={user?.cellCountryCode ?? ""}
        cellDialCode={user?.cellDialCode ?? ""}
        userId={id ?? ""}
      />
    </Spin>
  );
}

export default TabInfomation;
