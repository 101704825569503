import { Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import saveAs from "file-saver";
import { useEffect, useState } from "react";
import { AuditLogApi } from "../../../../../apis/audit.api";
import { LogType } from "../../../../../constants/app.enums";
import { AuditLog } from "../../../../../models/audit-log";
import DateUtils from "../../../../../utils/date.utils";
import showNotification from "../../../../common/notification";
import PaginationComponent from "../../../../common/pagination/component/pagination";
import LogComponent from "../audit-log-component/log-component";
import DrawerFilter from "../drawer-filter/drawer-filter";
import "./operation-log.scss";

export function getDate() {
  const today = new Date();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();
  const date = today.getDate();
  return `${month}/${date}/${year}`;
}

function OperationLog() {
  const [logs, setLogs] = useState<AuditLog[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [filterData, setFilterData] = useState<any>({});

  useEffect(() => {
    handleGetAuditLogs();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterData, pageSize, pageNumber]);

  const columns: ColumnsType<AuditLog> = [
    {
      title: null,
      render: (record: any) => {
        return <LogComponent type={LogType.OperationLog} log={record} />;
      },
    },
  ];


  const handleGetAuditLogs = async () => {
    try {
      setOpenDrawer(false);
      setIsLoading(true);
      
      let filterObject = {
        startDate: filterData.dateTimeFilter ? filterData.dateTimeFilter[0].toISOString() : null,
        endDate: filterData.dateTimeFilter ? filterData.dateTimeFilter[1].toISOString() : null,
        loginName: filterData.loginNameFilter ?? null,
        ipRequest: filterData.ipFilter ?? null,
        logStatus: filterData.statusFilter && filterData.statusFilter?.length === 1 ? filterData.statusFilter[0] : null
      };
  
      const res = await AuditLogApi.getAuditLogs(pageNumber, pageSize, LogType.OperationLog, filterObject);
      
      setLogs(res?.data?.records ?? []);
      setTotalRecords(res?.data?.totalRecords ?? 0);
    } catch (error) {
      console.error('Error fetching audit logs:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportOperationLog = async () => {
    setIsLoading(true);
    showNotification(
      "warning",
      "Just a moment, please. We're carefully preparing your Operation Log report."
    );
    await new Promise((resolve) => setTimeout(resolve, 5000));
    try {
      const typeSystemLog = LogType.OperationLog;
      const { data } = await AuditLogApi.getOperationLogReport(typeSystemLog);
      if (data) {
        setIsLoading(false);
        showNotification(
          "success",
          "Your Operation Log report is ready for download."
        );
        const currentDate = getDate();
        saveAs(
          data,
          `$Export_Operation_Logs_${DateUtils.dateView(currentDate)}`
        );
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="row-filter">
        {/* <span className="year-filter">
          <YearFilterList filters={yearList} />
        </span> */}
        <span className="right-filter">
        {/* <i className="icon-paper-export" onClick={handleExportOperationLog}></i> */}
        <i className="icon-filter-3" onClick={() => setOpenDrawer(true)}></i>
        <DrawerFilter
          openDrawer={openDrawer}
          onClose={() => setOpenDrawer(false)}
          filterData={filterData}
          handleFilterData={(data) => setFilterData(data)}
          type={LogType.OperationLog}
        />
        </span>
      </div>
      <Row>
        <Col span={24}>
          <Table
            showHeader={false}
            columns={columns}
            dataSource={logs}
            loading={isLoading}
            className="tab-audlt-log"
            rowKey="traceId"
            rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'}
            pagination={{
              pageSize: pageSize,
              current: pageNumber,
              style: { display: "none" },
            }}
          />
        </Col>
        <PaginationComponent
          totalRecords={totalRecords}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
        />
      </Row>
    </>
  );
}

export default OperationLog;
