import { AxiosResponse } from "axios";
import { HostConfigDisplay } from "../models/host-config";
import { PaginationResponseModel } from "../models/pagination-response-model";
import {
  deleteAsync,
  getAsync,
  postAsync,
  postFormDataAsync,
  postFormDataMultipleFile,
  putFormDataMultipleFile
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Portal}/v1`;

function uploadDLL(dllFile :File): Promise<AxiosResponse<HostConfigDisplay>> {
  const url = `${baseUrl}/hostconfig/upload-dll`;
  return postFormDataAsync(url, dllFile);
}

function getHostConfigs(
  pageIndex: number,
  pageSize: number,
  searchString: string
): Promise<AxiosResponse<PaginationResponseModel<HostConfigDisplay>>> {
  const url = `${baseUrl}/hostconfig?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${searchString}`;
  return getAsync(url);
}

function getAllHostConfigs(
): Promise<AxiosResponse<HostConfigDisplay[]>> {
  const url = `${baseUrl}/hostconfig/all`;
  return getAsync(url, undefined, true, true,false);
}

function getHostConfigById(id: string): Promise<AxiosResponse<HostConfigDisplay>> {
  const url = `${baseUrl}/hostconfig/${id}`;
  return getAsync(url, undefined, false, false);
}

function postHostConfig(
  data: any
): Promise<AxiosResponse<HostConfigDisplay>> {
  const url = `${baseUrl}/hostconfig`;
  return postFormDataMultipleFile(url, data);
}

function updateHostConfig(
  data: any
): Promise<AxiosResponse<HostConfigDisplay>> {
  const url = `${baseUrl}/hostconfig`;
  return putFormDataMultipleFile(url, data);
}

function activeHostConfig(id: string, isDeactive?: boolean) {
  const url = `${baseUrl}/hostconfig/active/${id}/${isDeactive}`;
  return postAsync(url, undefined, true, true);
}

function deleteHostConfig(id: string) {
  const url = `${baseUrl}/hostconfig/${id}`;
  return deleteAsync(url, undefined, true, true);
}

export const HostConfigApi = {
  uploadDLL,
  getHostConfigs,
  getAllHostConfigs,
  getHostConfigById,
  postHostConfig,
  updateHostConfig,
  activeHostConfig,
  deleteHostConfig
};
