import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { AuthApi } from "../../../apis/auth.api";
import { UserApi } from "../../../apis/user.api";
import logo from "../../../assets/images/PAX_Logo.svg";
import {
  PASSWORD_REGEX,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import {
  ChangePasswordType,
  FormAuth,
  MFAType,
  OTPType,
} from "../../../models/enums";
import {
  MFARequest,
  SendOTPRequestModel,
  SignInRequestModel,
  SignInResponseModel,
} from "../../../models/sign-in.model";
import LocalUtils from "../../../utils/local.utils";
import GuidePasscode from "../../common/guide-passcode/guide-passcode";
import StepDualAccess from "./components/dual-access";
import PasscodeWarning from "./components/passcode-warning";
import StepVerificationEmail from "./components/vertified-email";
import StepVerificationPhone from "./components/vertified-phone";
import "./index.scss";

function SignInForm() {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(MFAType.None);
  const [loginForm] = Form.useForm();
  const [phoneForm] = Form.useForm();
  const [emailForm] = Form.useForm();
  const [dualOTPForm] = Form.useForm();
  const [signInResponse, setSignInResponse] =
    useState<SignInResponseModel | null>(null);
  const [messageAlert, setmessageAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passcodeWarningDays, setPasscodeWarningDays] = useState(Number || 0);
  const navigate = useNavigate();
console.log(process.env.REACT_APP_CUSTOM_NODE_ENV);
  useEffect(() => {
    if (signInResponse !== null) {
      handleSendOTP();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [signInResponse]);

  async function handleSubmit(formName: FormAuth) {
    setLoading(true);

    try {
      if (formName === FormAuth.Login) {
        await handleSignIn(loginForm.getFieldsValue());
      } else if (formName === FormAuth.EmailVerification) {
        await handleMultiFactorAuth(
          MFAType.EmailCode,
          emailForm.getFieldsValue()
        );
      } else if (formName === FormAuth.PhoneVerification) {
        await handleMultiFactorAuth(
          MFAType.PhoneCode,
          phoneForm.getFieldsValue()
        );
      } else if (formName === FormAuth.DualOTP) {
        await handleMultiFactorAuth(
          MFAType.DualOTP,
          dualOTPForm.getFieldsValue()
        );
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleSendOTP() {
    try {
      setmessageAlert(false);

      const modeRequest: SendOTPRequestModel = {
        phoneCountryCode: signInResponse?.userPhoneCountryCode,
        phoneNumber: signInResponse?.userPhoneNumber,
        emailAddress: signInResponse?.userEmail,
      };

      switch (signInResponse?.mfaType) {
        case MFAType.EmailCode:
          await AuthApi.sendOTP(OTPType.MFAMail, modeRequest);
          break;
        case MFAType.PhoneCode:
          await AuthApi.sendOTP(OTPType.MFAPhone, modeRequest);
          break;
        case MFAType.DualOTP:
          await AuthApi.sendOTP(OTPType.MFAMail, modeRequest);
          await AuthApi.sendOTP(OTPType.MFAPhone, modeRequest);
          break;
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function handleSignIn(formValue: any) {
    try {
      setmessageAlert(false);

      const modeRequest: SignInRequestModel = {
        emailAddress: formValue.email,
        emailPasscode: formValue.password,
      };

      const { data } = await AuthApi.signIn(modeRequest);

      if (data) {
        handleAuthenticationResponse(data);
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  async function handleMultiFactorAuth(type: MFAType, formValue: any) {
    try {
      let modeRequest: MFARequest = {
        loginUserId: signInResponse?.userId,
      } as MFARequest;

      if (type === MFAType.EmailCode) {
        modeRequest.mailOTP = formValue.otp.join("");
      }

      if (type === MFAType.PhoneCode) {
        modeRequest.phoneOTP = formValue.otp.join("");
      }

      if (type === MFAType.DualOTP) {
        modeRequest.mailOTP = formValue.mailOTP.join("");
        modeRequest.phoneOTP = formValue.phoneOTP.join("");
      }

      const { data: multiFactorData } = await AuthApi.multiFactor(
        type,
        modeRequest
      );

      if (multiFactorData.token) {
        if (multiFactorData) {
          await handleRedirect(multiFactorData);
        }
      }
    } catch (error) {
      handleErrorResponse(error);
    }
  }

  function handleErrorResponse(error: any) {
    const data = error.response?.data;
    if (data) {
      setmessageAlert(data.message);
    } else {
      setmessageAlert(t("common.serverError"));
    }
  }

  async function handleRedirect(signInResponse: SignInResponseModel) {
    let redirectType = null;

    if (signInResponse.changePasscodeFirstTime) {
      LocalUtils.setAuthenticatedData(signInResponse, false, "false");
      redirectType = ChangePasswordType.FisrtTimeLogin;
    } else if (signInResponse.redirectChangePasscode) {
      if (signInResponse.passcodeWarningDays === 0) {
        LocalUtils.setAuthenticatedData(signInResponse, false, "false");
        redirectType = ChangePasswordType.PasswordExpired;
      } else {
        LocalUtils.setAuthenticatedData(signInResponse, false);
        redirectType = ChangePasswordType.PasswordWarning;
      }
    } else {
      LocalUtils.setAuthenticatedData(signInResponse, false);
      const { data } = await UserApi.getUserInfById(signInResponse.userId);
      LocalUtils.setUserInfo(data);
      window.location.href = ROUTE_PATHS.Home;
    }

    if (redirectType) {
      if (redirectType === ChangePasswordType.PasswordWarning) {
        const { data } = await UserApi.getUserInfById(signInResponse.userId);
        LocalUtils.setUserInfo(data);
        setPasscodeWarningDays(signInResponse.passcodeWarningDays);
        showModal();
      } else {
        navigate(ROUTE_PATHS.ChangePasscode, {
          replace: true,
          state: { type: redirectType },
        });
        return;
      }
    }
  }

  async function handleAuthenticationResponse(
    signInResponse: SignInResponseModel
  ) {
    switch (signInResponse.mfaType) {
      case MFAType.None:
        if (signInResponse) {
          await handleRedirect(signInResponse);
        }

        break;

      case MFAType.PhoneCode:
      case MFAType.EmailCode:
      case MFAType.DualOTP:
        setStep(signInResponse.mfaType);
        setSignInResponse(signInResponse);
        break;
    }
  }

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function StepLogin() {
    return (
      <>
        <div className="auth-layout__logo_wrapper">
          <Link to={ROUTE_PATHS.Home}>
            <img src={logo} className="auth-layout__logo" alt="" />
          </Link>
        </div>
        <div className="sign-in">
          <div className="header">
            <div className="title h2-semiBold">{t("login.title")}</div>
            <div className="title-detail body-regular">
              {t("login.titleDetail")}
            </div>
          </div>

          <div className="sign-in-form">
            {messageAlert && (
              <Alert style={{width:"80%"}} message={messageAlert} type="error" showIcon closable />
            )}
            <br />
            <Form
              layout="vertical"
              validateMessages={VALIDATION_MESSAGE_CONFIG}
              onFinish={() => handleSubmit(FormAuth.Login)}
              className="update-passcode-form passcode-checklist-form"
              form={loginForm}
            >
              <Form.Item
                name="email"
                messageVariables={{ label: "Email" }}
                hasFeedback
                rules={[{ required: true, type: "email" }]}
              >
                <Input
                  className="input-base login-input body-regular"
                  placeholder={t("login.emailPlahoder")}
                />
              </Form.Item>
              <div className="password-content password-content-tooltip">
              <Tooltip
                  placement="right"
                  className="guide-password"
                  color={"#D9D9D9"}
                  overlayClassName="guide-tooltip"
                  title={<GuidePasscode />}
                  trigger="click"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>

                <Form.Item
                  name="password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      pattern: PASSWORD_REGEX,
                      message: "The password required or format incorrect.",
                    },
                  ]}
                >
                  <Input.Password
                    className="input-base login-input body-regular"
                    placeholder={t("login.passwordPlahoder")}
                  />
                </Form.Item>
              </div>
              <Form.Item className="btn-login-form">
                <Button
                  htmlType="submit"
                  className="btn-login btn-base body-regular"
                  type="primary"
                  block
                  loading={loading}
                >
                  {t("login.continue")}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </>
    );
  }

  return (
    <>
      {step === MFAType.None && <StepLogin />}
      {step === MFAType.PhoneCode && (
        <StepVerificationPhone
          form={phoneForm}
          signInResponse={signInResponse}
          messageAlert={messageAlert}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {step === MFAType.EmailCode && (
        <StepVerificationEmail
          form={emailForm}
          signInResponse={signInResponse}
          messageAlert={messageAlert}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}
      {step === MFAType.DualOTP && (
        <StepDualAccess
          form={dualOTPForm}
          signInResponse={signInResponse}
          messageAlert={messageAlert}
          handleSubmit={handleSubmit}
          loading={loading}
        />
      )}

      <PasscodeWarning
        visible={isModalOpen}
        onClose={handleCancel}
        passcodeWarningDays={passcodeWarningDays}
      />
    </>
  );
}

export default SignInForm;
