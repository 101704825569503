import { Layout } from "antd";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { authState } from "../../../states/auth";
import HeaderDashboard from "./helpers/header";

import { collapsedState } from "../../../states/sidebar";
import SiderMenu from "./helpers/siderMenu";
import "./index.scss";
import SessionTimeout from "../../views/session-timeout";

function DashboardLayout() {
  const auth = useRecoilValue(authState);
  const user = auth.user;
  const isCollapsed = useRecoilValue(collapsedState);

  function ButtonTriggerSider() {
    return isCollapsed.isCollapsed ? (
      <i className="icon-menu"></i>
    ) : (
      <i className="icon-close"></i>
    );
  }

  return (
    <Layout style={{ height: "100%" }}>
      <SessionTimeout></SessionTimeout>
      <HeaderDashboard user={user} trigger={ButtonTriggerSider()} />
      <Layout>
        <SiderMenu />
        <Outlet />
      </Layout>
    </Layout>
  );
}

export default DashboardLayout;
