import { Badge, Button, Col, Row, Space, Table, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { HostConfigApi } from "../../../apis/host-config.api";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { HostConfigDisplay } from "../../../models/host-config";
import showConfirmModal from "../../common/modal-confirm";

import DateUtils from "../../../utils/date.utils";
import showNotification from "../../common/notification";
import PaginationComponent from "../../common/pagination/component/pagination";
import "./index.scss";

function HostConfigManagement() {
  const { t } = useTranslation();
  const [hostConfigs, setHostConfigs] = useState<HostConfigDisplay[] | any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    handleGetHostConfigs();
  }, []);

  const tableScroll = {
    x: "max-content",
  };

  const handleGetHostConfigs = async (
    pageSizeData?: number,
    pageNumberData?: number,
    searchString?: string
  ) => {
    pageSizeData = pageSizeData || pageSize;
    pageNumberData = pageNumberData || pageNumber;
    searchString = searchString || "";
    setIsLoading(true);
    try {
      const { data } = await HostConfigApi.getHostConfigs(
        pageNumberData,
        pageSizeData,
        searchString
      );

      setHostConfigs(data.records);
      setTotalRecords(data.totalRecords);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickActive = (id: string, isDeactivate?: boolean): void => {
    let title = isDeactivate ? "Active" : "Deactive";

    const handleConfirm = async () => {
      try {
        let { data: activeResponse } = await HostConfigApi.activeHostConfig(
          id,
          isDeactivate
        );
        if (activeResponse) {
          showNotification("success", "Updated host config successfully.");
          await handleGetHostConfigs();
        }
      } catch (error) {  
      }
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "warning",
      title: `${title} this host config now`,
      content: "Are you sure you want to perform this action?",
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await HostConfigApi.deleteHostConfig(id);
        if (deleteResponse) {
          showNotification("success", "Deleted host config successfully.");
          await handleGetHostConfigs();
        }
      } catch (error) {        
      }
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this host config now`,
      content: "Are you sure you want to perform this action?",
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const onSearch = (value: any) => {
    handleGetHostConfigs(undefined, undefined, value);
  };

  const hostConfigColumns: ColumnsType<HostConfigDisplay> = [
    {
      title: "Host Name",
      key: "1",
      render: (_, record: any) => <>{record.name}</>,
    },
    {
      title: "Created Date",
      key: "2",
      render: (_, record: any) => <Row> {DateUtils.convertServerToDateTimeViewFormat(record.createdOn)}</Row>,
    },
    {
      title: "Last Modified Date",
      key: "2",
      render: (_, record: any) => <Row>{DateUtils.convertServerToDateTimeViewFormat(record.modifiedOn)}</Row>,
    },
    {
      title: "Status",
      key: "3",
      className: "col-status",
      dataIndex: "isDeactivate",
      render: (isDeactivate) => {
        return (
          <>
            {isDeactivate && <Badge status="error" text="Deactivate" />}
            {!isDeactivate && <Badge status="success" text="Activate" />}
          </>
        );
      },
    },
    {
      key: "4",
      className: "col-action",
      render: (_, record) => (
        <Space size={[6, 12]} wrap className="action-cpn">
          <Tooltip title={record.isDeactivate ? "Active" : "Deactive"}>
            <Button
              className={
                !record.isDeactivate
                  ? "action-grp-deactive-btn"
                  : "action-grp-active-btn"
              }
              icon={
                <i
                  className={
                    !record.isDeactivate ? "icon-ok-1" : "icon-cancel-1"
                  }
                />
              }
              onClick={() =>
                handleClickActive(record.id!.toString(), record.isDeactivate)
              }
            >
              {!record.isDeactivate ? (
                <span className="active">Activate</span>
              ) : (
                <span className="deactive">Deactivate</span>
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Link
              to={ROUTE_PATHS.HostConfigEdit.replace(
                ":id",
                record.id!.toString()
              )}
            >
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={
                <i
                  className="icon-delete"
                  onClick={() => handleClickDelete(record.id!.toString())}
                />
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <div className="host-configs">
      <Row>
        <Col className="table-info" span={12}>
          <Row className="h4-semiBold">Host Configs</Row>
          <Row className="body-regular">{totalRecords} host configs</Row>
        </Col>
        <Col span={12} className="table-cmp">
          <Link to={ROUTE_PATHS.HostConfigCreate}>
            <Button
              className="btn-create btn-base btn-main-base"
              icon={<i className="icon-setting"/>}
            >
              {t("hostConfig.btn_add_new_host")}
            </Button>
          </Link>
          <Search
            className="search-cmp"
            placeholder="Search"
            onSearch={onSearch}
            size="large"
            style={{ width: 400 }}
          />
        </Col>
        <Col span={24}>
          <Row>
            <Col span={24}>
              <Table
                rowKey={x => x.id}
                loading={isLoading}
                className="table-host-config"
                columns={hostConfigColumns}
                dataSource={hostConfigs}
                scroll={tableScroll}
                pagination={{
                  pageSize: pageSize,
                  current: pageNumber,
                  style: { display: "none" },
                }}
              />
            </Col>
            <PaginationComponent
              totalRecords={totalRecords}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              handleGetFilter={handleGetHostConfigs}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default HostConfigManagement;
