import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KeysApi } from "../../../../apis/keys.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import {
  HSMKeysModelDisplay,
  KeyTemplateModelDisplay,
} from "../../../../models/keys";
import DateUtils from "../../../../utils/date.utils";
import showConfirmModal from "../../../common/modal-confirm";
import showNotification from "../../../common/notification";

function KeyTemplatesManagementForm() {
  const { t } = useTranslation();
  const [keysTemplateList, setKeysTemplateList] = useState<
    KeyTemplateModelDisplay[] | any[]
  >([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetAllHSMKey();
  }, []);

  const handleGetAllHSMKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await KeysApi.getAllKeyTemplates();
      setKeysTemplateList(data);
      setIsLoading(false);
    } catch (error) {}
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await KeysApi.deleteKeyTemplate(id);

        if (deleteResponse) {
          showNotification("success", "Deleted Key successfully.");
          await handleGetAllHSMKey();
        }
      } catch (error) {}
    };
    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this Key?`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const columns: ColumnsType<HSMKeysModelDisplay> = [
    {
      title: t("key.col_title.no"),
      key: "no",
      render: (text: string, record: any) => <>{record.id}</>,
    },
    {
      title: t("key.col_title.name"),
      key: "Name",
      render: (text: string, record: any) => (
        <>
          <Col>{record.name}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.number_of_keys"),
      key: "numberOfKeys",
      render: (text: string, record: any) => <Col>{record.numberOfKeys}</Col>,
    },
    {
      title: t("key.col_title.creation_time"),
      key: "creation-time",
      render: (text: string, record: any) => (
        <Col>{DateUtils.convertUTCToLocal(record.createdOn)}</Col>
      ),
    },
    {
      key: "6",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Link
            to={ROUTE_PATHS.KeyTemplatesEdit.replace(
              ":id",
              record.id!.toString()
            )}>
            <Tooltip title="Edit">
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Tooltip>
          </Link>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={<i className="icon-delete" />}
              onClick={() => handleClickDelete(record.id!.toString())}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Content className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t("key.title.key_template")}</Row>
          <Row className="body-regular">{keysTemplateList.length} {t("key.keys")}</Row>
        </Col>
        <Col span={12}>
          <Link to={ROUTE_PATHS.KeyTemplatesCreate}>
            <Button
              className="btn-create tbn-base btn-main-base"
              htmlType="button"
              type="primary"
              icon={<KeyOutlined />}>
              {t("key.button.btn_add_key_template")}
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowKey={(x) => x.id}
        loading={isLoading}
        className="tb-list-user"
        columns={columns}
        dataSource={keysTemplateList}
        pagination={{
          style: { display: "none" },
        }}
      />
    </Content>
  );
}
export default KeyTemplatesManagementForm;
