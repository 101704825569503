import { LOCAL_STORAGE_KEY, LOCAL_UI_STORAGE_VALUE, SESSION_TIMEOUT_CONFIG } from "@/constants/app-constants";
import { APP_STATE_KEYS } from "@/constants/app-state.constants";
import { UISettingModel } from "@/models/system-config";
import LocalUtils from "@/utils/local.utils";
import { atom } from "recoil";

const configString = LocalUtils.get(LOCAL_STORAGE_KEY.SYSTEM_CONFIG_DATA)
const configObj: UISettingModel = configString ? JSON.parse(configString) : {
  uiBrowserTitle: LOCAL_UI_STORAGE_VALUE.BROWSER_TITLE,
  uiLogoImage: LOCAL_UI_STORAGE_VALUE.LOGO_GATEWAY_PATH,
  uiWelcomeImage: LOCAL_UI_STORAGE_VALUE.WELCOME_IMAGE_PATH,
  inactivityLogout: SESSION_TIMEOUT_CONFIG.sessionWarningTime
};

const uiSettingState = atom<UISettingModel>({
  key: APP_STATE_KEYS.UI_SETTTING_STATE,
  default: {
    uiBrowserTitle: configObj.uiBrowserTitle ? configObj.uiBrowserTitle : LOCAL_UI_STORAGE_VALUE.BROWSER_TITLE,
    uiLogoImage: configObj.uiLogoImage ? configObj.uiLogoImage : LOCAL_UI_STORAGE_VALUE.LOGO_GATEWAY_PATH,
    uiWelcomeImage: configObj.uiWelcomeImage ? configObj.uiWelcomeImage : LOCAL_UI_STORAGE_VALUE.WELCOME_IMAGE_PATH,
    inactivityLogout: configObj.inactivityLogout ? configObj.inactivityLogout : SESSION_TIMEOUT_CONFIG.sessionWarningTime
  }
});

export { uiSettingState };

