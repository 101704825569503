import { Alert, Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import "./chart.scss";

function TrafficPieChart() {
  const [isLoading, setLoading] = useState(false);
  const [chartData, setChartData] = useState({
    options: {
      series: [44, 55, 13, 33],
      labels: ["United States", "Canada", "Mexico", "Other"],
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        pie: {
          expandOnClick: false,
          customScale: 0.8,
          donut: {
            size: "70%",
            borderRadius: 6,
          },
        },
      },
      colors: ["#BAEDBD", "#B1E3FF", "#5733FF", "#1C1C1C"],
      chart: {
        toolbar: {
          show: false,
        },
        width: 60,
      },
      legend: {
        position: "right" as "right",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: "bottom"
            }
          }
        }
      ]
    },
  });

  useEffect(() => {
    // Simulating data fetching delay
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Delay 2s (simulating async data fetching)
  }, []);

  return (
    <>
      {isLoading && (
        <div className="loading">
          <Spin tip="Loading...">
            <Alert description="Loading Chart..." type="info" />
          </Spin>
        </div>
      )}
      {!isLoading && (
        <div className="chart-bottom-content">
          <ReactApexChart
            options={chartData.options}
            series={chartData.options.series}
            type="donut"
          />
        </div>
      )}
    </>
  );
}

export default TrafficPieChart;
