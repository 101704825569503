import LocalUtils from "@/utils/local.utils";
import { Button, Col, DatePicker, Divider, Form, Input, Row, Spin } from "antd";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { UserApi } from "../../../../apis/user.api";
import {
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { UserModelInf } from "../../../../models/user";
import DateUtils from "../../../../utils/date.utils";
import UpdateUserEmailPopup from "../../../common/modal-mfa/modal-email";
import UpdateUserPhonePopup from "../../../common/modal-mfa/modal-phone";
import showNotification from "../../../common/notification";

interface TabInfomationProps {
  userInfo: UserModelInf | undefined;
}

function TabInfomation(props: TabInfomationProps) {
  const { t } = useTranslation();
  const [infomationForm] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [user, setUser] = useState<UserModelInf>();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenUpdateUserEmailPopup, setIsOpenUpdateUserEmailPopup] =
    useState(false);
  const [isOpenUpdateUserPhonePopup, setIsOpenUpdateUserPhonePopup] =
    useState(false);

  const fetchData = async () => {
    setIsLoading(true);

    try {
      const responseData = await handlerGetUserById(
        props.userInfo?.id ?? DefaultGUID
      );
      infomationForm.setFieldsValue({
        firstName: responseData?.firstName,
        surname: responseData?.surname,
        middleName: responseData?.middleName,
        perferredName: responseData?.perferredName,
        personalIdNumber: responseData?.personalIdNumber,
        email: responseData?.email,
        birthday: DateUtils.isoDate(
          responseData?.birthdayString ?? "2023-01-01T11:29:26.0000000"
        ),
        cellNumber: responseData?.cellNumber,
        cellCountryCode: responseData?.cellCountryCode,
        cellDialCode: responseData?.cellDialCode,
        roleId: responseData?.roleId,
      });

      setUser(responseData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (props.userInfo?.id != null) {
      fetchData();
    }
  }, []);

  const dateFormat = "YYYY-MM-DD";

  const onCloseUpdateUserEmailPopup = (isReload: boolean) => {
    setIsOpenUpdateUserEmailPopup(false);
    isReload && fetchData();
  };

  const onCloseUpdateUserPhonePopup = (isReload: boolean) => {
    setIsOpenUpdateUserPhonePopup(false);
    isReload && fetchData();
  };

  async function handlerGetUserById(id: string): Promise<UserModelInf> {
    const response = await UserApi.getUserInfById(id);
    return response.data;
  }

  const onSubmit = () => {
    infomationForm.submit();
  };

  const handleChangePhoneInput = (
    value: any,
    data: any,
    event: React.FocusEvent<HTMLInputElement>,
    formattedValue: any
  ) => {
    data?.countryCode &&
      infomationForm.setFieldValue(
        "cellCountryCode",
        data.countryCode.toUpperCase()
      );
    data?.dialCode &&
      infomationForm.setFieldValue("cellDialCode", data.dialCode);
  };

  async function handlePostUser(values: any) {
    setLoadingBtn(true);
    try {
      const userRequest: UserModelInf = {
        id: props.userInfo?.id ?? DefaultGUID,
        isDeactivate: values.isDeactivate,
        personalIdNumber: values.personalIdNumber,

        firstName: values.firstName,
        perferredName: values.perferredName,
        middleName: values.middleName,
        surname: values.surname,
        email: values.email,

        avatarUpload: values.profilePicture,

        birthdayString: values.birthday,
        cellNumber: values.cellNumber,
        cellCountryCode: values.cellCountryCode,
        cellDialCode: values.cellDialCode,
        roleId: values.roleId,
      };

      const { data } = await UserApi.putProfileUserInf(userRequest);

      if (data) {
        LocalUtils.setUserInfo(data);

        showNotification("success", t("user.notiUpdateUserProfileSucces"));
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleTabInformation() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userInf_set")}</div>
              <div className="body-regular ">{t("title.userInf_set")}</div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onSubmit}
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <TitleTabInformation />
      <Form
        name="infomationForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        form={infomationForm}
        onFinish={handlePostUser}
        className="form-submit-information"
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="firstName"
              label={t("user.firstName")}
              messageVariables={{ label: "First Name" }}
              rules={[{ required: true }]}
            >
              <Input className="input-base" placeholder={t("user.firstName")} />
            </Form.Item>

            <Form.Item
              name="middleName"
              label={t("user.middleName")}
              messageVariables={{ label: "Middle Name" }}
            >
              <Input
                className="input-base"
                placeholder={t("user.middleName")}
              />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email"
              messageVariables={{ label: "Email" }}
            >
              <Input
                className="input-with-addon"
                addonAfter={
                  <Button
                    type="primary"
                    className="addon-btn"
                    onClick={() => setIsOpenUpdateUserEmailPopup(true)}
                  >
                    Change
                  </Button>
                }
                placeholder="Email"
                disabled
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              label={t("user.lastName")}
              name="surname"
              messageVariables={{ label: "Last Name" }}
              rules={[{ required: true }]}
            >
              <Input className="input-base" placeholder={t("user.lastName")} />
            </Form.Item>

            <Form.Item
              label={t("user.birthday")}
              name="birthday"
              messageVariables={{ label: "Birthday" }}
              getValueProps={(i) => ({ value: i ? dayjs(i) : null })}
              rules={[{ required: true }]}
            >
              <DatePicker
                showToday={false}
                format={dateFormat}
                className="datepicker-input input-base"
              />
            </Form.Item>

            <Form.Item
              label="cellCountryCode"
              name="cellCountryCode"
              hidden={true}
            >
              <Input type="text" />
            </Form.Item>

            <Form.Item label="cellDialCode" name="cellDialCode" hidden={true}>
              <Input type="text" />
            </Form.Item>

            <div className="cur-addon-btn">
              <Form.Item
                name="cellNumber"
                className="phone-number"
                label="Phone"
              >
                <PhoneInput
                  disabled
                  enableSearch
                  country={"us"}
                  searchPlaceholder={t("user.searchNumberPlaceholder")}
                  onChange={handleChangePhoneInput}
                />
              </Form.Item>
              <Button
                type="primary"
                onClick={() => setIsOpenUpdateUserPhonePopup(true)}
              >
                Change
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <UpdateUserEmailPopup
        visible={isOpenUpdateUserEmailPopup}
        onClose={onCloseUpdateUserEmailPopup}
        email={user?.email ?? ""}
        userId={props.userInfo?.id ?? ""}
      />
      <UpdateUserPhonePopup
        visible={isOpenUpdateUserPhonePopup}
        onClose={onCloseUpdateUserPhonePopup}
        cellNumber={user?.cellNumber ?? ""}
        cellCountryCode={user?.cellCountryCode ?? ""}
        cellDialCode={user?.cellDialCode ?? ""}
        userId={props.userInfo?.id ?? ""}
      />
    </Spin>
  );
}

export default TabInfomation;
