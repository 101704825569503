import { Modal } from 'antd';
import {
  useEffect,
  useRef,
  useState,
} from 'react';
import { useNavigate } from "react-router-dom";
import { SESSION_TIMEOUT_CONFIG } from '../../../constants/app-constants';
import { ROUTE_PATHS } from '../../../constants/router.constants';
import LocalUtils from '../../../utils/local.utils';
import { useRecoilValue } from 'recoil';
import { uiSettingState } from '@/states/uiSetting';

const SessionTimeout = () => {
  const [second, setSecond] = useState(0);
  const [minute, setMinute] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const isCountDownStart = useRef(false);
  const navigate = useNavigate();
  const uiSetting = useRecoilValue(uiSettingState);

  let warningInactiveInterval = useRef<undefined | ReturnType<typeof setInterval>>();
  let startTimerInterval = useRef<undefined | ReturnType<typeof setTimeout>>();

  // start inactive check
  let timeWarning = uiSetting.inactivityLogout ? uiSetting.inactivityLogout - SESSION_TIMEOUT_CONFIG.countDownTime : SESSION_TIMEOUT_CONFIG.sessionWarningTime - SESSION_TIMEOUT_CONFIG.countDownTime;
  
  let timeChecker = () => {
    startTimerInterval.current = setTimeout(() => {
      warningInactive();
    }, timeWarning);
  };

  // warning timer
  let warningInactive = () => {
    clearTimeout(startTimerInterval.current);
    let currentTimeCountDown = SESSION_TIMEOUT_CONFIG.countDownTime;
    warningInactiveInterval.current = setInterval(() => {
      if (currentTimeCountDown > 0) {
        currentTimeCountDown = currentTimeCountDown - 1000;
        setSecond(Math.floor((currentTimeCountDown / 1000) % 60));
        setMinute(Math.floor(currentTimeCountDown / 1000 / 60))
        isCountDownStart.current = true;
        setIsOpen(true)
      }
      else {
        clearInterval(warningInactiveInterval.current);
        isCountDownStart.current = false;
        setIsOpen(false)
        Object.values(SESSION_TIMEOUT_CONFIG.actionList).forEach((event) => {
          window.removeEventListener(event, resetTimer);
        });
        //logout();
        LocalUtils.clear();
        navigate(ROUTE_PATHS.SessionTimedOut, { replace: true, state: { Title: SESSION_TIMEOUT_CONFIG.sessionWarningContentData.Title, Content: SESSION_TIMEOUT_CONFIG.sessionWarningContentData.Content }});
        navigate(0);
      }
    }, 1000);
  };

  // reset interval timer
  const resetTimer = () => {
    if (!isCountDownStart.current) {
      clearTimeout(startTimerInterval.current);
      clearInterval(warningInactiveInterval.current);
      timeChecker();
      setIsOpen(false)
    }
  };

  // handle popup
  const handleContinueSession = () => {
    clearTimeout(startTimerInterval.current);
    clearInterval(warningInactiveInterval.current);
    timeChecker();
    isCountDownStart.current = false;
    setIsOpen(false)
  };

  useEffect(() => {
    Object.values(SESSION_TIMEOUT_CONFIG.actionList).forEach((event) => {
      window.addEventListener(event, resetTimer);
    });
  }, []);


  if (!isOpen) {
    return null;
  }
  // change fragment to modal and handleclose func to close
  return <div>
    <Modal
      open={isOpen}
      closable={false}
      title="Are you still there?"
      onOk={handleContinueSession}
      okText="Continue session"
      footer={(_, { OkBtn }) => (
        <>
          <OkBtn />
        </>
      )}
      >
      {`Your session will be expired automatically in ${second} seconds`}
    </Modal>
  </div>;
};

export default SessionTimeout;