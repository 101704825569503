import { Layout, Row, Tabs, TabsProps } from "antd";

import { useTranslation } from "react-i18next";
import BreadcrumbComponent from "../../common/breadcrumb";
import OperationLog from "./components/operation-log/operation-log";
import SystemLog from "./components/system-log/system-log";
import "./index.scss";
import TransactionLog from "./components/transaction-log/transaction-log";

function AuditLogForm() {
  const { t } = useTranslation();
  const breadcrumbItems = ["Audit Log", "Home", "Audit Log"];
  const onChange = (key: string) => {
  };

  const items: TabsProps["items"] = [
    {
      key: "OperationLog",
      label: (
        <>
          <Row className="h5-semiBold">{t("operation.operationLog")}</Row>
          <Row className="body-regular">{t("operation.operationLogDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <OperationLog />,
      
    },
    {
      key: "SystemLog",
      label: (
        <>
          <Row className="h5-semiBold">{t("operation.sysLog")}</Row>
          <Row className="body-regular">{t("operation.sysLogDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <SystemLog />,
    },
    {
      key: "TransactionLog",
      label: (
        <>
          <Row className="h5-semiBold">{t("operation.transLog")}</Row>
          <Row className="body-regular">{t("operation.transLogDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <TransactionLog />,
    },
  ];

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="container-tabs">
        <Tabs
          defaultActiveKey="1"
          items={items}
          onChange={onChange}
          className="container"
          destroyInactiveTabPane={true}
        />
      </div>
    </Layout>
  );
}

export default AuditLogForm;
