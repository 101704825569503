import { UserAddOutlined } from "@ant-design/icons";
import { Badge, Button, Col, Row, Space, Table, Tag, Tooltip } from "antd";
import Search from "antd/es/input/Search";
import Layout from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { RoleApi } from "../../../apis/role.api";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import {
  ApiPermissionModelAllowed,
  RoleModelDisplay,
} from "../../../models/role";
import DateUtils from "../../../utils/date.utils";
import BreadcrumbComponent from "../../common/breadcrumb";
import showConfirmModal from "../../common/modal-confirm";
import showNotification from "../../common/notification";
import PaginationComponent from "../../common/pagination/component/pagination";
import "./role.scss";

function UserRolesForm() {
  const { t } = useTranslation();
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [roles, setRoles] = useState<RoleModelDisplay[] | []>([]);
  const [isLoading, setIsLoading] = useState(true);
  const breadcrumbItems = ["User Roles", "Home", "User Roles"];

  useEffect(() => {
    handleGetAllRolesFilter();
  }, []);

  const tableScroll = {
    x: "100%",
  };

  const roleColumns: ColumnsType<RoleModelDisplay> = [
    {
      title: t("rolePermision.roleName"),
      className: "col-role-name",
      render: (text: string, record: any) => <>{record.roleName}</>,
    },
    {
      title: t("rolePermision.rolePermision"),
      className: "col-role-permission",
      width: "25%",
      dataIndex: "apiPermissionModelAllowed",
      render: (text: string, record: RoleModelDisplay) => (
        <>
          <Space
            size={[0, 8]}
            wrap>
            {
              <Space
                size={[0, 8]}
                wrap>
                {sortPermissionsByHttpMethod(
                  record.apiPermissionModelAllowed ?? []
                ).map((permission) => (
                  <Tag color={getColorByHttpMethod(permission.httpMethod)}>
                    {permission.name}
                  </Tag>
                ))}
              </Space>
            }
          </Space>
        </>
      ),
    },
    {
      title: t("user.status"),
      className: "col-status",
      dataIndex: "isDeactivate",
      render: (isDeactivate) => {
        return (
          <>
            {isDeactivate && (
              <Badge
                status="error"
                text={t("sysConfig.button.deactive")}
              />
            )}
            {!isDeactivate && (
              <Badge
                status="success"
                text={t("sysConfig.button.active")}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("sysConfig.userPermission.date_created"),
      className: "col-date",
      render: (text: string, record: any) => (
        <Col>{DateUtils.convertUTCToLocal(record.createdOn)}</Col>
      ),
    },
    {
      key: "action",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title={record.isDeactivate ? "Active" : "Deactive"}>
            <Button
              className={
                !record.isDeactivate
                  ? "action-grp-deactive-btn"
                  : "action-grp-active-btn"
              }
              icon={
                <i
                  className={
                    !record.isDeactivate ? "icon-ok-1" : "icon-cancel-1"
                  }
                />
              }
              onClick={() =>
                handleClickActive(record.id!.toString(), record.isDeactivate)
              }>
              {!record.isDeactivate ? (
                <span className="active">{t("sysConfig.button.active")}</span>
              ) : (
                <span className="deactive">{t("sysConfig.button.deactive")}</span>
              )}
            </Button>
          </Tooltip>
          <Tooltip title="Edit">
            <Link
              to={ROUTE_PATHS.UserRolesEdit.replace(
                ":id",
                record.id!.toString()
              )}>
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={
                <i
                  className="icon-delete"
                  onClick={() => handleClickDelete(record.id!.toString())}
                />
              }
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  const handleGetAllRolesFilter = async (
    pageSizeData?: number,
    pageNumberData?: number,
    searchString?: string
  ) => {
    pageSizeData = pageSizeData || pageSize;
    pageNumberData = pageNumberData || 1;
    searchString = searchString || "";
    setIsLoading(true);
    try {
      const { data } = await RoleApi.getAllRolesFilter(
        pageNumberData,
        pageSizeData,
        searchString
      );
      setRoles(data.records);
      setIsLoading(false);
      setTotalRecords(data.totalRecords);
    } catch (error) {}
  };

  function sortPermissionsByHttpMethod(
    apiPermissionModelAllowed: ApiPermissionModelAllowed[]
  ): ApiPermissionModelAllowed[] {
    return apiPermissionModelAllowed.sort((a, b) => {
      const httpMethodOrder: Record<string, number> = {
        GET: 1,
        POST: 2,
        PUT: 3,
        PATCH: 4,
        DELETE: 5,
        HEAD: 6,
        OPTIONS: 7,
      };

      const aOrder = httpMethodOrder[a.httpMethod] || Infinity;
      const bOrder = httpMethodOrder[b.httpMethod] || Infinity;

      return aOrder - bOrder;
    });
  }

  const handleClickActive = (id: string, isDeactivate?: boolean): void => {
    let title = isDeactivate ? "Active" : "Deactive";

    const handleConfirm = async () => {
      try {
        let { data: activeResponse } = await RoleApi.activeRole(
          id,
          isDeactivate
        );
        if (activeResponse) {
          showNotification("success", "Updated role successfully.");
          await handleGetAllRolesFilter();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "warning",
      title: `${title} this role now`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await RoleApi.deleteRole(id);

        if (deleteResponse) {
          showNotification("success", "Deleted role successfully.");
          await handleGetAllRolesFilter();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this role now`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const getColorByHttpMethod = (httpMethod: string): string => {
    switch (httpMethod.toUpperCase()) {
      case "GET":
        return "orange";
      case "POST":
        return "blue";
      case "PUT":
        return "green";
      case "PATCH":
        return "cyan";
      case "DELETE":
        return "red";
      case "HEAD":
        return "volcano";
      case "OPTIONS":
        return "geekblue";
      default:
        return "black";
    }
  };

  const onSearch = (value: any) => {
    handleGetAllRolesFilter(undefined, undefined, value);
  };

  const onTableChange = async (pagination: any) => {
    const { current: pageNumber, pageSize } = pagination;
    setPageNumber(pageNumber);
    setPageSize(pageSize);
    await handleGetAllRolesFilter(pageSize, pageNumber);
  };

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="ctn-user-index">
        <Row>
          <Col
            className="table-info"
            span={12}>
            <Row className="h4-semiBold">{t("menuDashboard.userRoles")}</Row>
            <Row className="body-regular">{totalRecords} {t("title.roles")}</Row>
          </Col>
          <Col
            span={12}
            className="table-cmp">
            <Link to={ROUTE_PATHS.UserRolesCreate}>
              <Button
                className="btn-create btn-base btn-main-base"
                icon={<UserAddOutlined />}>
                {t("user.btn_add_new_role")}
              </Button>
            </Link>
            <Search
              className="search-cmp"
              placeholder="Search"
              onSearch={onSearch}
              size="large"
              style={{ maxWidth: 400 }}
            />
          </Col>
          <Col span={24}>
            <Table
              loading={isLoading}
              className="tb-list-role"
              columns={roleColumns}
              dataSource={roles}
              scroll={tableScroll}
              onChange={onTableChange}
              pagination={{
                pageSize: pageSize,
                current: pageNumber,
                style: { display: "none" },
              }}
            />
          </Col>
          <PaginationComponent
            totalRecords={totalRecords}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            handleGetFilter={handleGetAllRolesFilter}
          />
        </Row>
      </div>
    </Layout>
  );
}

export default UserRolesForm;
