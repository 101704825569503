import { KeyOutlined } from '@ant-design/icons';
import { Button, Col, Row, Space, Table, Tooltip } from 'antd';
import { Content } from 'antd/es/layout/layout';
import { ColumnsType } from 'antd/es/table';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { KeysApi } from '../../../../apis/keys.api';
import { ROUTE_PATHS } from '../../../../constants/router.constants';
import { KeysEnums } from '../../../../models/key-enums';
import { HostKey } from '../../../../models/keys';
import { CommonUtils } from '../../../../utils/common';
import showConfirmModal from '../../../common/modal-confirm/index';
import showNotification from '../../../common/notification/index';

function HostKeysManagementForm() {
  const { t } = useTranslation();
  const [HostKeys, setHostKeys] = useState<HostKey[] | any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    handleGetAllHostKey();
  }, []);

  const handleGetAllHostKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await KeysApi.getAllHostKeys();
      setHostKeys(data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await KeysApi.deleteHostKey(id);

        if (deleteResponse) {
          showNotification('success', 'Deleted Key successfully.');
          await handleGetAllHostKey();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
    };

    showConfirmModal({
      type: 'delete',
      title: `Delete this Key?`,
      content: t('modal.confirm_text'),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const columns: ColumnsType<HostKey> = [
    {
      title: t('key.col_title.name'),
      key: 'Name',
      render: (text: string, record: any) => (
        <>
          <Col>{record.name}</Col>
        </>
      ),
    },
    {
      title: t('key.col_title.host_config_name'),
      key: 'hostConfigKeyName',
      render: (text: string, record: any) => (
        <>
          <Col>{record.hostConfigKeyName}</Col>
        </>
      ),
    },
    {
      title: t('key.col_title.key_mode'),
      key: 'keyMode',
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyMode,
            KeysEnums.KeyModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t('key.col_title.kcv'),
      key: 'kcv',
      render: (text: string, record: any) => (
        <>
          <Col>{record.name}</Col>
        </>
      ),
    },
    {
      title: t('key.col_title.encryption_mode'),
      key: 'encryptionMode',
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.encryptionMode,
            KeysEnums.EncryptionModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t('key.col_title.keyAlgorithm'),
      key: 'keyAlgorithm',
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyAlgorithm,
            KeysEnums.EncryptionModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t('key.col_title.remark'),
      key: 'remark',
      render: (text: string, record: any) => <Col>{record.remark}</Col>,
    },
    {
      key: '6',
      className: 'col-action',
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title="Edit">
            <Link
              to={ROUTE_PATHS.HostKeysEdit.replace(
                ':id',
                record.id!.toString()
              )}>
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={<i className="icon-delete" />}
              onClick={() => handleClickDelete(record.id!.toString())}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <Content className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t('key.title.key_host')}</Row>
          <Row className="body-regular">{HostKeys.length} {t('key.keys')}</Row>
        </Col>
        <Col span={12}>
          <Link to={ROUTE_PATHS.HostKeysCreate}>
            <Button
              className="btn-create tbn-base btn-main-base"
              htmlType="button"
              type="primary"
              icon={<KeyOutlined />}>
              {t('key.button.btn_add_host_key')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowKey={(x) => x.id}
        loading={isLoading}
        className="tb-list-user"
        columns={columns}
        dataSource={HostKeys}
        pagination={{
          style: { display: 'none' },
        }}
      />
    </Content>
  );
}
export default HostKeysManagementForm;
