import { AxiosResponse } from "axios";
import { PaginationResponseModel } from "../models/pagination-response-model";
import {
  MFAModel,
  UpdateUserEmailRequest,
  UpdateUserPhoneRequest,
  UserModelContact,
  UserModelDisplay,
  UserModelInf,
  UserRestPasscode
} from "../models/user";
import {
  deleteAsync,
  getAsync,
  postAsync,
  postFormDataAsync,
  putAsync,
  putFormDataAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Auth}/v1`;

function getAllUsers(): Promise<AxiosResponse<UserModelDisplay[]>> {
  const url = `${baseUrl}/user`;
  return getAsync(url);
}

function getAllUsersFilter(
  pageIndex: number,
  pageSize: number,
  searchString: string
): Promise<AxiosResponse<PaginationResponseModel<UserModelDisplay>>> {
  const url = `${baseUrl}/user?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${searchString}`;
  return getAsync(url);
}

function getUserInfById(id: string): Promise<AxiosResponse<UserModelInf>> {
  const url = `${baseUrl}/user/inf/${id}`;
  return getAsync(url, undefined, false, false);
}

function getMyAccountById(
  id: string
): Promise<AxiosResponse<UserModelInf>> {
  const url = `${baseUrl}/user/my-account/${id}`;
  return getAsync(url, undefined, false, false);
}

function getMFAById(
  id: string
): Promise<AxiosResponse<MFAModel>> {
  const url = `${baseUrl}/user/mfa-require/${id}`;
  return getAsync(url, undefined, false, false);
}

function updateMFAById(
  model: MFAModel
): Promise<AxiosResponse<MFAModel>> {
  const url = `${baseUrl}/user/mfa-require/`;
  return putFormDataAsync(url, model, false, false);
}

function updateProfileAvatar(
  avatarUpload: any
): Promise<AxiosResponse<string>> {
  const url = `${baseUrl}/user/avatar-upload`;
  const formData = new FormData();
  formData.append('avatarUpload', avatarUpload);
  return putAsync(url, formData, true, true);
}

function putUserInf(model: UserModelInf): Promise<AxiosResponse<UserModelInf>> {
  const url = `${baseUrl}/user/inf`;
  return putFormDataAsync(url, model, true, true);
}

function putProfileUserInf(
  model: UserModelInf
): Promise<AxiosResponse<UserModelInf>> {
  const url = `${baseUrl}/user/profile-inf`;
  return putFormDataAsync(url, model, true, true);
}

function putProfileUserContact(
  model: UserModelContact
): Promise<AxiosResponse<UserModelContact>> {
  const url = `${baseUrl}/user/profile-contact`;
  return putFormDataAsync(url, model, true, true);
}

function createUserInf(
  model: UserModelInf
): Promise<AxiosResponse<UserModelInf>> {
  const url = `${baseUrl}/user/inf`;
  return postFormDataAsync(url, model, true, true);
}

function getUserContactById(
  id: string
): Promise<AxiosResponse<UserModelContact>> {
  const url = `${baseUrl}/user/contact/${id}`;
  return getAsync(url, undefined, false, false);
}

function putUserContact(
  model: UserModelContact
): Promise<AxiosResponse<UserModelContact>> {
  const url = `${baseUrl}/user/contact`;
  return putFormDataAsync(url, model, true, true);
}

function activeUser(id: string, isDeactive?: boolean) {
  const url = `${baseUrl}/user/active/${id}/${isDeactive}`;
  return postAsync(url, undefined, true, true);
}

function userResetPasscode(model: UserRestPasscode) {
  const url = `${baseUrl}/user/reset-passcode`;
  return postAsync(url, model, true, true, false);
}

function deleteUser(id: string) {
  const url = `${baseUrl}/user/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function updateUserEmail(model: UpdateUserEmailRequest): Promise<AxiosResponse<string>>  {
  const url = `${baseUrl}/user/change-mail/`;
  return putFormDataAsync(url, model, false, false);
  
}

function updateUserPhone(model: UpdateUserPhoneRequest): Promise<AxiosResponse<string>>  {
  const url = `${baseUrl}/user/change-cell-phone/`;
  return putFormDataAsync(url, model, false, false);
}

export const UserApi = {
  getAllUsers,
  putUserInf,
  getUserInfById,
  activeUser,
  deleteUser,
  putUserContact,
  getUserContactById,
  createUserInf,
  getAllUsersFilter,
  userResetPasscode,
  updateUserEmail,
  updateUserPhone,
  putProfileUserContact,
  putProfileUserInf,
  updateProfileAvatar,
  getMyAccountById,
  getMFAById,
  updateMFAById
};
