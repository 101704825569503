import { UserOutlined } from "@ant-design/icons";
import { Avatar, Badge, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { RoleApi } from "../../../../../apis/role.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { UserModelDisplay } from "../../../../../models/user";
import { CommonUtils } from "../../../../../utils/common";
import DateUtils from "../../../../../utils/date.utils";

function UserByRole() {
  const { t } = useTranslation();
  const [dataUsers, setDataUsers] = useState<UserModelDisplay[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();

  useEffect(() => {
    if (id != null) {
      fetchDataUsers();
    }
    setIsLoading(false);
  }, []);

  async function fetchDataUsers() {
    try {
      const actualId = id ?? DefaultGUID;
      setDataUsers(await getUserByRole(actualId));
      setIsLoading(false);
    } catch (error) { 
    }
  }

  async function getUserByRole(id: string) {
    const { data } = await RoleApi.getUserByRole(id);
    return data;
  }

  const userColumns: ColumnsType<UserModelDisplay> = [
    {
      title: t("user.user"),
      render: (text: string, record: any) => (
        <>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            src={CommonUtils.createFileUrl(record.personalImage)}
            style={{ backgroundColor: "#4E46DC", marginRight: "7px" }}
            icon={<UserOutlined />}
          />
          {record.fullName}
        </>
      ),
    },
    {
      title: t("user.contact"),
      render: (text: string, record: any) => (
        <>
          <Row> {record.email}</Row>
          <Row> {record.cellE164Number}</Row>
        </>
      ),
    },
    {
      title: t("user.status"),
      key: "3",
      dataIndex: "isDeactivate",
      render: (isDeactivate) => {
        return (
          <>
            {isDeactivate && (
              <Badge
                status="error"
                text={t("sysConfig.button.deactive")}
              />
            )}
            {!isDeactivate && (
              <Badge
                status="success"
                text={t("sysConfig.button.active")}
              />
            )}
          </>
        );
      },
    },
    {
      title: t("user.registerTime"),
      key: "4",
      render: (text: string, record: any) => (
        <Col>
          {DateUtils.convertServerToDateTimeViewFormat(record.createdOn)}
        </Col>
      ),
    },
    {
      title: t("user.lastTimeLogin"),
      key: "5",
      render: (text: string, record: any) => (
        <Col>
          {DateUtils.convertServerToDateTimeViewFormat(record.lastActivity)}
        </Col>
      ),
    },
  ];

  return (
    <Table
      pagination={{ pageSize: 6 }}
      loading={isLoading}
      className="tb-list-user"
      columns={userColumns}
      dataSource={dataUsers}
    />
  );
}
export default UserByRole;
