import defaultWelcomeImage from "@/assets/images/PAX_Logo.svg";
import defaultLogo from "@/assets/images/PAX_Logo_Dashboard.svg";
import { ChangePasswordType } from "@/models/enums";
import i18n from "@/utils/i18n";

export const LOCAL_COOKIE_KEY = {
  ID_TOKEN: "ID_TOKEN",
  IS_LOGGED_IN: "IS_LOGGED_IN",
  ACCESS_TOKEN: "ACCESS_TOKEN",
};

export const LOCAL_STORAGE_KEY = {
  LANGUAGE: "lang",
  USER_INFO: "USER_INFO",
  IS_REMEMBER_ME: "IS_REMEMBER_ME",
  REFRESH_TOKEN: "REFRESH_TOKEN",
  GUEST_USER_INFO: "GUEST_USER_INFO",
  SYSTEM_CONFIG_DATA: "SYSTEM_CONFIG_DATA",
};

export const LOCAL_UI_STORAGE_VALUE = {
  BROWSER_TITLE: "Gateway Host",
  LOGO_GATEWAY_PATH: defaultLogo,
  WELCOME_IMAGE_PATH: defaultWelcomeImage,
};

export const VALIDATION_MESSAGE_CONFIG = {
  required: i18n.t("common.requireValidation"),
  types: {
    email: i18n.t("common.emailValidation"),
    url: i18n.t("common.urlValidation"),
  },
};

export const SESSION_TIMEOUT_CONFIG = {
  countDownTime: 15000,
  sessionWarningTime: 600000,
  actionList: ["wheel", "load", "mousemove", "mousedown", "click", "scroll", "keypress",  "touchstart"],
  sessionWarningContentData: {
    Title: "Log Out",
    Content: "Session expired, logging in from another session, password expired, or account status changed may have caused a logout."
  }
}

export const CHANGE_PASSWORD_CONFIG = [
  {
    key: ChangePasswordType.FisrtTimeLogin,
    label: i18n.t("login.changePasssTemporary"),
  },
  {
    key: ChangePasswordType.PasswordExpired,
    label: i18n.t("login.changePassExpiry"),
  },
  {
    key: ChangePasswordType.PasswordWarning,
    label: i18n.t("login.changePassExpiryWarning"),
  }
];

export const DATE_TIME_FORMAT = {
  dateTimeViewFormat: "YYYY-MM-DD HH:mm",
  dateTimeSecondViewFormat: "YYYY-MM-DD HH:mm:ss",
  dateTimeViewFormatUTC: "YYYY-MM-DDTHH:mm:ss[Z]",
  dateTimeViewFormatPM: "MMM DD, YYYY h:mma",
  viewFormat: "MMM DD, YYYY",
  dateView: "MMM D, YYYY",
  dateMonthView: "MMM D",
  monthView: "MMM YYYY",
  timeView: "h:mm:ss a",
  hourView: "h:mm a",
  isoDate: "yyyy-MM-DD",
  datePickerView: "YYYY-MM-DD",
  chartDateFormat: "MMM dd",
  rangePickerFormat: "YYYY-MM-DD HH:mm",
  rangePickerHourFormat: "HH:mm",
};

export const SCREEN_WIDTH_MEDIUM: number = 1280;
export const SCREEN_WIDTH_SMALL: number = 768;
export const SCREEN_WIDTH_XSMALL: number = 576;
export const DefaultGUID = "00000000-0000-0000-0000-000000000000";
export const DefaultDateTime = "0000-01-01T11:29:26.0000000";
export const DefaultValue = 0;

export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()-_=+\[\]{}|;:,.<>?])[A-Za-z\d!@#$%^&*()-_=+\[\]{}|;:,.<>?]{12,}$/;

export const PASSWORD_REGEX_ONE_LOWER_CASE = `(?=.*[a-z])`; // should contain at least one lower case
export const PASSWORD_REGEX_ONE_UPPER_CASE = `(?=.*[A-Z])`; // should contain at least one upper case
export const PASSWORD_REGEX_ONE_NUMBER = `(?=.*[0-9])`; // should contain at least one upper case
export const PASSWORD_REGEX_MINIMUM_12_CHARACTERS = `(?=.{12,})`; // should contain at least 12 characters
export const PASSWROD_REGEX_SPECIAL_CHARACTER = `(?=.*[!@#$%^&*])`; // should contain a special character

export const PASSWORD_POLYCIES = [
  {
    key: 1,
    label: i18n.t("common.8CharactersMinimum"),
    regex: PASSWORD_REGEX_MINIMUM_12_CHARACTERS,
    valid: false,
  },
  {
    key: 2,
    label: i18n.t("common.containSpecialCharacter"),
    regex: PASSWROD_REGEX_SPECIAL_CHARACTER,
    valid: false,
    disabled: true,
  },
  {
    key: 3,
    label: i18n.t("common.oneLowercaseCharacter"),
    valid: false,
    regex: PASSWORD_REGEX_ONE_LOWER_CASE,
  },
  {
    key: 4,
    label: i18n.t("common.oneUppercaseCharacter"),
    regex: PASSWORD_REGEX_ONE_UPPER_CASE,
    valid: false,
  },
  {
    key: 5,
    label: i18n.t("common.notEasy"),
    regex: PASSWROD_REGEX_SPECIAL_CHARACTER,
    valid: false,
    disabled: true,
  },
  {
    key: 6,
    label: i18n.t("common.notRepeatably"),
    regex: PASSWORD_REGEX_ONE_NUMBER,
    valid: false,
  },
];

export const SUPPORT_EMAIL = "gateway@project-jump-start.com";