import { Form, Input, Modal } from "antd";
import { useTranslation } from "react-i18next";
import { EncrytedKey } from "../../../../../models/keys";
import TextArea from "antd/es/input/TextArea";
import { useEffect } from "react";

export interface ModalViewDetailProps {
  visible: boolean;
  onClose: () => void;
  encryptedModel: EncrytedKey | undefined;
}

export function ModalViewDetail({
  visible,
  encryptedModel,
  onClose,
}: ModalViewDetailProps) {
  const { t } = useTranslation();
  const [viewDetailForm] = Form.useForm();

  useEffect(() => {
    viewDetailForm.setFieldsValue({
      name: encryptedModel?.name,
      keyMode: encryptedModel?.keyMode,
      hsmKeyName: encryptedModel?.hsmKeyName,
      ecryptMode: encryptedModel?.encryptionMode,
      ecryptValue: encryptedModel?.encryptedKeyValue,
      kcv: encryptedModel?.kcv,
      keyAlgorithm: encryptedModel?.keyAlgorithm,
      ksi: encryptedModel?.ksi,
      initialDID: encryptedModel?.initialDID,
      remark: encryptedModel?.remark,
    });
  });

  const handleCancel = () => {
    onClose();
  };

  function ViewDetailForm() {
    return (
      <>
        <Form.Item
          name="name"
          label={t("key.col_title.name")}
          hasFeedback>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="keyMode"
          label={t("key.col_title.key_mode")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="hsmName"
          hidden></Form.Item>
        <Form.Item
          name="hsmKeyName"
          label={t("key.col_title.hsm_key_name")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="ecryptMode"
          label={t("key.col_title.encryption_mode")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="ecryptValue"
          label={t("key.col_title.ecryptValue")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="kcv"
          label={t("key.col_title.kcv")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="keyAlgorithm"
          label={t("key.col_title.keyAlgorithm")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="ksi"
          label={t("key.col_title.ksi")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="initialDID"
          label={t("key.col_title.initialDID")}>
          <Input disabled />
        </Form.Item>
        <Form.Item
          name="remark"
          label={t("key.col_title.remark")}>
          <TextArea disabled />
        </Form.Item>
      </>
    );
  }
  return (
    <>
      <Modal
        open={visible}
        onCancel={handleCancel}
        className="modal-encrypted"
        footer={null}
        title={t("key.title.encrypted_key")}>
        <Form
          className="modal-container"
          layout="vertical"
          labelWrap
          form={viewDetailForm}>
          <ViewDetailForm />
        </Form>
      </Modal>
    </>
  );
}

export default ModalViewDetail;
