import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Layout,
  Row,
  Select,
  SelectProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Content } from "antd/es/layout/layout";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { KeysApi } from "../../../../../apis/keys.api";
import { DefaultGUID } from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import {
  EncryptionModeEnums,
  KeyAlgorithmEnums,
  KeyDisplayName,
  KeyModeEnums,
} from "../../../../../models/key-enums";
import { EncrytedKey, HSMKeysModelDisplay } from "../../../../../models/keys";
import BreadcrumbComponent from "../../../../common/breadcrumb";
import showNotification from "../../../../common/notification";
import "../index.scss";

function AddEditEncrytedKeyForm() {
  const [encryptedForm] = Form.useForm();
  const [hsmkeysListName, setHsmkeysListName] = useState<HSMKeysModelDisplay[]>(
    []
  );
  const navigate = useNavigate();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();
  const breadcrumbItems = [
    "Update Encrypted Keys",
    "Key Management",
    "Encrypted Keys",
  ];
  const checkUrl = useLocation();

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data } = await KeysApi.getEncryptedKeyById(id);
          encryptedForm.setFieldsValue({
            name: data.name,

            hsmKeyEntityId: data.hsmKeyEntityId,
            hsmKeyName: data.hsmKeyName,
            ecryptMode: data.encryptionMode,
            keyMode: data.keyMode,
            keyAlgorithm: data.keyAlgorithm,
            ecryptValue: data.encryptedKeyValue,
            kcv: data.kcv,
            ksi: data.ksi,
            initialDID: data.initialDID,
            remark: data.remark,
          });
        } catch (err) {}
      };
      fetchData();
    }
    const fetchHSMKeyName = async () => {
      const hsmKeys = await KeysApi.getAllHSMKey();
      setHsmkeysListName(hsmKeys.data);
    };
    fetchHSMKeyName();
  }, []);

  const optionsKeyMode: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyModeDisplayName[0],
      value: KeyModeEnums.None,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[1],
      value: KeyModeEnums.DUKPTbyHSMKey,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[2],
      value: KeyModeEnums.NoneUnique,
    },
    {
      label: KeyDisplayName.KeyModeDisplayName[4],
      value: KeyModeEnums.KEK,
    },
  ];

  const optionsEncryptionMode: SelectProps["options"] = [
    {
      label: KeyDisplayName.EncryptionModeDisplayName[0],
      value: EncryptionModeEnums.None,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[1],
      value: EncryptionModeEnums.ECB,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[2],
      value: EncryptionModeEnums.CBC,
    },
    {
      label: KeyDisplayName.EncryptionModeDisplayName[4],
      value: EncryptionModeEnums.TR31,
    },
  ];

  const optionsKeyAlgorithm: SelectProps["options"] = [
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[0],
      value: KeyAlgorithmEnums.None,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[1],
      value: KeyAlgorithmEnums.DES,
    },
    {
      label: KeyDisplayName.KeyAlgorithmDisplayName[2],
      value: KeyAlgorithmEnums.TDES,
    },
  ];

  async function handleUpdateEncryptedKey(values: any) {
    setLoadingBtn(true);
    try {
      var req: EncrytedKey = {
        id: id ?? DefaultGUID,
        name: values.name,
        keyMode: values.keyMode,
        encryptionMode: values.ecryptMode,
        keyAlgorithm: values.keyAlgorithm,
        encryptedKeyValue: values.ecryptValue,
        kcv: values.kcv,
        hsmKeyEntityId: values.hsmKeyEntityId,

        ksi: values.ksi,
        initialDID: values.initialDID,
        remark: values.remark,
      };

      const result =
        checkUrl.pathname === ROUTE_PATHS.EncryptedKeysCreate
          ? await KeysApi.createEncryptedKey(req)
          : await KeysApi.updateEncryptedKey(req);

      showNotification("success", "Updated Encrypted Key successfully.");
      setLoadingBtn(false);
      if (result) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.EncryptedKeys);
        }, 1000);
      }
    } catch (err) {}
  }

  const onSubmit = () => {
    encryptedForm.submit();
  };

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Content className="ctn-user-index">
        <Form
          className="edit-container"
          layout="vertical"
          labelWrap
          form={encryptedForm}
          onFinish={handleUpdateEncryptedKey}>
          <Row gutter={16}>
            <Col
              sm={18}
              xs={14}
              lg={12}>
              <div className="head-form">
                <div className="h4-semiBold">{t("key.encrypted_key.title")}</div>
                <div className="body-regular ">{t("key.encrypted_key.title")}</div>
              </div>
            </Col>
            <Col
              sm={6}
              xs={10}
              lg={12}>
              <Form.Item>
                <div className="btn-grp">
                  <Link to={ROUTE_PATHS.EncryptedKeys}>
                    <Button className="btn-back btn-base btn-secondary-base">
                      {t("sysConfig.button.back")}
                    </Button>
                  </Link>
                  <Button
                    className="btn-sumbit btn-base btn-main-base"
                    loading={loadingBtn}
                    onClick={onSubmit}>
                    {t("sysConfig.button.save")}
                  </Button>
                </div>
              </Form.Item>
            </Col>
          </Row>
          <Divider />
          <Row>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="name"
                label={t("key.col_title.name")}
                hasFeedback
                rules={[{ required: true, message: "Name is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.name")}
                />
              </Form.Item>
              <Form.Item
                name="keyMode"
                label={t("key.col_title.key_mode")}
                rules={[{ required: true, message: "Key Mode is required" }]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.key_mode")}
                  options={optionsKeyMode}
                />
              </Form.Item>
              <Form.Item
                name="hsmKeyEntityId"
                hidden></Form.Item>
              <Form.Item
                name="hsmKeyName"
                label={t("key.col_title.hsm_key_name")}
                rules={[
                  { required: true, message: "HSM Key Name is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.hsm_key_name")}
                  onSelect={(value) =>
                    encryptedForm.setFieldValue("hsmKeyEntityId", value)
                  }
                  options={hsmkeysListName.map((hsm) => ({
                    label: hsm.name,
                    value: hsm.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="ecryptMode"
                label={t("key.col_title.encryption_mode")}
                rules={[
                  { required: true, message: "Encryption Key is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder={t("key.col_title.encryption_mode")}
                  options={optionsEncryptionMode}
                />
              </Form.Item>
              <Form.Item
                name="ecryptValue"
                label={t("key.col_title.ecryptValue")}
                rules={[
                  { required: true, message: "Encryption Value is required" },
                ]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.ecryptValue")}
                />
              </Form.Item>
            </Col>
            <Col
              lg={12}
              xs={24}
              sm={24}>
              <Form.Item
                name="kcv"
                label={t("key.col_title.kcv")}
                hasFeedback
                rules={[{ required: true, message: "KCV is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.kcv")}
                />
              </Form.Item>
              <Form.Item
                name="keyAlgorithm"
                label={t("key.col_title.keyAlgorithm")}
                rules={[
                  { required: true, message: "Key Algorithm is required" },
                ]}>
                <Select
                  className="select-base"
                  placeholder="Key Mode"
                  options={optionsKeyAlgorithm}
                />
              </Form.Item>
              <Form.Item
                name="ksi"
                label={t("key.col_title.ksi")}
                hasFeedback
                rules={[{ required: true, message: "KSI is required" }]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.ksi")}
                />
              </Form.Item>
              <Form.Item
                name="initialDID"
                label={t("key.col_title.initialDID")}
                hasFeedback
                rules={[
                  { required: true, message: "Initial DID is required" },
                ]}>
                <Input
                  className="input-base"
                  placeholder={t("key.col_title.initialDID")}
                />
              </Form.Item>
              <Form.Item
                name="remark"
                label={t("key.col_title.remark")}>
                <TextArea placeholder={t("key.col_title.remark")}/>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Content>
    </Layout>
  );
}
export default AddEditEncrytedKeyForm;
