import { LogType } from "@/constants/app.enums";
import { ReloadOutlined } from "@ant-design/icons";
import { Button, DatePicker, Divider, Drawer, Form, FormProps } from "antd";
import Input from "antd/es/input";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { DATE_TIME_FORMAT } from "../../../../../constants/app-constants";
import { TypeFilter } from "../../../../../models/audit-log";
import "./drawer-filter.scss";

export interface DrawerFilterProps {
  openDrawer: boolean;
  onClose: () => void;
  filterData: any;
  type: LogType;
  handleFilterData: (data: object) => void;
}

const dataLogStatus: TypeFilter[] = [
  { key: 17, type: "Success", value: "" },
  { key: 18, type: "Failure", value: "" },
];

const { RangePicker } = DatePicker;

function TypeFilterRender(props: {
  filters: TypeFilter[];
  onSelect: (value: string) => void;
  selectedFilters: string[];
}) {
  return (
    <>
      {props.filters.map((filter) => (
        <Button
          className={`tag ${
            props.selectedFilters.includes(filter.type) ? "selected" : ""
          }`}
          key={filter.key}
          value={filter.value}
          onClick={() => props.onSelect(filter.type)}
        >
          {filter.type}
        </Button>
      ))}
    </>
  );
}

type FieldType = {
  ipFilter?: string;
  loginNameFilter?: string;
  dateTimeFilter?: Date[];
};

function DrawerFilter(props: DrawerFilterProps) {
  const { t } = useTranslation();
  const [drawerForm] = Form.useForm();
  const [selectedFilters, setSelectedFilters] = useState<string[]>(
    props.filterData.statusFilter ?? []
  );

  const handleSelectFilter = (value: string) => {
    if (selectedFilters.includes(value)) {
      setSelectedFilters(selectedFilters.filter((filter) => filter !== value));
    } else {
      setSelectedFilters([...selectedFilters, value]);
    }
  };

  const handleReloadFilter = () => {
    setSelectedFilters([]);
    props.handleFilterData({});
  };

  const handleSearch = (values: FormProps<FieldType>) => {
    props.handleFilterData({ ...values, statusFilter: selectedFilters });
  };

  return (
    <>
      <Drawer
        placement="right"
        onClose={props.onClose}
        closable={true}
        width={432}
        open={props.openDrawer}
        extra={
          <>
            <span> {t("userMenu.filter")}</span>
            <i className="reload-btn" onClick={handleReloadFilter}>
              <ReloadOutlined />
            </i>
          </>
        }
      >
        <Form
          form={drawerForm}
          className="drawer-form"
          onFinish={handleSearch}
          initialValues={{ ...props.filterData }}
        >
          <p>{t("drawer.operationTime")}</p>
          <Form.Item name="dateTimeFilter">
            <RangePicker
              style={{ width: 398 }}
              showTime={{ format: DATE_TIME_FORMAT.rangePickerHourFormat }}
              format={DATE_TIME_FORMAT.rangePickerFormat}
            />
          </Form.Item>
          {props.type !== LogType.TransactionLog && (
            <Form.Item name="loginNameFilter">
              <Input placeholder="Search by Login Name" />
            </Form.Item>
          )}

          <Form.Item name="ipFilter">
            <Input placeholder="Search by IP" />
          </Form.Item>
          <div className="logStatus">
            <Divider />
            <p>{t("drawer.logStatus")}</p>
            <TypeFilterRender
              filters={dataLogStatus}
              onSelect={handleSelectFilter}
              selectedFilters={selectedFilters}
            />
          </div>
          <div className="btn-search-container">
            <Button className="btn-search" htmlType="submit">
              {" "}
              {t("userMenu.search")}
            </Button>
          </div>
        </Form>
      </Drawer>
    </>
  );
}

export default DrawerFilter;
