import { UserAddOutlined, UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Col, Row, Table } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { UserApi } from "../../../../apis/user.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { UserModelDisplay } from "../../../../models/user";
import { CommonUtils } from "../../../../utils/common";
import DateUtils from "../../../../utils/date.utils";
import "../user.scss";

function UserListForm() {
  const { t } = useTranslation();
  const [users, setUsers] = useState<UserModelDisplay[] | any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetAllUserFilter();
  }, []);

  const tableScroll = {
    x: "100%",
    y: "100%",
  };

  const userColumns: ColumnsType<UserModelDisplay> = [
    {
      title: "User",
      key: "1",
      render: (text: string, record: any) => (
        <>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            src={CommonUtils.createFileUrl(record.personalImage)}
            style={{ backgroundColor: "#4E46DC", marginRight: "7px" }}
            icon={<UserOutlined />}
          />
          {record.fullName}
        </>
      ),
    },
    {
      title: "Contact",
      key: "2",
      render: (text: string, record: any) => (
        <>
          <Row> {record.email}</Row>
          <Row> {record.cellE164Number}</Row>
        </>
      ),
    },
    {
      title: "Activity",
      key: "3",
      render: (text: string, record: any) => (
        <Col>{DateUtils.convertUTCToLocal(record.lastActivity)}</Col>
      ),
    },
  ];

  const handleGetAllUserFilter = async () => {
    setIsLoading(true);
    try {
      const { data } = await UserApi.getAllUsersFilter(1, 5, "");
      setUsers(data.records);
    } catch (error) {}
    setIsLoading(false);
  };

  return (
    <Content className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t("menuDashboard.users")}</Row>
          <Row className="body-regular">{users.length}{t("user.registeredUsers")}</Row>
        </Col>
        <Col span={12}>
          <Button
            className="btn-create tbn-base btn-main-base"
            htmlType="button"
            type="primary"
            icon={<UserAddOutlined />}>
            <Link to={ROUTE_PATHS.UserCreate}>
              {t("user.btn_add_new_user")}
            </Link>
          </Button>
        </Col>
      </Row>
      <Table
        rowKey={(x) => x.id}
        loading={isLoading}
        className="tb-list-user"
        columns={userColumns}
        dataSource={users}
        scroll={tableScroll}
        pagination={{
          style: { display: "none" },
        }}
      />
    </Content>
  );
}
export default UserListForm;
