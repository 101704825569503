import { Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function TerminalChart() {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      series: [
        {
          data: [0]
        },
      ],
      chart: {
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        // Customize the color of the line
        colors: ["#FFF"],
        curve: "smooth" as "smooth",
        width: 1, // Set the width of the line
      },
      markers: {
        // Customize the markers on the line
        colors: ["#524BCC"],
        size: 3,
        strokeWidth: 0,
        hover: {
          size: 5,
        },
      },
      grid: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  });
  useEffect(() => {
    setTimeout(() => {
      const newData = [12, 14, 26, 21, 20, 15, 10, 21, 20, 15, 10, 21, 20, 15, 10, 21, 20,
        15, 25,];

      setChartData(prevChartData => ({
        ...prevChartData,
        options: {
          ...prevChartData.options,
          series: [{ data: newData }],
        },
      }));

      setLoading(false);
    }, 2000);
  }, []);
  return (
    <>
      <Spin spinning={isLoading}>
        <div className="dashboard-card card-terminal">
          <div className="dashboard-card-content">
            <span className="h2-semiBold">26K</span>
            <span className="h5-regular">(-12.4%)</span>
            <br />
            <span className="h5-regular">Terminal</span>
          </div>

          <div className="terminal-chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.options.series}
              type="line"
              height={120}
              width={"99%"}
            />
          </div>
        </div>
      </Spin>
    </>
  );
}
export default TerminalChart;
