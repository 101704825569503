import { Badge, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { HealthCheckApi } from "../../../../../apis/health-check.api";
import { HealthCheckDependency } from "../../../../../models/health-check";
import "./health-check.scss"

function TransactionHealthCheck() {
  const { t } = useTranslation();
  const [healthChecks, setHealthChecks] = useState<HealthCheckDependency[] | any[]>([]);
  const [serviceStatus, setServiceStatus] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetHealthCheck();
  }, []);

  const tableScroll = {
    x: "max-content",
  };

  function timeToMilliseconds(timeString: string) {
    var parts = timeString.split(':');
    var hours = parseInt(parts[0], 10) || 0;
    var minutes = parseInt(parts[1], 10) || 0;
    var seconds = parseFloat(parts[2]) || 0;
    return (((hours * 3600) + (minutes * 60) + seconds) * 1000).toFixed(2);
  }

  const healthCheckColumns: ColumnsType<HealthCheckDependency> = [
    {
      title: "Dependency Name",
      key: "1",
      render: (text: string, record: any) => (
        <>
          {record.name}
        </>
      ),
    },
    {
      title: "Status",
      key: "2",
      className: "col-status",
      dataIndex: "status",
      render: (status) => {
        return (
          <>
            {status != 'Healthy' && (
              <Badge
                status="error"
                text={status}
              />
            )}
            {status == 'Healthy' && (
              <Badge
                status="success"
                text={status}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Description",
      key: "3",
      render: (text: string, record: any) => (
        <>
          <Row> {record.description}</Row>
        </>
      ),
    },
    {
      title: "Duration",
      key: "4",
      render: (text: string, record: any) => (
        <>
          <Row>{timeToMilliseconds(record.duration)} ms</Row>
        </>
      ),
    }
  ];

  const handleGetHealthCheck = async (
  ) => {
    setIsLoading(true);
    try {
      const { data } = await HealthCheckApi.getHealthyCheckTransaction();
      console.log(data);
      setHealthChecks(data.results);
      setServiceStatus(data.status);
      setIsLoading(false);
    } catch (error) 
    { 
      setServiceStatus('Unhealthy');
      setHealthChecks([]);
      setIsLoading(false);
    }
  };


  return (
    <div className="ctn-health-check-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row
            className="h4-semiBold">Transaction Service
          </Row>
          <Row className="body-regular">
            {serviceStatus != 'Healthy' && (
              <Badge
                status="error"
                text={serviceStatus}
              />
            )}
            {serviceStatus == 'Healthy' && (
              <Badge
                status="success"
                text={serviceStatus}
              />
            )}</Row>
        </Col>
      </Row>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Table
              rowKey={(x) => x.id}
              loading={isLoading}
              className="tb-list-health-check"
              columns={healthCheckColumns}
              dataSource={healthChecks}
              scroll={tableScroll}
            />
          </Col>
        </Row>
      </Col>
    </div>
  );
}

export default TransactionHealthCheck;
