import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Spin
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { TerminalApi } from "../../../../../apis/termial.api";
import {
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../../constants/router.constants";
import { TerminalCreateModel, TerminalModelDisplay } from "../../../../../models/terminal";
import showNotification from "../../../../common/notification/index";

function TabInfomation() {
  const { t } = useTranslation();
  const [infomationForm] = Form.useForm();
  const { id } = useParams();
  const isEdit = id != null;
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [terminal, setTerminal] = useState<TerminalModelDisplay>();
  const navigate = useNavigate();
  const checkUrl = useLocation();
  const [isLoading, setIsLoading] = useState(false);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const responseData = await handlerGetTerminalById(id ?? "");
      infomationForm.setFieldsValue({
        serialNumber: responseData?.serialNumber,
        modelName: responseData?.modelName,
        ipAddress: responseData?.ipAddress,
        androidVersion: responseData?.androidVersion,
        macAddress: responseData?.macAddress,
      });
      setTerminal(responseData);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      fetchData();
    }
  }, []);

  async function handlerGetTerminalById(
    id: string
  ): Promise<TerminalModelDisplay | undefined> {
    const response = await TerminalApi.getTerminalById(id);
    return response.data;
  }

  const onSubmit = () => {
    infomationForm.submit();
  };

  async function handlePostTerminal(values: any) {
    setLoadingBtn(true);
    try {
      const terminalRequest: TerminalCreateModel = {
        id: id ?? DefaultGUID,
        serialNumber: values.serialNumber,
        modelName: values.modelName,
        ipAddress: values?.ipAddress,
        androidVersion: values?.androidVersion,
        macAddress: values?.macAddress,
      };

      const data =
        checkUrl.pathname === ROUTE_PATHS.TerminalCreate
          ? await TerminalApi.createTerminal(terminalRequest)
          : await TerminalApi.updateTerminal(terminalRequest);

      const message =
        checkUrl.pathname === ROUTE_PATHS.TerminalCreate
          ? t("terminal.notiCreateTerminalSuccess")
          : t("terminal.notiUpdateTerminalSucces");

      showNotification("success", message);

      if (data) {
        setTimeout(() => {
          navigate(ROUTE_PATHS.Terminals);
        }, 1000);
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleTabInformation() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.userInf_title")}</div>
              <div className="body-regular ">{t("title.userInf_set")}</div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.Terminals}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onSubmit}
                >
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Spin tip="Loading" spinning={isLoading}>
      <TitleTabInformation />
      <Form
        name="infomationForm"
        layout="vertical"
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        form={infomationForm}
        onFinish={handlePostTerminal}
        className="form-submit-information"
      >
        <Row gutter={16}>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="serialNumber"
              label={t("terminal.serialNumber")}
              messageVariables={{ label: "Serial Number" }}
              rules={[{ required: true }]}
            >
              <Input className="input-base" placeholder={t("terminal.serialNumber")} />
            </Form.Item>
          </Col>
          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="modelName"
              label={t("terminal.modelName")}
              messageVariables={{ label: "Model Name" }}
            >
              <Input
                className="input-base"
                placeholder={t("terminal.modelName")}
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="androidVersion"
              label={t("terminal.androidVersion")}
              messageVariables={{ label: "Android Version" }}
            >
              <Input
                className="input-base"
                placeholder={t("terminal.androidVersion")}
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="ipAddress"
              label={t("terminal.ipAddress")}
              messageVariables={{ label: "IP Address" }}
            >
              <Input
                className="input-base"
                placeholder={t("terminal.ipAddress")}
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24} lg={12}>
            <Form.Item
              name="macAddress"
              label={t("terminal.macAddress")}
              messageVariables={{ label: "MAC Address" }}
            >
              <Input
                className="input-base"
                placeholder={t("terminal.macAddress")}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Spin>
  );
}

export default TabInfomation;
