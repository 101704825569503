import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Divider, Form, Input, Row, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { SystemApi } from "../../../../../apis/system.api";
import { UISettingModel } from "../../../../../models/system-config";
import { CommonUtils } from "../../../../../utils/common";
import showNotification from "../../../../common/notification";
import "./general-setting.scss";

export async function handleGetUI(): Promise<UISettingModel> {
  const response = await SystemApi.getUISettingConfig();
  return response.data;
}

function GeneralSettingTab() {
  const { t } = useTranslation();
  const [createForm] = Form.useForm();
  const [loading] = useState(false);
  const [logoUrl, setlogoUrl] = useState<string>();
  const [welcomeImageUrl, setWelcomeImageUrl] = useState<string>();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [loadingLogo, setLoadingLogo] = useState(false);
  const [loadingWelcomeImg, setLoadingWelcomeImg] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const adminPortalEndPoint = process.env.REACT_APP_API_ENDPOINT_Portal

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await handleGetUI();
        createForm.setFieldsValue({
          uiLogoImage: responseData?.uiLogoImage,
          uiBrowserTitle: responseData?.uiBrowserTitle,
          uiWelcomeImage: responseData?.uiWelcomeImage,
        });

        setlogoUrl(CommonUtils.createFileUrl(responseData?.uiLogoImage, adminPortalEndPoint));
        setWelcomeImageUrl(
          CommonUtils.createFileUrl(responseData?.uiWelcomeImage,adminPortalEndPoint)
        );
        setIsLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const onChangeLogo = async (fileInfo: any) => {
    setLoadingLogo(true);
    try {
      const { file } = fileInfo;
      setlogoUrl(URL.createObjectURL(file.originFileObj));
      createForm.setFieldsValue({
        uiLogoImageUpload: await file.originFileObj,
      });
    } catch (error) {}
    setLoadingLogo(false);
  };

  const onChangeWelcomeImg = async (fileInfo: any) => {
    setLoadingWelcomeImg(true);
    try {
      const { file } = fileInfo;
      setWelcomeImageUrl(URL.createObjectURL(file.originFileObj));
      createForm.setFieldsValue({
        uiWelcomeImageUpload: await file.originFileObj,
      });
    } catch (error) {}
    setLoadingWelcomeImg(false);
  };

  function LogoSetting() {
    return (
      <>
        <Divider
          className="h5-semiBold"
          orientation="left"
          orientationMargin="0">
          {t("sysConfig.ui.logo_setting")}
        </Divider>
        <div className="logo-setting-container">
          <div className="container-title">
            <p className="title body-regular">
              {t("sysConfig.ui.logo_setting")}
            </p>
            <p className="title-description">
              {t("sysConfig.ui.logo_setting_desc")}
            </p>
          </div>
          <div className="container-content">
            <Row gutter={16}>
              <Col span={12}>
                <p className="title body-regular">
                  {t("sysConfig.ui.admin_logo")}
                </p>
                <p className="title-description">
                  {t("sysConfig.ui.admin_logo_desc")}
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="uiLogoImageUpload"
                  valuePropName="LogoValue"
                  className="logo-upload-form-item">
                  <Upload
                    listType="picture-circle"
                    className="logo-upload"
                    showUploadList={false}
                    onChange={onChangeLogo}
                    fileList={[]}
                    customRequest={() => {}}
                    accept=".jpg, .jpeg, .png, .svg">
                    {logoUrl ? (
                      <Spin spinning={loadingLogo}>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                            backgroundColor: "#f5f5f5"
                          }}
                          src={logoUrl}
                          alt=""
                        />
                      </Spin>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }

  function BrowserTitle() {
    return (
      <>
        <Divider
          className="h5-semiBold"
          orientation="left"
          orientationMargin="0">
          {t("sysConfig.ui.broswer_title")}
        </Divider>
        <div className="browser-title-container">
          <Row gutter={16}>
            <Col span={12}>
              <div className="container-title">
                <p className="title body-regular">
                  {t("sysConfig.ui.broswer_title")}
                </p>
                <p className="title-description">
                  {t("sysConfig.ui.browser_desc")}
                </p>
              </div>
            </Col>
            <Col span={12}>
              <Form.Item
                className="input-name-form"
                name="uiBrowserTitle">
                <Input
                  className="input-broswer-title"
                  placeholder={t("sysConfig.ui.broswer_title")}
                  bordered={false}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </>
    );
  }

  function WelcomePageSetting() {
    return (
      <>
        <Divider
          className="h5-semiBold"
          orientation="left"
          orientationMargin="0">
          {t("sysConfig.ui.welcome_page")}
        </Divider>
        <div className="welcome-page-setting-container">
          <div className="container-content">
            <Row gutter={16}>
              <Col span={12}>
                <p className="title body-regular">
                  {t("sysConfig.ui.welcome_page")}
                </p>
                <p className="title-description">
                  {t("sysConfig.ui.welcome_page_desc")}
                </p>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="uiWelcomeImageUpload"
                  valuePropName="welcomeImageValue"
                  className="welcome-picture-upload-form-item">
                  <Upload
                    listType="picture-circle"
                    className="welcome-picture-upload"
                    showUploadList={false}
                    onChange={onChangeWelcomeImg}
                    fileList={[]}
                    customRequest={() => {}}
                    accept=".jpg, .jpeg, .png, .svg">
                    {welcomeImageUrl ? (
                      <Spin spinning={loadingWelcomeImg}>
                        <img
                          style={{
                            width: "100px",
                            height: "100px",
                            borderRadius: "50px",
                          }}
                          src={welcomeImageUrl}
                          alt=""
                        />
                      </Spin>
                    ) : (
                      uploadButton
                    )}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
  
  async function handleUpdateUI(values: any) {
    setLoadingBtn(true);
    try {
      const uiUpdate: UISettingModel = {
        logoImageUpload: values.uiLogoImageUpload,
        uiBrowserTitle: values.uiBrowserTitle,
        welcomeImageUpload: values.uiWelcomeImageUpload,
      };
      let data = await SystemApi.updateUISettingConfig(uiUpdate);
      if (data) {
        showNotification("success", "Updated UI successfully.");
        navigate(0);
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleGeneralSetting() {
    return (
      <>
        <Row gutter={10}>
          <Col
            sm={18}
            xs={14}
            lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">
                {t("sysConfig.ui.general_setting")}
              </div>
              <div className="body-regular ">
                {t("sysConfig.ui.general_setting")}
              </div>
            </div>
          </Col>
          <Col
            sm={6}
            xs={10}
            lg={12}>
            <Form.Item>
              <Button
                className="btn-sumbit btn-base btn-main-base"
                type="primary"
                loading={loadingBtn}
                htmlType="submit">
                {t("sysConfig.button.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <Spin
      tip="Loading"
      spinning={isLoading}>
      <Form
        className="general-container"
        form={createForm}
        onFinish={handleUpdateUI}>
        <TitleGeneralSetting />
        <LogoSetting />
        <BrowserTitle />
        <WelcomePageSetting />
      </Form>
    </Spin>
  );
}
export default GeneralSettingTab;
