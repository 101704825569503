import LocalUtils from "@/utils/local.utils";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Col,
  Divider,
  Flex,
  Layout,
  Row,
  Space,
  Tabs,
  TabsProps,
  Typography,
  Upload,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { UserApi } from "../../../apis/user.api";
import { DefaultGUID } from "../../../constants/app-constants";
import { MyAccountDisplayModel } from "../../../models/user";
import { authState } from "../../../states/auth";
import { CommonUtils } from "../../../utils/common";
import DateUtils from "../../../utils/date.utils";
import showNotification from "../../common/notification";
import ContactDetails from "./component/contact-details";
import FAConfiguratrion from "./component/login-configuration";
import TabInfomation from "./component/tab-information";
import "./index.scss";

const { Text } = Typography;

const MyAccountForm = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState<MyAccountDisplayModel>();
  const auth = useRecoilValue(authState);
  const user = auth.user;

  useEffect(() => {
    handleGetUserInfo();
  }, []);

  const handleGetUserInfo = async () => {
    try {
      setLoading(true);
      const res = await UserApi.getMyAccountById(user?.id ?? DefaultGUID);
      setUserInfo(res?.data);
      if(res.data){
        LocalUtils.setUserInfo(res?.data);

      }
    } catch (error) {
      console.error('Error fetching user info:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleUploadImage = async (options: any) => {
    try {
      setLoading(true);
      const message = await UserApi.updateProfileAvatar(options.file);
      showNotification("success", message.data);
      
      await handleGetUserInfo();
    } catch (error) {
      console.error('Error uploading image:', error);
    } finally {
      setLoading(false);
    }
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      showNotification("error", "You can only upload JPG/PNG file");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showNotification("error", "Image must smaller than 2MB");
    }
    return isJpgOrPng && isLt2M;
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: (
        <>
          <Row className="h5-semiBold">{t("title.userInf_title")}</Row>
          <Row className="body-regular">{t("title.userInf_desc")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <TabInfomation userInfo={user} />,
    },
    {
      key: "2",
      label: (
        <>
          <Row className="h5-semiBold">{t("title.userContact_title")}</Row>
          <Row className="body-regular">{t("title.userContact_desc")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <ContactDetails userInfo={user} />,
    },
    {
      key: "4",
      label: (
        <>
          <Row className="h5-semiBold">{t("title.login_config_title")}</Row>
          <Row className="body-regular">{t("title.login_config_desc")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      children: <FAConfiguratrion userInfo={user}/>,
    },
  ];

  return (
    <Layout className="content-page">
      <div className="user-info-card">
        <Row>
          <Col span={15}>
            <Space
              wrap
              size={16}>
              <Upload
                name="avatar"
                listType="picture-circle"
                className="avatar-uploader"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={handleUploadImage}>
                {loading ? (
                  <LoadingOutlined />
                ) : userInfo?.personalImage ? (
                  <Avatar
                    size={90}
                    src={CommonUtils.createFileUrl(userInfo?.personalImage)}
                  />
              ) : (
                  <UserOutlined />
                )}
              </Upload>
              <div className="user-name-wrap">
                <h2>{`${userInfo?.firstName ?? ""} ${userInfo?.middleName ?? ""
                  } ${userInfo?.surname ?? ""}`}</h2>
                <span>{userInfo?.roleName ?? ""}</span>
              </div>
            </Space>
          </Col>
          <Col span={9}>
            <Flex justify="flex-start">
              <Divider
                type="vertical"
                className="user-info-card-divider"
              />
              <Space direction="vertical">
                <Text type="secondary">{t("my_account.title.login_email")}</Text>
                <Text>{userInfo?.email ?? ""}</Text>
                <Text type="secondary">{t("my_account.title.last_login")}</Text>
                <Text>
                  {DateUtils.convertUTCToLocal(userInfo?.lastActivity ?? "")}
                </Text>
              </Space>
            </Flex>
          </Col>
        </Row>
      </div>
      <div className="user-info-form-wrap">
        <Tabs
          tabPosition="left"
          defaultActiveKey="1"
          items={items}
          destroyInactiveTabPane={true}
        />
      </div>
    </Layout>
  );
};

export default MyAccountForm;
