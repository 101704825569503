import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import KeyTemplatesManagementForm from "../../components/views/key-management/key-templates";

function KeyTemplatesPage() {
  const breadcrumbItems = ["Key Templates", "Key Management", "Key Templates"];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <KeyTemplatesManagementForm />
    </Layout>
  );
}
export default KeyTemplatesPage;
