import { AxiosResponse } from "axios";
import { PaginationResponseModel } from "../models/pagination-response-model";
import {
  TerminalCreateModel,
  TerminalModelDisplay
} from "../models/terminal";
import {
  deleteAsync,
  getAsync,
  postAsync,
  postFormDataAsync,
  downloadAsync,
  putFormDataAsync,
} from "./http-client";

const baseUrl = `${process.env.REACT_APP_API_ENDPOINT_Portal}/v1`;

function getAllTerminalsFilter(
  pageIndex: number,
  pageSize: number,
  searchString: string
): Promise<AxiosResponse<PaginationResponseModel<TerminalModelDisplay>>> {
  const url = `${baseUrl}/terminal/all?pageIndex=${pageIndex}&pageSize=${pageSize}&searchString=${searchString}`;
  return getAsync(url);
}

function activeTerminal(id: string, isDeactive?: boolean) {
  const url = `${baseUrl}/terminal/active/${id}/${isDeactive}`;
  return postAsync(url, undefined, true, true);
}

function deleteTerminal(id: string) {
  const url = `${baseUrl}/terminal/${id}`;
  return deleteAsync(url, undefined, true, true);
}

function getTerminalById(
  id: string
): Promise<AxiosResponse<TerminalModelDisplay>> {
  const url = `${baseUrl}/terminal/${id}`;
  return getAsync(url, undefined, false, false);
}

function updateTerminal(
  model: TerminalCreateModel
): Promise<AxiosResponse<TerminalCreateModel>> {
  const url = `${baseUrl}/terminal/update`;
  return putFormDataAsync(url, model, true, true);
}

function createTerminal(
  model: TerminalCreateModel
): Promise<AxiosResponse<TerminalCreateModel>> {
  const url = `${baseUrl}/terminal/add`;
  return postFormDataAsync(url, model, true, true);
}

function downloadKey(
  id: string
): Promise<AxiosResponse<File>> {
  const url = `${baseUrl}/terminal/download-rsa-pub/${id}`;
  return downloadAsync(url);
}


export const TerminalApi = {
  getAllTerminalsFilter,
  activeTerminal,
  deleteTerminal,
  getTerminalById,
  updateTerminal,
  createTerminal,
  downloadKey
};
