import showNotification from "@/components/common/notification";
import { ROUTE_PATHS } from "@/constants/router.constants";
import { Alert, Button, Col, Descriptions, Divider, Form, Row } from "antd";
import saveAs from "file-saver";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import { TerminalApi } from "../../../../../apis/termial.api";

function ContactDetails() {
  const { t } = useTranslation();
  const { id } = useParams();
  const [loadingBtn, setLoadingBtn] = useState(false);

  async function handleDownload() {
    setLoadingBtn(true);
    try {
      const { data } = await TerminalApi.downloadKey(id ?? "");
      console.log(data);
      if (data) {
        showNotification("success", "Your public key is ready for download.");
        saveAs(data, `${id}_public.pem`);
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleContactDetails() {
    return (
      <>
        <Row gutter={10}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">{t("title.key_download")}</div>
              <div className="body-regular ">
                Download the terminal's key as a file
              </div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.Terminals}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <>
      <TitleContactDetails />
      <div style={{ marginTop: "24px" }}>
        <Alert message={t("terminal.warning")} type="warning" showIcon />
        <Descriptions
          style={{ marginTop: "10px" }}
          title="RSA public key"
          size="small"
          layout="vertical"
        >
          <Descriptions.Item label="Key type">RSA</Descriptions.Item>
          <Descriptions.Item label="RSA key size">2048</Descriptions.Item>
          <Descriptions.Item label="Public key file format">
            .pem
          </Descriptions.Item>
        </Descriptions>
        <div className="btn-grp">
          <Button
            className="icon-key btn-sumbit btn-base btn-main-base"
            loading={loadingBtn}
            onClick={handleDownload}
          >
            {t("Dowload public key")}
          </Button>
        </div>
      </div>
    </>
  );
}
export default ContactDetails;
