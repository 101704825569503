import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KeysApi } from "../../../../apis/keys.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { KeysEnums } from "../../../../models/key-enums";
import { HSMKeysModelDisplay } from "../../../../models/keys";
import { CommonUtils } from "../../../../utils/common";
import showConfirmModal from "../../../common/modal-confirm/index";
import showNotification from "../../../common/notification/index";

function KeyHSMManagementForm() {
  const { t } = useTranslation();
  const [HSMKeys, setHSMKeys] = useState<HSMKeysModelDisplay[] | any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetAllHSMKey();
  }, []);

  const handleGetAllHSMKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await KeysApi.getAllHSMKey();
      setHSMKeys(data);
      setIsLoading(false);
    } catch (error) {}
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await KeysApi.deleteHSM(id);

        if (deleteResponse) {
          showNotification("success", "Deleted Key successfully.");
          await handleGetAllHSMKey();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this Key?`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const columns: ColumnsType<HSMKeysModelDisplay> = [
    {
      title: t("key.col_title.name"),
      key: "Name",
      render: (text: string, record: any) => (
        <>
          <Col>{record.name}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.key_mode"),
      key: "keyMode",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyMode,
            KeysEnums.KeyModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.key_index"),
      key: "keyIndex",
      render: (text: string, record: any) => <Col>{record.keyIndex}</Col>,
    },
    {
      title: t("key.col_title.kcv"),
      key: "kcv",
      render: (text: string, record: any) => <Col>{record.kcv}</Col>,
    },
    {
      title: t("key.col_title.keyAlgorithm"),
      key: "keyAlgorithm",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyAlgorithm,
            KeysEnums.KeyAlgorithmEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.remark"),
      key: "remark",
      render: (text: string, record: any) => <Col>{record.remark}</Col>,
    },
    {
      key: "6",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title="Edit">
            <Link
              to={ROUTE_PATHS.HSMKeysEdit.replace(
                ":id",
                record.id!.toString()
              )}>
              <Button
                className="action-grp-edit-btn"
                icon={<i className="icon-edit" />}
              />
            </Link>
          </Tooltip>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={<i className="icon-delete" />}
              onClick={() => handleClickDelete(record.id!.toString())}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];
  return (
    <Content className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t("key.title.hsm")}</Row>
          <Row className="body-regular">{HSMKeys.length} {t("key.keys")}</Row>
        </Col>
        <Col span={12}>
          <Link to={ROUTE_PATHS.HSMKeysCreate}>
            <Button
              className="btn-create tbn-base btn-main-base"
              htmlType="button"
              type="primary"
              icon={<KeyOutlined />}>
              {t("key.button.btn_add_hsm")}
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowKey={(x) => x.id}
        loading={isLoading}
        className="tb-list-user"
        columns={columns}
        dataSource={HSMKeys}
        pagination={{
          style: { display: "none" },
        }}
      />
    </Content>
  );
}
export default KeyHSMManagementForm;
