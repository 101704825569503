import {
  Button,
  Col,
  Form,
  Input,
  Layout,
  Row,
  Select,
  Tabs,
  TabsProps,
} from "antd";
import TextArea from "antd/es/input/TextArea";
import { Key, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { RoleApi } from "../../../../apis/role.api";
import {
  DefaultGUID,
  VALIDATION_MESSAGE_CONFIG,
} from "../../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import {
  APIPermission,
  AllowedDataField,
  RoleModelDisplay,
} from "../../../../models/role";
import { CommonUtils } from "../../../../utils/common";
import BreadcrumbComponent from "../../../common/breadcrumb";
import showNotification from "../../../common/notification";
import "./index.scss";
import AllowedDataFieldTab from "./tabs/dataFields";
import RolePermissionTab, { RolePermissionRef } from "./tabs/role-permission";
import UserByRole from "./tabs/users-roles";

function CreateAndEditRoleForm() {
  const { t } = useTranslation();
  const breadcrumbItems = ["User Role", "Home", "User Roles", "User Role"];
  const checkUrl = useLocation();
  const { id } = useParams();
  const [createForm] = Form.useForm();
  const [dataFieldForm] = Form.useForm();
  const [selectRolePermissions, setSelectRolePermissions] = useState<Key[]>([]);
  const [roles, setRoles] = useState<RoleModelDisplay[] | []>([]);
  const [data, setData] = useState<APIPermission[]>([]);
  const [dataFields, setDataFields] = useState<AllowedDataField[]>([]);
  const rolePermissionRef = useRef<RolePermissionRef>(null);
  const [loadingBtn, setLoadingBtn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (id != null) {
      const fetchData = async () => {
        try {
          const { data: responseData } = await RoleApi.getRoleById(id);
          createForm.setFieldsValue({
            roleName: responseData?.roleName,
            roleDescription: responseData?.description,
          });
          setSelectRolePermissions(
            responseData?.allowedApiId
              ? parseApiIds(responseData.allowedApiId)
              : []
          );

          setDataFields(responseData?.requestResponseDataFieldsAlloweds ?? []);
        } catch (error) {}
      };
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchRole = async function getRoles() {
      try {
        const { data } = await RoleApi.getAllRoles();
        const showTemplates = data.filter((template) => template.id !== id);
        setRoles(showTemplates);
      } catch (error) {    
      }
    };
    fetchRole();
  }, []);

  // Get List API Permissions
  useEffect(() => {
    const fetch = async () => {
      try {
        const { data: apiPermissions } = await RoleApi.getAPIPermissions();
        setData(apiPermissions);
      } catch (error) {}
    };
    fetch();
  }, []);

  function ToModelRequest(
    values: any,
    allowFields: AllowedDataField[],
    rolePermissions: Key[],
    isUpdate: boolean = true
  ): RoleModelDisplay {
    const requestModel: RoleModelDisplay = {
      id: isUpdate ? id : DefaultGUID,
      roleName: values.roleName,
      description: values.roleDescription,
      allowedApiId: CommonUtils.convertArraytoString(rolePermissions),
      requestResponseDataFieldsAlloweds: [...allowFields],
    };

    return requestModel;
  }

  function parseApiIds(apiIdString: string): Key[] {
    const keyArrays: Key[] = [];

    const matches = apiIdString.match(/([^,\s]+)/g); // Match API IDs with commas or end of string

    if (matches) {
      keyArrays.push(...matches.map((apiId) => apiId as Key)); // Convert matches to Key array
    }

    return keyArrays;
  }

  async function handleRoleAction(
    values: any,
    allowFields: AllowedDataField[],
    rolePermissions: Key[],
    isCreate: boolean
  ) {
    setLoadingBtn(true);
    try {
      let model = ToModelRequest(values, allowFields, rolePermissions);
      let result;

      if (isCreate) {
        result = await RoleApi.postRole(model);
      } else {
        result = await RoleApi.updateRole(model);
      }

      if (result?.data) {
        const message = isCreate
          ? t("rolePermision.notiCreateRoleSuccess")
          : t("rolePermision.notiUpdateRoleSuccess");

        showNotification("success", message);
        if (message) {
          setTimeout(() => {
          navigate(ROUTE_PATHS.UserRoles);
          }, 1000);
        }
      }
    } catch (error) {}
    finally{
      setLoadingBtn(false);
    }
  }

  function handleAPIfunction(values: any) {
    const dataAllowDataFields = updatedFormData(dataFieldForm.getFieldsValue());
    const rolePermissions = rolePermissionRef.current
      ? rolePermissionRef.current.getSelectRolePermissions()
      : [];

    if (checkUrl.pathname === ROUTE_PATHS.UserRolesCreate) {
      handleRoleAction(values, dataAllowDataFields, rolePermissions, true);
    } else {
      handleRoleAction(values, dataAllowDataFields, rolePermissions, false);
    }
    setDataFields(dataAllowDataFields);
    setSelectRolePermissions(rolePermissions);
  }

  function updatedFormData(values: any) {
    const updatedFormData: AllowedDataField[] = dataFields.map((field) => {
      const valueFromForm = values[`field-${field.value}`];

      if (valueFromForm !== undefined) {
        return {
          ...field,
          isChecked: valueFromForm,
        };
      }
      return field;
    });

    return updatedFormData;
  }

  const handleChangeSelect = (value: string) => {
    roles.map((role) => {
      const allowedApiId = role.allowedApiId || "";
      if (role.id === value) {
        setSelectRolePermissions(
          CommonUtils.convertStringToArray(allowedApiId)
        );
      }
      return allowedApiId;
    });
    createForm.setFieldValue("select", value);
  };

  function RoleSetup() {
    return (
      <Row className="container-items">
        <Col span={12}>
          <Form.Item
            name="roleName"
            label={t("rolePermision.roleName")}
            rules={[
              { required: true, message: t("rolePermision.roleNameReq") },
            ]}>
            <Input
              className="input-base"
              placeholder={t("rolePermision.roleName")}
            />
          </Form.Item>
          <Form.Item
            label={t("rolePermision.role_template_label")}
            initialValue={null}
            name="select">
            <Select
              className="select-base"
              placeholder={t("rolePermision.template")}
              onChange={handleChangeSelect}
              options={roles.map((role) => ({
                label: role.roleName,
                value: role.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="roleDescription"
            label={t("rolePermision.roleDescription")}>
            <TextArea
              className="input-base"
              placeholder={t("rolePermision.roleDescription")}
              style={{ height: 123 }}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item>
            <Tabs
              tabPosition="top"
              defaultActiveKey="rolePermission"
              items={items}
            />
          </Form.Item>
        </Col>
      </Row>
    );
  }

  const items: TabsProps["items"] = [
    {
      key: "rolePermission",
      label: (
        <>
          <Row className="h5-semiBold">{t("rolePermision.rolePermision")}</Row>
          <Row className="body-regular">
            {t("rolePermision.rolePermisionDes")}
          </Row>
          <Row className="status-validation"></Row>
        </>
      ),
      forceRender: true,
      children: (
        <RolePermissionTab
          data={data}
          ref={rolePermissionRef}
          selectRolePermissions={selectRolePermissions}
        />
      ),
    },
    {
      key: "allowedDataField",
      label: (
        <>
          <Row className="h5-semiBold">
            {t("rolePermision.allowedDataField")}
          </Row>
          <Row className="body-regular">
            {t("rolePermision.allowedDataFieldDes")}
          </Row>
          <Row className="status-validation"></Row>
        </>
      ),
      forceRender: true,
      children: (
        <AllowedDataFieldTab
          data={dataFields}
          dataFieldForm={dataFieldForm}
        />
      ),
    },
    {
      key: "userRoles",
      label: (
        <>
          <Row className="h5-semiBold">{t("rolePermision.userRoles")}</Row>
          <Row className="body-regular">{t("rolePermision.userRolesDes")}</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      forceRender: false,
      children: <UserByRole />,
    },
  ];

  const onSubmit = () => {
    createForm.submit();
  };

  function UserRolesForm() {
    return (
      <Form
        onFinish={handleAPIfunction}
        validateMessages={VALIDATION_MESSAGE_CONFIG}
        name="createUserRolesForm"
        className="container"
        layout="vertical"
        form={createForm}>
        <Row className="container-top-items">
          <Col span={12}>
            <div className="h4-semiBold"> {t("rolePermision.setupRole")}</div>
          </Col>
          <Col span={12}>
            <Form.Item>
              <div className="btn-grp">
                <Link to={ROUTE_PATHS.UserRoles}>
                  <Button className="btn-back btn-base btn-secondary-base">
                    {t("sysConfig.button.back")}
                  </Button>
                </Link>
                <Button
                  className="btn-sumbit btn-base btn-main-base"
                  loading={loadingBtn}
                  onClick={onSubmit}>
                  {t("sysConfig.button.save")}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
        <RoleSetup />
      </Form>
    );
  }

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      {<UserRolesForm />}
    </Layout>
  );
}
export default CreateAndEditRoleForm;
