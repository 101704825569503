import { Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserApi } from '../../../apis/user.api';
import { UpdateUserEmailRequest } from '../../../models/user';
import showNotification from '../notification/index';
import "./index.scss";
import InsertPasscodeForm from './modal-input-passcode';

export interface UpdateUserEmailPopupProps {
  visible: boolean;
  onClose: (isReload: boolean) => void;
  email: string;
  userId: string;
}

export function UpdateUserEmailPopup({
  visible,
  onClose,
  email,
  userId    
}: UpdateUserEmailPopupProps) {
  const { t } = useTranslation();
  const [updateUserEmailForm] = Form.useForm();
  const [isLoadingModal, setLoadingModal] = useState(false);
  const [isConfirmPasswordModalOpen, setIsConfirmPasswordModalOpen] = useState(false);

  const handleOk = () => {
    setIsConfirmPasswordModalOpen(true)
  };

  const handleCancel = () => {
    updateUserEmailForm.resetFields();
    setIsConfirmPasswordModalOpen(false);
    onClose(false);
  };

  const handleUpdateUserEmail = async (values: UpdateUserEmailRequest) => {
    setLoadingModal(true);

    try {
      await UserApi.updateUserEmail(values);
      showNotification("success", "Updated user email successfully.");
      handleCancel();
      window.location.reload();
    } catch (error: any) {
      const errorMessage = error.response?.data?.message || "An error occurred while updating the user email.";
      showNotification("error", errorMessage);
    } finally {
      setLoadingModal(false);
    }
  };

  useEffect(() => {
    updateUserEmailForm.resetFields();
    updateUserEmailForm.setFieldValue("email", email);
    updateUserEmailForm.setFieldValue("id", userId);
  }, [email, visible]);


  return (
    <>
    <Modal
      className="modal-confirm"
      title="Update user email"
      open={visible}
      okText="Next"
      okButtonProps={{ loading: isLoadingModal }}
      onOk={handleOk}
      onCancel={handleCancel}
    >
      <Form
        className="modal-container"
        layout="vertical"
        labelWrap
        form={updateUserEmailForm}
        onFinish={handleUpdateUserEmail}
      >
        <Form.Item
          label="id"
          name="id"
          hidden={true}>
          <Input type="text" />
        </Form.Item>
        <Form.Item
          name="email"
          label={t("user.email")}
          messageVariables={{ label: "Email" }}
          rules={[{ required: true, type: "email" }]}
        >
          <Input className="input-base" placeholder="Email"/>
        </Form.Item>
        <Form.Item
            name="passcode"
            label={t("verify.ModalInsertPasscord")}
            hidden={true}
          >
            <Input.Password
              className="input-base"
              placeholder={t("user.yourPasscode")}
            />
          </Form.Item>
      </Form>
    </Modal>
      <InsertPasscodeForm formSubmit={updateUserEmailForm} visible={isConfirmPasswordModalOpen} onClose={handleCancel} isLoading={isLoadingModal}/>
    </>
  );
}

export default UpdateUserEmailPopup;
