import { Layout } from "antd";
import BreadcrumbComponent from "../../components/common/breadcrumb";
import UserManagementForm from "../../components/views/users";

function UserManagementPage() {
  const breadcrumbItems = ["Users", "Home", "Users"];
  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <UserManagementForm />
    </Layout>
  );
}
export default UserManagementPage;
