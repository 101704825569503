import { Layout, Row, Tabs, TabsProps } from "antd";
import { useEffect, useState } from "react";
import { SCREEN_WIDTH_SMALL } from "../../../constants/app-constants";
import BreadcrumbComponent from "../../common/breadcrumb";
import ApplicationManagement from "./components/application-management-tabs/application-management";
import HealthChecks from "./components/health-check/health-check";
import UISetting from "./components/ui-setting-tabs/ui-setting";
import "./index.scss";

function SystemConfigForm() {
  const [currentActiveKey, setCurrentActiveKey] = useState<string>(window.innerWidth <= SCREEN_WIDTH_SMALL ? "GlobalConfigurationTab" : "ApplicationManagementTab");

  useEffect(() => {
    const handleResize = () => {
      if(window.innerWidth <= SCREEN_WIDTH_SMALL && currentActiveKey === "HealthCheckTab") {
        setCurrentActiveKey("GlobalConfigurationTab");
      } else if(window.innerWidth > SCREEN_WIDTH_SMALL && currentActiveKey === "GlobalConfigurationTab") {
        setCurrentActiveKey("ApplicationManagementTab");
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [currentActiveKey]);



  const breadcrumbItems = ["System Config", "Home", "System Config"];

  const items: TabsProps["items"] = [
    {
      key: "ApplicationManagementTab",
      label: (
        <>
          <Row className="h5-semiBold">Application Management </Row>
          <Row className="body-regular">Configuration for Application</Row>
          <Row className="status-validation"></Row>
        </>
      ),
      forceRender: false,
      children: <ApplicationManagement />,
    },
    {
      key: "UISettingTab",
      label: (
        <>
          <Row className="h5-semiBold">UI Setting</Row>
          <Row className="body-regular">
            Set theme, logo and advertisment image
          </Row>
          <Row className="status-validation"></Row>
        </>
      ),

      forceRender: false,
      children: <UISetting />,
    },
    {
      key: "HealthCheckTab",
      label: (
        <div className="tab-web-only">
          <Row className="h5-semiBold">Health Check</Row>
          <Row className="body-regular">
            Check status services and dependencies
          </Row>
          <Row className="status-validation"></Row>
        </div>
      ),

      forceRender: false,
      children: <HealthChecks />,
    },
  ];

  function ConfigForm() {
    return (
      <>
        <Tabs
          tabPosition="left"
          onTabClick={(activeKey) => setCurrentActiveKey(activeKey)}
          items={items}
          destroyInactiveTabPane = {true}
          className="tab"
          activeKey={currentActiveKey}
        />
      </>
    );
  }

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <div className="container-system-form">{<ConfigForm />} </div>
    </Layout>
  );
}

export default SystemConfigForm;
