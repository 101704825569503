import { Alert, Form, FormInstance, Input, Modal } from 'antd';
import { useTranslation } from "react-i18next";
import {
  PASSWORD_REGEX,
} from "../../../constants/app-constants";
import "./index.scss";

interface InsertPasscodeFormProps{
    formSubmit: FormInstance<any>
    visible: boolean;
    onClose: (isReload:boolean) => void;
    isLoading?: boolean;
}

export function InsertPasscodeForm(props: InsertPasscodeFormProps) {
    const { t } = useTranslation();
    const [passcodeForm] = Form.useForm();
  
    const handleOk = () => {
      passcodeForm.submit();
    };
  
    const handleCancel = () => {
      passcodeForm.resetFields();
      props.onClose(false);
    };

    async function handleSubmitMFA(values: any) {
      try { 
        props.formSubmit.setFieldValue("passcode",values.password)
        props.formSubmit.submit();
        passcodeForm.resetFields();
        }
      catch (error) {
      }
      finally{
      }
    }
    
    return (
      <Modal
        className="modal-confirm"
        title={t("verify.ModalInsertPasswordTitle")}
        open={props.visible}
        okText="Save"
        okButtonProps={{ loading: props.isLoading }}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Alert message={t("common.warningVerifyPass")} type="warning" showIcon />
        <Form
          className="modal-container"
          layout="vertical"
          labelWrap
          form={passcodeForm}
          onFinish={handleSubmitMFA}
        >
          <Form.Item
            name="password"
            label={t("verify.ModalInsertPasscord")}
            hasFeedback
            rules={[
              {
                required: true,
                pattern: PASSWORD_REGEX,
                message: "The password required or format incorrect.",
              }]}
          >
            <Input.Password
              
              className="input-base"
              placeholder={t("user.yourPasscode")}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
  
  export default InsertPasscodeForm;