import { InfoCircleOutlined } from "@ant-design/icons";
import { Alert, Button, Form, Input } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { AuthApi } from "../../../apis/auth.api";
import logo from "../../../assets/images/PAX_Logo.svg";
import {
  CHANGE_PASSWORD_CONFIG,
  PASSWORD_REGEX,
  VALIDATION_MESSAGE_CONFIG
} from "../../../constants/app-constants";
import { ROUTE_PATHS } from "../../../constants/router.constants";
import { ChangePasswordType } from "../../../models/enums";
import { ChangePasscodeModel } from "../../../models/sign-in.model";
import GuidePasscode from "../../common/guide-passcode/guide-passcode";
import showNotification from "../../common/notification";
import "./change-passcode.scss";

type ChangePasswordConfig = {
  key: ChangePasswordType;
  label: string;
};

function ChangePasscodeForm() {
  const { t } = useTranslation();
  const [changePasscodeForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [messageAlert, setMessageAlert] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const state: any = location.state;

  console.log(state);

  const getChangePasswordConfigByKey = (key: ChangePasswordType): ChangePasswordConfig | undefined => {
    return CHANGE_PASSWORD_CONFIG.find(config => config.key === key);
  }

  const config = getChangePasswordConfigByKey(state.type);

  async function handleErrorResponse(error: any) {
    const data = error.response?.data;
    if (data) {
      setMessageAlert(data.message);
    } else {
      setMessageAlert(t("common.serverError"));
    }
  }

  async function handleSubmit(values: any) {
    setLoading(true);
    setMessageAlert(false);

    try {
      const model: ChangePasscodeModel = {
        oldPasscode: values.oldPasscode,
        passcode: values.passcode,
        passcodeConfirm: values.confirm,
      };

      let data = await AuthApi.changingLoggedUserPasscode(model, false);
      if (data) {
        showNotification(
          "success",
          "Password updated successfully."
        );
        let redirectUrl =
          state.type === ChangePasswordType.PasswordWarning
            ? ROUTE_PATHS.Home
            : ROUTE_PATHS.SignIn;

        navigate(redirectUrl);
      }
    } catch (error) {
      handleErrorResponse(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="auth-layout__logo_wrapper">
        <Link to={ROUTE_PATHS.Home}>
          <img src={logo} className="auth-layout__logo" alt="" />
        </Link>
      </div>
      <div className="verification">
        <div className="header">
          <div className="title h2-semiBold">
            {t("login.updateYourPassword")}
          </div>
          <div
            className="title-detail body-regular text-center"
            dangerouslySetInnerHTML={{
              __html: config?.label ?? t("login.changePassExpiryWarning"),
            }}
          ></div>
        </div>

        <div className="sign-in-form">
          {messageAlert && (
            <Alert message={messageAlert} type="error" showIcon closable />
          )}
          <br />
          <Form
            name="changePasscodeForm"
            form={changePasscodeForm}
            layout="vertical"
            validateMessages={VALIDATION_MESSAGE_CONFIG}
            className="update-passcode-form passcode-checklist-form"
            onFinish={handleSubmit}
          >
            <Form.Item
              name="oldPasscode"
              label={t("user.oldPasscode")}
              hasFeedback
              tooltip={{
                title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
                placement: "rightBottom", color: "#D9D9D9",
                overlayClassName: "guide-tooltip"
              }}
              rules={[
                {
                  required: true,
                  pattern: PASSWORD_REGEX,
                  message: "The current password required or format incorrect.",
                },
              ]}
            >
              <Input.Password
                className="input-base"
                placeholder={t("user.oldPasscode")}
                autoComplete="oldPasscode"
              />
            </Form.Item>
            <Form.Item
              name="passcode"
              label={t("user.newPasscode")}
              hasFeedback
              tooltip={{
                title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
                placement: "rightBottom", color: "#D9D9D9",
                overlayClassName: "guide-tooltip"
              }}
              rules={[
                {
                  required: true,
                  pattern: PASSWORD_REGEX,
                  message: "The new password required or format incorrect.",
                },
              ]}
            >
              <Input.Password
                className="input-base"
                placeholder={t("user.newPasscode")}
                autoComplete="newPasscode"
              />
            </Form.Item>
            <Form.Item
              name="confirm"
              className="form-item-confirm"
              label={t("user.rePasswordPlahoder")}
              dependencies={["password"]}
              hasFeedback
              tooltip={{
                title: <GuidePasscode />, icon: <InfoCircleOutlined />, trigger: "click", 
                placement: "rightBottom", color: "#D9D9D9",
                overlayClassName: "guide-tooltip"
              }}  
              rules={[
                {
                  required: true,
                  message: "Please confirm your password",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("passcode") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The confirm new password that you entered do not match."
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                className="input-base"
                placeholder={t("user.rePasswordPlahoder")}
                autoComplete="rePassword"
              />
            </Form.Item>

            <Form.Item>
              <Button
                loading={loading}
                htmlType="submit"
                className="btn-login btn-base body-regular"
                type="primary"
                block
              >
                {t("login.verificationContinue")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Link
        className="auth__backLink"
        to={ROUTE_PATHS.SignIn}
        dangerouslySetInnerHTML={{ __html: t("login.sendMeBack") }}
      ></Link>
    </>
  );
}

export default ChangePasscodeForm;
