import { UserOutlined } from "@ant-design/icons";
import { Avatar, Drawer, Space } from "antd";
import "./index.scss";

export interface SlideRightProps {
  openDrawer: boolean;
  setOpenDrawer: (collapsed: boolean) => void;
}

function SlideRight(props: SlideRightProps) {

  const onClose = () => {
    props.setOpenDrawer(false);
  };

  return (
    <Drawer
      className="slide-right"
      placement="right"
      onClose={onClose}
      width={348}
      closable={true}
      open={props.openDrawer}
      extra={
        <Space className="drawer-actions">
          <i className="drawer-icon icon-group-10"></i>
          <i className="drawer-icon icon-chat"></i>
          <i className="drawer-icon icon-setting-2"></i>
        </Space>
      }
    >
      <div className="drawer-filed">
        <span className="body-semiBold today">Today</span>
      </div>

      <div className="drawer-items">
        <div className="drawer-item">
          <div className="drawer-item-container">
            <p className="drawer-item-title h5-regular">Meeting with Lucas</p>
            <p className="drawer-item-time body-regular">
              1-3pm - Palo Alto, CA
            </p>
          </div>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined rev={undefined} />}
          />
        </div>

        <div className="drawer-item">
          <div className="drawer-item-container">
            <p className="drawer-item-title h5-regular">Meeting with Megan</p>
            <p className="drawer-item-time body-regular">
              1-3pm - Palo Alto, CA
            </p>
          </div>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            style={{ backgroundColor: "#4E46DC" }}
            icon={<UserOutlined />}
          />
        </div>        
      </div>


      <div className="drawer-filed">
        <span className="body-semiBold today">Tomorrow</span>
      </div>

      <div className="drawer-items">
        <div className="drawer-item">
          <div className="drawer-item-container">
            <p className="drawer-item-title h5-regular">New UI Project</p>
            <p className="drawer-item-time body-regular">
            4-5pm - Palo Alto, CA
            </p>
          </div>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            style={{ backgroundColor: "#87d068" }}
            icon={<UserOutlined />}
          />
        </div>

        <div className="drawer-item">
          <div className="drawer-item-container">
            <p className="drawer-item-title h5-regular">Team Meeting</p>
            <p className="drawer-item-time body-regular">
              1-3pm - Palo Alto, CA
            </p>
          </div>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            style={{ backgroundColor: "#C1506E" }}
            icon={<UserOutlined />}
          />
        </div>

        <div className="drawer-item">
          <div className="drawer-item-container">
            <p className="drawer-item-title h5-regular">Startup Meeting</p>
            <p className="drawer-item-time body-regular">
              1-3pm - Palo Alto, CA
            </p>
          </div>
          <Avatar
            className="drawer-item-avatar"
            size={40}
            style={{ backgroundColor: "#4E46DC" }}
            icon={<UserOutlined />}
          />
        </div>        
      </div>

    </Drawer>
  );
}

export default SlideRight;
