import { atom } from "recoil";
import { APP_STATE_KEYS } from "../constants/app-state.constants";

interface CollapsedState {
  isCollapsed: boolean;
}
const collapsedState = atom<CollapsedState>({
  key: APP_STATE_KEYS.SIDE_BAR_STATE,
  default: { isCollapsed: false },
});
export { collapsedState };

