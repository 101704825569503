import { KeyOutlined } from "@ant-design/icons";
import { Button, Col, Row, Space, Table, Tooltip } from "antd";
import { Content } from "antd/es/layout/layout";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { KeysApi } from "../../../../apis/keys.api";
import { ROUTE_PATHS } from "../../../../constants/router.constants";
import { KeysEnums } from "../../../../models/key-enums";
import { EncrytedKey, EncrytedKeyModelDisplay } from "../../../../models/keys";
import { CommonUtils } from "../../../../utils/common";
import showConfirmModal from "../../../common/modal-confirm";
import showNotification from "../../../common/notification";
import ModalViewDetail from "./component/modal-view-encrypted";
import "./index.scss";

function EncryptedKeyManagementForm() {
  const { t } = useTranslation();
  const [encryptedKeyList, setEncryptedKeyList] = useState<
    EncrytedKeyModelDisplay[] | any[]
  >([]);
  const [encryptedKeys, setEncryptedKeys] = useState<EncrytedKey>();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = async (id: string) => {
    const { data } = await KeysApi.getEncryptedKeyById(id);
    console.log(data);
    setEncryptedKeys(data);
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    handleGetAllHSMKey();
  }, []);

  const handleGetAllHSMKey = async () => {
    setIsLoading(true);
    try {
      const { data } = await KeysApi.getAllEncryptedKeys();
      setEncryptedKeyList(data);
      setIsLoading(false);
    } catch (error) {}
  };

  const handleClickDelete = (id: string): void => {
    const handleConfirm = async () => {
      try {
        let { data: deleteResponse } = await KeysApi.deleteEncryptedKey(id);

        if (deleteResponse) {
          showNotification("success", "Deleted Key successfully.");
          await handleGetAllHSMKey();
        }
      } catch (error) {}
    };

    const handleCancel = () => {
      console.log("Cancelled action");
    };

    showConfirmModal({
      type: "delete",
      title: `Delete this Key?`,
      content: t("modal.confirm_text"),
      onOk: handleConfirm,
      onCancel: handleCancel,
    });
  };

  const columns: ColumnsType<EncrytedKeyModelDisplay> = [
    {
      title: t("key.col_title.name"),
      key: "Name",
      render: (text: string, record: any) => (
        <>
          <Col>{record.name}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.key_mode"),
      key: "keyMode",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyMode,
            KeysEnums.KeyModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.key_name"),
      key: "KeyName",
      render: (text: string, record: any) => (
        <>
          <Col>{record.hsmKeyName}</Col>
        </>
      ),
    },
    {
      title: t("key.col_title.encryption_mode"),
      key: "encryptionMode",
      render: (text: string, record: any) => (
        <Col>
          {" "}
          {CommonUtils.getDisplayNameForKeyEnums(
            record.encryptionMode,
            KeysEnums.EncryptionModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.keyAlgorithm"),
      key: "keyAlgorithm",
      render: (text: string, record: any) => (
        <Col>
          {CommonUtils.getDisplayNameForKeyEnums(
            record.keyAlgorithm,
            KeysEnums.EncryptionModeEnums
          )}
        </Col>
      ),
    },
    {
      title: t("key.col_title.remark"),
      key: "remark",
      render: (text: string, record: any) => <Col>{record.remark}</Col>,
    },
    {
      key: "6",
      title: "Action",
      className: "col-action",
      render: (_, record) => (
        <Space
          size={[6, 12]}
          wrap
          className="action-cpn">
          <Tooltip title="View Detail">
            <Button
              className="action-grp-view-btn"
              icon={<i className="icon-show" />}
              onClick={() => showModal(record.id!.toString())}
            />
          </Tooltip>
          <Link
            to={ROUTE_PATHS.EncryptedKeysEdit.replace(
              ":id",
              record.id!.toString()
            )}>
            <Button
              className="action-grp-edit-btn"
              icon={<i className="icon-edit" />}
            />
          </Link>
          <Tooltip title="Delete">
            <Button
              className="action-grp-delete-btn"
              icon={<i className="icon-delete" />}
              onClick={() => handleClickDelete(record.id!.toString())}
            />
          </Tooltip>
        </Space>
      ),
    },
  ];

  return (
    <Content className="ctn-user-index">
      <Row>
        <Col
          className="table-info"
          span={12}>
          <Row className="h4-semiBold">{t("key.title.encrypted_key")}</Row>
          <Row className="body-regular">{encryptedKeyList.length} {t("key.keys")}</Row>
        </Col>
        <Col span={12}>
          <Link to={ROUTE_PATHS.EncryptedKeysCreate}>
            <Button
              className="btn-create tbn-base btn-main-base"
              htmlType="button"
              type="primary"
              icon={<KeyOutlined />}>
              {t("key.button.btn_add_encryted")}
            </Button>
          </Link>
        </Col>
      </Row>
      <Table
        rowKey={(x) => x.id}
        loading={isLoading}
        className="tb-list-user"
        columns={columns}
        dataSource={encryptedKeyList}
        pagination={{
          style: { display: "none" },
        }}
      />
      <div className="modal-encypted">
        <ModalViewDetail
          visible={isModalOpen}
          onClose={handleCancel}
          encryptedModel={encryptedKeys}
        />
      </div>
    </Content>
  );
}
export default EncryptedKeyManagementForm;
