import { Button, Col, Row, Table } from "antd";
import { ColumnsType } from "antd/es/table/interface";
import { saveAs } from "file-saver";
import { useEffect, useState } from "react";
import { AuditLogApi } from "../../../../../apis/audit.api";
import { LogType } from "../../../../../constants/app.enums";
import { AuditLog, YearFilter } from "../../../../../models/audit-log";
import DateUtils from "../../../../../utils/date.utils";
import showNotification from "../../../../common/notification/index";
import PaginationComponent from "../../../../common/pagination/component/pagination";
import LogComponent from "../audit-log-component/log-component";
import { getDate } from "../operation-log/operation-log";
import "./system-log.scss";

const yearList: YearFilter[] = [
  { id: 1, label: "2023-2022", value: "" },
  { id: 2, label: "2021", value: "" },
  { id: 3, label: "2019", value: "" },
  { id: 4, label: "2018", value: "" },
  { id: 5, label: "Earlier", value: "" },
];

function YearFilterList(props: { filters: YearFilter[] }) {
  return (
    <>
      {props.filters.map((button) => (
        <span key={button.id}>
          <Button value={button.value}>{button.label}</Button>
        </span>
      ))}
    </>
  );
}

function RightFilter() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleExportSystemLog = async () => {
    setIsLoading(true);
    showNotification(
      "warning",
      "Just a moment, please. We're carefully preparing your System Log report."
    );
    await new Promise((resolve) => setTimeout(resolve, 5000));
    try {
      const typeSystemLog = LogType.SystemLog;
      const { data } = await AuditLogApi.getSystemLogReport(typeSystemLog);
      if (data) {
        setIsLoading(false);
        showNotification(
          "success",
          "Your System Log report is ready for download."
        );
        const currentDate = getDate();
        saveAs(data, `$Export_System_Logs_${DateUtils.dateView(currentDate)}`);
      }
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {/* <i
        className="icon-paper-export"
        onClick={handleExportSystemLog}></i> */}
      <i
        className="icon-filter-3"
        onClick={toggleDrawer}></i>
    </>
  );
}

function SystemLog() {
  const [logs, setLogs] = useState<AuditLog[]>([]);
  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(1);
  const [totalRecords, setTotalRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    handleGetSystemLogs();
  }, []);

  const columns: ColumnsType<AuditLog> = [
    {
      title: null,
      render: (record: any) => {
        return <LogComponent type={LogType.SystemLog} log={record} />;
      },
    },
  ];

  const getAuditLogs = async (
    pageSize: number,
    pageNumber: number,
    type: LogType
  ) => {
    const { data } = await AuditLogApi.getAuditLogs(pageNumber, pageSize, type, {});
    if (data) {
      setIsLoading(false);
    }
    return data;
  };

  const handleGetSystemLogs = async (
    pageSizeData?: number,
    pageNumberData?: number
  ) => {
    pageSizeData = pageSizeData || pageSize;
    pageNumberData = pageNumberData || 1;
    const typeSystemLog = LogType.SystemLog;
    try {
      const auditLogDatas = await getAuditLogs(
        pageSizeData,
        pageNumberData,
        typeSystemLog
      );
      setLogs(auditLogDatas.records);
      setTotalRecords(auditLogDatas.totalRecords);
    } catch (error) {}
  };

  return (
    <>
      <div className="row-filter">
        {/* <span className="year-filter">
          <YearFilterList filters={yearList} />
        </span> */}
        <span className="right-filter">
          <RightFilter />
        </span>
      </div>
      <Row>
        <Col span={24}>
          <Table
            showHeader={false}
            columns={columns}
            dataSource={logs}
            loading={isLoading}
            className="tab-audlt-log"
            rowKey="traceId"
            pagination={{
              pageSize: pageSize,
              current: pageNumber,
              style: { display: "none" },
            }}
          />
        </Col>
        <PaginationComponent
          totalRecords={totalRecords}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          handleGetFilter={handleGetSystemLogs}
        />
      </Row>
    </>
  );
}

export default SystemLog;
