import { Card, Col, Layout, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import BreadcrumbComponent from "../../common/breadcrumb";
import UserListForm from "../users/user-list";
import "./index.scss";
import ProductSalesChart from "./mixed-charts/bottom-charts/productSalesChart";
import TrafficPieChart from "./mixed-charts/bottom-charts/trafficPieChart";
import MerchantChart from "./mixed-charts/top-charts/merchantChart";
import SessionsChart from "./mixed-charts/top-charts/sessionsChart";
import TerminalChart from "./mixed-charts/top-charts/terminalChart";
import TrafficChart from "./mixed-charts/trafficChart";

function DashboardForm() {
  const breadcrumbItems = ["Dashboard", "Home", "Dashboard"];

  return (
    <Layout className="content-page">
      <BreadcrumbComponent items={breadcrumbItems} />
      <Content
        style={{
          margin: 0,
          minHeight: 280,
        }}>
        <Row
          className="row-top"
          gutter={24}>
          <Col
            lg={8}
            sm={24}>
            <TerminalChart />
          </Col>
          <Col
            lg={8}
            sm={24}>
            <MerchantChart />
          </Col>
          <Col
            lg={8}
            sm={24}>
            <SessionsChart />
          </Col>
        </Row>
        <Row
          className="chart-content"
          gutter={24}>
          <Col span={24}>
            <Card>
              <div className="chart-title">
                <h2>Traffic </h2>
                <p>January 17, 2023 - October 17, 2023</p>
              </div>
              <div className="mixed-chart">
                <TrafficChart />
              </div>
            </Card>
          </Col>
        </Row>
        <Row
          className="bottom-content"
          gutter={24}>
          <Col
            lg={8}
            sm={24}>
            <Row gutter={24}>
              <Col
                lg={24}
                sm={24}>
                <div className="traffic-chart-container">
                  <h3>Traffic by Location</h3>
                  <div className="traffic-chart">
                    <TrafficPieChart />
                  </div>
                </div>
              </Col>
              <Col
                lg={24}
                sm={24}>
                <div className="product-sales-chart-container">
                  <div className="title-product-chart">
                    <h3>Product Sales Analytics</h3>
                  </div>
                  <ProductSalesChart />
                </div>
              </Col>
            </Row>
          </Col>
          <Col
            lg={16}
            sm={24}>
            <div className="users-form-container">
              <UserListForm />
            </div>
          </Col>
        </Row>
      </Content>
    </Layout>
  );
}

export default DashboardForm;
