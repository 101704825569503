import { Button, Col, Divider, Form, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SystemApi } from "../../../../../apis/system.api";
import { Application } from "../../../../../models/system-config";
import InsertPasscodeForm from "../../../../common/modal-mfa/modal-input-passcode";
import showNotification from "../../../../common/notification/index";
import ApplicationPermissions from "./application-permissions";
import Base from "./base";
import "./index.scss";

function ApplicationManagement() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [applicationForm] = Form.useForm();
  const [loadingBtn, setLoadingBtn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await handleGetApplicationForm();
        applicationForm.setFieldsValue({
          //Basic
          name: responseData?.name,
          id: responseData?.id,
          isDeactivate: responseData?.isDeactivate,
          secretKey: responseData?.secretKey,
          appId: responseData?.appId,
          aChangePasscodeAfterXDays: responseData?.aChangePasscodeAfterXDays,
          aotpExpiry: responseData?.aotpExpiry,
          aChangePasscodeAfterXDaysNotify:
            responseData?.aChangePasscodeAfterXDaysNotify,
          inactivityLogout: responseData?.inactivityLogout,
          accessTokenLifeTime: responseData?.accessTokenLifeTime,

          //Application Permissions
          aAllowMailLogin: responseData?.aAllowMailLogin,
          aAllowPhoneLogin: responseData?.aAllowPhoneLogin,
          isEmailCodeRequired: responseData?.isEmailCodeRequired,
          isCellCodeRequired: responseData?.isCellCodeRequired,
          isDualOTP: responseData?.isDualOTP,
          isDualAccessNormal: responseData?.isDualAccessNormal,
          userManagerOTPSMSRequired: responseData?.userManagerOTPSMSRequired,
          loginFailedLockEnabled: responseData?.loginFailedLockEnabled,
          loginFailedNotifyMail: responseData?.loginFailedNotifyMail,
          loginFailedNotifyPhone: responseData?.loginFailedNotifyPhone,
          failureRetryTimesAllowed: responseData?.failureRetryTimesAllowed,
          lockedWhenFailedXMinutesConfig:
            responseData?.lockedWhenFailedXMinutesConfig,
          lockedWhenOutOfRetryTimesConfig:
            responseData?.lockedWhenOutOfRetryTimesConfig,
        });
        setIsLoading(false);
      } catch (error) {}
    };
    fetchData();
  }, []);

  function ApplicationManagementForm() {
    return (
      <>
        <div className="application-container-item">
          <Base />
          <ApplicationPermissions formSubmit={applicationForm} />
        </div>
      </>
    );
  }

  const onVisibleSubmitModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  async function handleGetApplicationForm(): Promise<Application | undefined> {
    const response = await SystemApi.getApplicationConfig();
    return response.data;
  }

  async function handleUpdateApplicationForm(values: any) {
    setLoadingBtn(true);

    try {
      const appConfig: Application = {
        //Basic
        name: values.name,
        id: values.id,
        isDeactivate: values.isDeactivate,
        secretKey: values.secretKey,
        appId: values.appId,
        aChangePasscodeAfterXDays: values.aChangePasscodeAfterXDays,
        aotpExpiry: values.aotpExpiry,
        aChangePasscodeAfterXDaysNotify: values.aChangePasscodeAfterXDaysNotify,
        inactivityLogout: values.inactivityLogout,
        accessTokenLifeTime: values.accessTokenLifeTime,

        //Application Permissions
        aAllowMailLogin: values.aAllowMailLogin,
        aAllowPhoneLogin: values.aAllowPhoneLogin,
        isEmailCodeRequired: values.isEmailCodeRequired,
        isCellCodeRequired: values?.isCellCodeRequired,
        isDualOTP: values?.isDualOTP,
        isDualAccessNormal: values?.isDualAccessNormal,
        userManagerOTPSMSRequired: values.userManagerOTPSMSRequired,
        loginFailedLockEnabled: values.loginFailedLockEnabled,
        loginFailedNotifyMail: values.loginFailedNotifyMail,
        loginFailedNotifyPhone: values.loginFailedNotifyPhone,
        failureRetryTimesAllowed: values.failureRetryTimesAllowed,
        lockedWhenFailedXMinutesConfig: values.lockedWhenFailedXMinutesConfig,
        lockedWhenOutOfRetryTimesConfig: values.lockedWhenOutOfRetryTimesConfig,
        passcode: values.passcode,
      };

      var { data } = await SystemApi.updateApplicationConfig(appConfig);
      if (data) {
        showNotification(
          "success",
          t("sysConfig.appPermissions.noti_application_config_success")
        );
        onVisibleSubmitModal();
      }
    } catch (error) {
    } finally {
      setLoadingBtn(false);
    }
  }

  function TitleApplicationManagement() {
    return (
      <>
        <Row gutter={16}>
          <Col sm={18} xs={14} lg={12}>
            <div className="head-form">
              <div className="h4-semiBold">
                {t("sysConfig.label.applicationManagement")}
              </div>
              <div className="body-regular ">
                {t("sysConfig.label.applicationManagement")}
              </div>
            </div>
          </Col>
          <Col sm={6} xs={10} lg={12}>
            <Form.Item className="btn-row">
              <Button
                className="btn-sumbit btn-base btn-main-base"
                loading={loadingBtn}
                onClick={onVisibleSubmitModal}
                htmlType="submit"
              >
                {t("sysConfig.button.save")}
              </Button>
            </Form.Item>
          </Col>
        </Row>
        <Divider />
      </>
    );
  }

  return (
    <>
      <Spin tip="Loading" spinning={isLoading}>
        <Form
          className="application-container"
          layout="vertical"
          labelWrap
          form={applicationForm}
          onFinish={handleUpdateApplicationForm}
        >
          <TitleApplicationManagement />
          <ApplicationManagementForm />
        </Form>
        <InsertPasscodeForm
          visible={isModalOpen}
          onClose={onVisibleSubmitModal}
          formSubmit={applicationForm}
          isLoading={loadingBtn}
        />
      </Spin>
    </>
  );
}

export default ApplicationManagement;
