import { Spin } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function MerchantChart() {
  const [isLoading, setLoading] = useState(true);
  const [chartData, setChartData] = useState({
    options: {
      series: [
        {
          data: [0],
        },
      ],
      chart: {
        width: '100%',
        toolbar: {
          show: false,
        },
        sparkline: {
          enabled: true,
        },
      },
      fill: {
        type: "gardient",
        colors: ["#F5DC7E"],
      },
      stroke: {
        width: 1,
        colors: ["#FFFFFF"],
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        show: false,
      },
      yaxis: {
        show: false,
      },
      xaxis: {
        tickPlacement: 'between',
      
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    },
  });
  
  
  useEffect(() => {
    setTimeout(() => {
      const newData = [12, 14, 26, 21, 20, 15, 10];

      setChartData(prevChartData => ({
        ...prevChartData,
        options: {
          ...prevChartData.options,
          series: [{ data: newData }],
        },
      }));

      setLoading(false);
    }, 2000);
  }, []);

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="dashboard-card card-merchant">
          <div className="dashboard-card-content">
            <span className=" h2-semiBold">30.000</span>
            <span className=" h5-regular ">(+84.7%)</span>
            <br />
            <span className=" h5-regular ">Merchant</span>
          </div>

          <div className="merchant-chart">
            <ReactApexChart
              options={chartData.options}
              series={chartData.options.series}
              type="area"
              height={118}
              width={"100%"}
            />
          </div>
        </div>
      </Spin>
    </>
  );
}
export default MerchantChart;
