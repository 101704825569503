export enum NotificationType {
  Info = 0,
  Warning = 1,
  Error = 2,
}

export enum LogType {
  OperationLog = 1,
  SystemLog = 2,
  TransactionLog = 4,
}